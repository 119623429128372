import { supabase } from './supabase';
import { get } from 'svelte/store';
import { authStore } from '$lib/stores/auth-store';

export type Filter = {
  id?: string;
  name: string;
  module: string;
  filter: any[];
  type: 'private' | 'public' | 'shared' | 'system';
  isDefault?: boolean;
  isPinned?: boolean;
  isPublic?: boolean;
  isShared?: boolean;
  search?: string;
  order?: number;
  fulltextsearch?: boolean;
  view?: string;
  columns?: any;
  dealId?: string;
};

export const loadFilters = async (module: string) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('filters')
    .select('*')
    .eq('module', module)
    .order('order', { ascending: true })
    .order('name');

  if (error) {
    console.error('Error loading filters:', error);
    throw new Error(error.message);
  }

  return data;
};

export const saveFilter = async (filter: Omit<Filter, 'id'>) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('filters')
    .insert(filter)
    .select()
    .single();

  if (error) {
    console.error('Error saving filter:', error);
    throw new Error(error.message);
  }

  return data;
};

export const deleteFilter = async (id: string) => {
  const { error } = await supabase(get(authStore)?.token)
    .from('filters')
    .delete()
    .eq('id', id);

  if (error) {
    console.error('Error deleting filter:', error);
    throw new Error(error.message);
  }
};

export const updateFilter = async (id: string, updates: Partial<Omit<Filter, 'id'>>) => {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('filters')
    .update(updates)
    .eq('id', id)
    .select();

  if (error) {
    console.error('Error updating filter:', error);
    throw new Error(error.message);
  }

  return data[0];
};

// Helper functions for common updates
export const togglePinned = async (id: string, isPinned: boolean) => {
  return updateFilter(id, { isPinned });
};

export const toggleShared = async (id: string, isShared: boolean) => {
  return updateFilter(id, { isShared });
};

export const togglePublic = async (id: string, isPublic: boolean) => {
  return updateFilter(id, { isPublic });
};

export const updateFilterOrder = async (id: string, order: number) => {
  return updateFilter(id, { order });
};
