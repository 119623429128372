<script lang="ts">
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import Icon from '@iconify/svelte';
  import { appStore } from '$lib/stores/app-store';
  import { cn } from '$lib/utils/hanta-utils';
  import HelpDocs from '$lib/components/help-docs.svelte';
  const APP_VERSION = import.meta.env.PACKAGE_VERSION || '0.0.0';

  let showDocs = false;

  const helpItems = [
    {
      group: 'support',
      items: [
        {
          icon: 'ph:book-open-duotone',
          label: 'Help & documentation',
          onClick: () => {
            showDocs = true;
          },
        },
        {
          icon: 'ph:chat-circle-text-duotone',
          label: 'Get support',
          href: 'https://hanta.atlassian.net/servicedesk/customer/portals',
          target: '_blank',
        },
      ],
    },
    {
      group: 'features',
      items: [
        {
          icon: 'ph:keyboard-duotone',
          label: 'Keyboard shortcuts',
          href: '/shortcuts',
        },
        {
          icon: 'ph:bell-duotone',
          label: "What's new?",
          href: '/changelog',
        },
      ],
    },
    {
      group: 'links',
      items: [
        {
          icon: 'ion:logo-x',
          label: 'X',
          href: 'https://x.com/hanta_io',
          target: '_blank',
        },
        {
          icon: 'ph:file-text-duotone',
          label: 'Terms & privacy',
          href: 'https://hanta.io/legal',
          target: '_blank',
        },
        {
          icon: 'ph:chart-line-up-duotone',
          label: 'Status',
          href: 'https://status.hanta.io/',
          target: '_blank',
        },
      ],
    },
  ];
</script>

<div class="pt-4 mt-auto">
  <DropdownMenu.Root>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <DropdownMenu.Trigger
      class="flex gap-2 items-center px-2 py-1.5 w-full text-sm rounded-md"
    >
      <Icon
        icon="mdi:help"
        class={cn('size-6 bg-muted', $appStore.isCollapsed && 'ml-2')}
      />
      {#if !$appStore.isCollapsed}
        <span>Help</span>
      {/if}
    </DropdownMenu.Trigger>

    <DropdownMenu.Content class="w-72 z-[99999]">
      {#each helpItems as group, index}
        {#if index > 0}
          <DropdownMenu.Separator />
        {/if}
        <DropdownMenu.Group>
          {#each group.items as item}
            <DropdownMenu.Item>
              {#if item.onClick}
                <button
                  class="flex items-center w-full cursor-pointer"
                  on:click={item.onClick}
                >
                  <Icon icon={item.icon} class="mr-2 w-4 h-4" />
                  {item.label}
                </button>
              {:else}
                <a
                  href={item.href}
                  class="flex items-center w-full cursor-pointer"
                  target={item.target}
                  rel={item.target === '_blank'
                    ? 'noopener noreferrer'
                    : undefined}
                >
                  <Icon icon={item.icon} class="mr-2 w-4 h-4" />
                  {item.label}
                </a>
              {/if}
            </DropdownMenu.Item>
          {/each}
        </DropdownMenu.Group>
      {/each}

      <DropdownMenu.Separator />
      <DropdownMenu.Group>
        <DropdownMenu.Item class="text-muted-foreground">
          Version {APP_VERSION}
        </DropdownMenu.Item>
      </DropdownMenu.Group>
    </DropdownMenu.Content>
  </DropdownMenu.Root>
  {#if showDocs}
    <HelpDocs bind:open={showDocs} />
  {/if}
</div>
