<script lang="ts">
  import { contextStore } from '$lib/stores/app-store';
  import * as Drawer from '$lib/components/ui/drawer';
  import HantaObjectView from './hanta-object-view.svelte';
  import HantaObjectContent from './hanta-object-content.svelte';
  import { Button } from '$lib/components/ui/button';

  $: ({ isOpen, popupType, popupData } = $contextStore);
  $: module = popupData?.module;
  $: id = popupData?.id;

  function handleClose() {
    contextStore.closePopup();
  }
</script>

<Drawer.Root
  bind:open={isOpen}
  onOpenChange={open => {
    if (!open) handleClose();
  }}
>
  <Drawer.Content
    class="fixed inset-x-0 bottom-0 mt-24 flex h-[calc(50%-2rem)] flex-col rounded-t-[10px] bg-background"
  >
    <div class="flex flex-col mx-auto w-full max-w-md h-full">
      <Drawer.Header class="p-4">
        <Drawer.Title class="text-lg font-semibold">
          {module ? module.charAt(0).toUpperCase() + module.slice(1) : ''} Details
        </Drawer.Title>
        <Drawer.Description class="text-sm text-muted-foreground">
          Viewing details for {module} with ID: {id}
        </Drawer.Description>
      </Drawer.Header>
      <div class="overflow-y-auto flex-1 p-4">
        {#if popupType === 'crmObject' && module && id}
          <HantaObjectView {module} {id} isPopup={true} let:item>
            <svelte:fragment slot="header">
              <!-- Add any header content specific to the popup view -->
            </svelte:fragment>
            <svelte:fragment slot="actionBar">
              <!-- Add any action bar content specific to the popup view -->
            </svelte:fragment>
            <HantaObjectContent {module} {item} />
          </HantaObjectView>
        {/if}
      </div>
      <Drawer.Footer class="p-4 mt-auto">
        <Button onClick={handleClose} class="w-full">Close</Button>
      </Drawer.Footer>
    </div>
  </Drawer.Content>
</Drawer.Root>

<style>
  :global(.drawer-content) {
    max-height: calc(50vh - 2rem) !important;
    height: calc(50vh - 2rem) !important;
  }

  :global(.drawer-content > div) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :global(.drawer-content > div > div) {
    flex: 1;
    overflow-y: auto;
  }

  :global(.drawer-content > div > div::-webkit-scrollbar) {
    width: 6px;
  }

  :global(.drawer-content > div > div::-webkit-scrollbar-thumb) {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  :global(.drawer-content > div > div::-webkit-scrollbar-track) {
    background-color: rgba(0, 0, 0, 0.1);
  }
</style>
