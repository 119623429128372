import { get } from 'svelte/store';
import { updateItem } from './mutations';

import { supabase } from './supabase';
import { getPrimaryRoutes } from '$lib/config/routes';
import { authStore } from '$lib/stores/auth-store';

export const getTrashItems = async () => {
  const modules = getPrimaryRoutes().map(route => route.module);
  const data = await Promise.all(
    modules.map(async module => {
      const { data, error } = await supabase(get(authStore)?.token)
        .from(module)
        .select(`*`)
        .eq('deleted', true);
      return data?.map(item => ({ ...item, module }));
    }),
  );
  return data.flat().filter(item => item);
};

export async function restoreItem(module, id) {
  await updateItem(module, id, {
    deleted: false,
    deletedAt: null,
    deletedBy: null,
  });
}

export async function deleteItemPermanently(module, id) {
  await supabase(get(authStore)?.token).from(module).delete().eq('id', id);
}

export async function emptyTrash() {
  const items = await getTrashItems();
  await Promise.all(
    items.map(item => deleteItemPermanently(item.module, item.id)),
  );
}
