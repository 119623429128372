import { KPIDescription } from './analytics';

export const REVENUE_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } = {
  grossRevenue: {
    name: 'Gross Revenue',
    description:
      'Total amount of money generated from sales before any deductions',
    formula: 'Sum of all sales revenue before expenses',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Varies by industry',
  },
  netRevenue: {
    name: 'Net Revenue',
    description:
      'Total revenue after deducting returns, discounts, and allowances',
    formula: 'Gross Revenue - Returns - Discounts - Allowances',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Higher is better',
  },
  totalRevenue: {
    name: 'Total Revenue',
    description:
      'The total amount of money generated from all business activities during a specific period',
    formula: 'Sum of all revenue streams',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Depends on business model',
  },
  revenueGrowthMoM: {
    name: 'Month-over-Month Revenue Growth',
    description:
      'Percentage increase/decrease in revenue compared to the previous month',
    formula:
      '((Current Month Revenue - Previous Month Revenue) / Previous Month Revenue) * 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 5% is considered good',
  },
  revenueGrowthYoY: {
    name: 'Year-over-Year Revenue Growth',
    description:
      'Percentage increase/decrease in revenue compared to the same period last year',
    formula:
      '((Current Year Revenue - Previous Year Revenue) / Previous Year Revenue) * 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 10% is considered strong',
  },
  averageRevenuePerCustomer: {
    name: 'Average Revenue Per Customer (ARPC)',
    description:
      'Total revenue divided by the number of customers, indicating customer value',
    formula: 'Total Revenue / Total Number of Customers',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Varies by industry and business model',
  },
  recurringRevenue: {
    name: 'Recurring Revenue',
    description:
      'Predictable and stable revenue generated from ongoing subscriptions or repeat customers',
    formula: 'Sum of all recurring revenue streams',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Higher percentage indicates more stability',
  },
  oneTimeRevenue: {
    name: 'One-Time Revenue',
    description:
      'Revenue generated from single, non-recurring sales or transactions',
    formula: 'Sum of all non-recurring revenue streams',
    idealTrend: 'balanced',
    unit: 'currency',
    benchmark: 'Should complement recurring revenue',
  },
};

export const PROFITABILITY_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } =
  {
    grossProfitMargin: {
      name: 'Gross Profit Margin',
      description:
        'Percentage of revenue remaining after accounting for direct costs of goods sold',
      formula: '((Revenue - COGS) / Revenue) × 100',
      idealTrend: 'increasing',
      unit: 'percentage',
      benchmark: 'Industry average typically 30-50%',
    },
    netProfitMargin: {
      name: 'Net Profit Margin',
      description:
        'Percentage of revenue remaining after all expenses, showing overall profitability',
      formula: '(Net Income / Revenue) × 100',
      idealTrend: 'increasing',
      unit: 'percentage',
      benchmark: 'Healthy range 10-20%',
    },
    ebitda: {
      name: 'EBITDA',
      description:
        'Earnings Before Interest, Taxes, Depreciation, and Amortization - measure of operational performance',
      formula: 'Net Income + Interest + Taxes + Depreciation + Amortization',
      idealTrend: 'increasing',
      unit: 'currency',
      benchmark: 'Higher indicates stronger operational performance',
    },
    operatingExpensesRatio: {
      name: 'Operating Expenses Ratio',
      description:
        'Percentage of revenue spent on operating expenses, indicating operational efficiency',
      formula: '(Operating Expenses / Revenue) × 100',
      idealTrend: 'decreasing',
      unit: 'percentage',
      benchmark: 'Lower indicates better cost control',
    },
    cogs: {
      name: 'Cost of Goods Sold (COGS)',
      description:
        'Direct costs attributable to the production of goods/services sold',
      formula: 'Beginning Inventory + Purchases - Ending Inventory',
      idealTrend: 'decreasing',
      unit: 'currency',
      benchmark: 'Should decrease relative to revenue',
    },
    operatingIncome: {
      name: 'Operating Income',
      description:
        'Profit from core business operations before interest and taxes',
      formula: 'Gross Profit - Operating Expenses',
      idealTrend: 'increasing',
      unit: 'currency',
      benchmark: 'Should show consistent growth',
    },
  };

export const CASH_FLOW_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } = {
  operatingCashFlow: {
    name: 'Operating Cash Flow',
    description:
      'Cash generated from core business operations, excluding investments and financing',
    formula: 'Net Income + Non-Cash Expenses + Changes in Working Capital',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Should be consistently positive and growing',
  },
  freeCashFlow: {
    name: 'Free Cash Flow',
    description:
      'Cash available after operational expenses and capital investments',
    formula: 'Operating Cash Flow - Capital Expenditures',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Positive and stable growth indicates healthy business',
  },
  cashConversionCycle: {
    name: 'Cash Conversion Cycle',
    description:
      'Time taken to convert resource inputs into cash flows from sales',
    formula: 'DIO + DSO - DPO (Days Inventory + Days Sales - Days Payable)',
    idealTrend: 'decreasing',
    unit: 'days',
    benchmark: 'Lower numbers indicate better cash management',
  },
  daysSalesOutstanding: {
    name: 'Days Sales Outstanding (DSO)',
    description: 'Average number of days to collect payment after a sale',
    formula: '(Accounts Receivable / Total Revenue) × 365',
    idealTrend: 'decreasing',
    unit: 'days',
    benchmark: 'Industry average typically 30-60 days',
  },
  accountsReceivableAging: {
    name: 'Accounts Receivable Aging',
    description: 'Analysis of unpaid customer invoices by age categories',
    formula: 'Grouped AR by time periods (30/60/90+ days)',
    idealTrend: 'decreasing',
    unit: 'currency',
    benchmark: 'Majority should be in current to 30-day range',
  },
  cashRunway: {
    name: 'Cash Runway',
    description:
      'Time remaining before cash reserves are depleted at current burn rate',
    formula: 'Cash Balance / Monthly Burn Rate',
    idealTrend: 'increasing',
    unit: 'months',
    benchmark: 'Minimum 12-18 months recommended',
  },
  burnRate: {
    name: 'Burn Rate',
    description: 'Rate at which company is spending its cash reserves',
    formula: 'Starting Cash - Ending Cash / Time Period',
    idealTrend: 'decreasing',
    unit: 'currency/month',
    benchmark: 'Should decrease as company approaches profitability',
  },
};

export const CUSTOMER_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } = {
  customerAcquisitionCost: {
    name: 'Customer Acquisition Cost (CAC)',
    description:
      'Total cost of acquiring a new customer, including marketing and sales expenses',
    formula: 'Total Sales & Marketing Cost / Number of New Customers',
    idealTrend: 'decreasing',
    unit: 'currency',
    benchmark: 'Should decrease over time with efficiency',
  },
  customerLifetimeValue: {
    name: 'Customer Lifetime Value (CLV)',
    description:
      'Total revenue expected from a customer throughout the business relationship',
    formula:
      'Average Purchase Value × Purchase Frequency × Average Customer Lifespan',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Should be at least 3x CAC',
  },
  clvCacRatio: {
    name: 'CLV/CAC Ratio',
    description:
      'Ratio of customer value to acquisition cost, indicating marketing efficiency',
    formula: 'Customer Lifetime Value / Customer Acquisition Cost',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: 'Healthy ratio is 3:1 or higher',
  },
  customerChurnRate: {
    name: 'Customer Churn Rate',
    description: 'Rate at which customers stop doing business with the company',
    formula: '(Lost Customers / Total Customers at Start) × 100',
    idealTrend: 'decreasing',
    unit: 'percentage',
    benchmark: 'Industry average varies, typically 5-7% annually',
  },
  customerGrowthRate: {
    name: 'Customer Growth Rate',
    description: 'Rate of customer base expansion over time',
    formula: '((End Customers - Start Customers) / Start Customers) × 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: 'Should show consistent positive growth',
  },
  customerSatisfactionScore: {
    name: 'Customer Satisfaction Score',
    description: 'Measure of customer satisfaction with products/services',
    formula: '(Total Satisfaction Points / Total Responses) × 100',
    idealTrend: 'increasing',
    unit: 'score',
    benchmark: 'Industry average 75-85%',
  },
};

export const SALES_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } = {
  salesGrowth: {
    name: 'Sales Growth',
    description: 'Rate of increase in sales revenue over time',
    formula:
      '((Current Period Sales - Previous Period Sales) / Previous Period Sales) × 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: 'Should exceed inflation rate + industry growth',
  },
  salesPipelineValue: {
    name: 'Sales Pipeline Value',
    description: 'Total potential revenue from all active opportunities',
    formula: 'Sum of (Deal Value × Probability) for all opportunities',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: '3-4x quarterly sales target',
  },
  conversionRate: {
    name: 'Conversion Rate',
    description: 'Percentage of leads that convert to customers',
    formula: '(Number of Conversions / Total Leads) × 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: 'Industry average 2-5%',
  },
  averageDealSize: {
    name: 'Average Deal Size',
    description: 'Average revenue per closed deal',
    formula: 'Total Revenue / Number of Deals',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Should trend upward with market expansion',
  },
  salesCycleLength: {
    name: 'Sales Cycle Length',
    description: 'Average time to close a deal',
    formula: 'Average time from first contact to deal closure',
    idealTrend: 'decreasing',
    unit: 'days',
    benchmark: 'Industry specific, typically 3-6 months B2B',
  },
  winRate: {
    name: 'Win Rate',
    description: 'Percentage of opportunities that result in closed deals',
    formula: '(Won Opportunities / Total Opportunities) × 100',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: 'Industry average 20-40%',
  },
};

export const MARKET_GROWTH_KPI_DESCRIPTIONS: { [key: string]: KPIDescription } =
  {
    marketShare: {
      name: 'Market Share',
      description: 'Percentage of total market sales captured by the company',
      formula: '(Company Sales / Total Market Sales) × 100',
      idealTrend: 'increasing',
      unit: 'percentage',
      benchmark: 'Industry dependent, higher is better',
    },
    marketGrowthRate: {
      name: 'Market Growth Rate',
      description: 'Rate at which the total addressable market is expanding',
      formula:
        '((Current Market Size - Previous Market Size) / Previous Market Size) × 100',
      idealTrend: 'increasing',
      unit: 'percentage',
      benchmark: 'Should exceed GDP growth rate',
    },
    newMarketPenetration: {
      name: 'New Market Penetration',
      description:
        'Success rate in entering and establishing presence in new markets',
      formula: 'New Market Revenue / Total New Market Opportunity',
      idealTrend: 'increasing',
      unit: 'percentage',
      benchmark: '> 10% in first year',
    },
    competitiveIndex: {
      name: 'Competitive Index',
      description:
        'Measure of competitive position relative to key competitors',
      formula:
        'Weighted average of market share, growth rate, and customer satisfaction',
      idealTrend: 'increasing',
      unit: 'score',
      benchmark: '> 7.5 out of 10',
    },
    brandStrength: {
      name: 'Brand Strength',
      description: 'Measure of brand recognition and reputation in the market',
      formula: 'Composite of brand awareness, preference, and loyalty metrics',
      idealTrend: 'increasing',
      unit: 'score',
      benchmark: '> 80 out of 100',
    },
    marketExpansionRate: {
      name: 'Market Expansion Rate',
      description:
        'Rate at which the company is expanding into new markets or segments',
      formula: 'New Market Revenue Growth / Total Revenue Growth',
      idealTrend: 'increasing',
      unit: 'ratio',
      benchmark: '> 0.25',
    },
  };

export const OPERATIONAL_EFFICIENCY_KPI_DESCRIPTIONS: {
  [key: string]: KPIDescription;
} = {
  employeeProductivity: {
    name: 'Employee Productivity',
    description: 'Measure of output per employee',
    formula: 'Total Revenue / Number of Full-Time Employees',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Industry specific, higher is better',
  },
  operationalEfficiency: {
    name: 'Operational Efficiency',
    description: 'Ratio of operating expenses to revenue',
    formula: 'Operating Expenses / Total Revenue',
    idealTrend: 'decreasing',
    unit: 'ratio',
    benchmark: '< 0.7',
  },
  assetUtilization: {
    name: 'Asset Utilization',
    description: 'Efficiency of asset usage in generating revenue',
    formula: 'Total Revenue / Total Assets',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 1.0',
  },
  processEfficiency: {
    name: 'Process Efficiency',
    description: 'Measure of process completion time and resource usage',
    formula: 'Standard Process Time / Actual Process Time',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 0.85',
  },
  resourceUtilization: {
    name: 'Resource Utilization',
    description: 'Percentage of available resources being effectively used',
    formula: 'Used Resources / Available Resources',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 80%',
  },
  automationRate: {
    name: 'Automation Rate',
    description: 'Percentage of processes that are automated',
    formula: 'Automated Processes / Total Processes',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 60%',
  },
};

export const WORKING_CAPITAL_KPI_DESCRIPTIONS: {
  [key: string]: KPIDescription;
} = {
  workingCapitalRatio: {
    name: 'Working Capital Ratio',
    description:
      "Measure of company's ability to pay off current liabilities with current assets",
    formula: 'Current Assets / Current Liabilities',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 1.5',
  },
  netWorkingCapital: {
    name: 'Net Working Capital',
    description: 'Amount of current assets in excess of current liabilities',
    formula: 'Current Assets - Current Liabilities',
    idealTrend: 'increasing',
    unit: 'currency',
    benchmark: 'Positive and growing with business size',
  },
  cashConversionCycle: {
    name: 'Cash Conversion Cycle',
    description: 'Time taken to convert resource inputs into cash flows',
    formula: 'DIO + DSO - DPO',
    idealTrend: 'decreasing',
    unit: 'days',
    benchmark: '< 90 days',
  },
  inventoryTurnover: {
    name: 'Inventory Turnover',
    description: 'Number of times inventory is sold and replaced over a period',
    formula: 'Cost of Goods Sold / Average Inventory',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 4 times per year',
  },
  receivablesTurnover: {
    name: 'Receivables Turnover',
    description: 'Number of times receivables are collected over a period',
    formula: 'Net Credit Sales / Average Accounts Receivable',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 8 times per year',
  },
  payablesTurnover: {
    name: 'Payables Turnover',
    description: 'Number of times payables are paid over a period',
    formula: 'Total Supplier Purchases / Average Accounts Payable',
    idealTrend: 'stable',
    unit: 'ratio',
    benchmark: '8-12 times per year',
  },
};

export const BUSINESS_HEALTH_KPI_DESCRIPTIONS: {
  [key: string]: KPIDescription;
} = {
  debtToEquityRatio: {
    name: 'Debt to Equity Ratio',
    description: "Measure of company's financial leverage",
    formula: "Total Debt / Total Shareholders' Equity",
    idealTrend: 'decreasing',
    unit: 'ratio',
    benchmark: '< 2.0',
  },
  interestCoverageRatio: {
    name: 'Interest Coverage Ratio',
    description: 'Ability to meet interest payments on outstanding debt',
    formula: 'EBIT / Interest Expenses',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 2.0',
  },
  returnOnEquity: {
    name: 'Return on Equity (ROE)',
    description: 'Measure of financial performance and efficiency',
    formula: "Net Income / Shareholders' Equity",
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 15%',
  },
  returnOnAssets: {
    name: 'Return on Assets (ROA)',
    description: 'Measure of how efficiently assets are being used',
    formula: 'Net Income / Total Assets',
    idealTrend: 'increasing',
    unit: 'percentage',
    benchmark: '> 5%',
  },
  quickRatio: {
    name: 'Quick Ratio',
    description: 'Measure of immediate short-term liquidity',
    formula: '(Current Assets - Inventory) / Current Liabilities',
    idealTrend: 'increasing',
    unit: 'ratio',
    benchmark: '> 1.0',
  },
  zScore: {
    name: 'Altman Z-Score',
    description: 'Predictor of probability of business failure',
    formula: '1.2×WC/TA + 1.4×RE/TA + 3.3×EBIT/TA + 0.6×MVE/TL + 1.0×S/TA',
    idealTrend: 'increasing',
    unit: 'score',
    benchmark: '> 2.99 Safe, 1.81-2.99 Grey Zone, < 1.81 Distress',
  },
};
