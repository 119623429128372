<script lang="ts">
  import { cn } from '$lib/utils/hanta-utils';
  import {
    type InputEvents,
    type Props,
    inputVariants,
    type Size,
    type Variant,
  } from '.';

  type $$Props = Props;

  type $$Events = InputEvents;

  export let value: $$Props['value'] = undefined;
  export let variant: Variant = 'default';

  let className: $$Props['class'] = undefined;
  export { className as class };

  let preventScroll = event => {
    event.preventDefault();
  };
</script>

<input
  class={cn(inputVariants({ variant, className }))}
  bind:value
  autocomplete="off"
  on:blur
  on:change
  on:click
  on:focus
  on:focusin
  on:focusout
  on:keydown
  on:keypress
  on:keyup
  on:mouseover
  on:mouseenter
  on:mouseleave
  on:paste
  on:input
  on:wheel={preventScroll}
  {...$$restProps}
/>
