<script lang="ts">
  import { onMount } from 'svelte';
  import { savePipelineItems } from '$lib/api/pipeline-api';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import PipelineBuilder from '$lib/components/pipelines/pipeline-builder.svelte';
  import { Button } from '$lib/components/ui/button';
  import { deletePipeline } from '$lib/api/pipeline-api';

  import Icon from '@iconify/svelte';
  import type { Pipeline, Phase } from '$lib/types/pipeline';

  export let selectedPipelineId: string | null = null;

  let isPreviewMode = true;
  let phases: Phase[] = [];

  // Subscribe to pipelines store
  let pipelines: Pipeline[] = [];
  pipelineStore.subscribe(value => {
    pipelines = value;
    if (!selectedPipelineId) {
      selectedPipelineId = pipelines[0]?.id;
    }
  });

  $: selectedPipeline = pipelines.find(p => p.id === selectedPipelineId);
  $: if (selectedPipeline) {
    phases = selectedPipeline.phases;
    if (phases.length === 0) {
      isPreviewMode = false;
    }
  }

  onMount(() => {
    pipelineStore.loadPipelines();
  });

  function handlePhaseReorder(updatedPhases: Phase[]) {
    phases = updatedPhases.map((phase, index) => ({
      ...phase,
      order: index,
    }));
    if (selectedPipeline) {
      // pipelineStore.updatePipeline(selectedPipeline.id, { phases });
    }
  }

  function handleTogglePreview() {
    isPreviewMode = !isPreviewMode;
  }

  function onPhaseAdd() {
    phases = [
      ...phases,
      {
        id: crypto.randomUUID(),
        name: '',
        description: '',
        order: phases.length,
        items: [],
      },
    ];
    selectedPipeline.phases = phases;
    // pipelineStore.updatePipeline(selectedPipeline.id, selectedPipeline);
  }

  function onPhaseRemove(id: string) {
    phases = phases.filter(p => p.id !== id);
    selectedPipeline.phases = phases;
    // pipelineStore.updatePipeline(selectedPipeline.id, selectedPipeline);
  }

  function onSavePipeline() {
    if (selectedPipeline) {
      pipelineStore.updatePipeline(selectedPipeline);
      isPreviewMode = true;
    }
  }

  function onDuplicatePipeline() {
    if (selectedPipeline) {
      // pipelineStore.duplicatePipeline(selectedPipeline.id);
    }
  }

  async function onChange(phase: Phase, items: any[]) {
    await savePipelineItems(phase.id, items);
  }

  function onPhaseReorder(updatedPhases: Phase[]) {
    phases = updatedPhases.map((phase, index) => ({
      ...phase,
      order: index,
    }));
  }

  async function onDeletePipeline() {
    if (selectedPipeline) {
      pipelineStore.deletePipeline(selectedPipeline.id);
      await deletePipeline(selectedPipeline.id);
    }
  }
</script>

<div class="w-full">
  <div class="absolute top-4 left-48 flex items-center space-x-2">
    {#if selectedPipeline}
      <Button
        variant={isPreviewMode ? 'ghost' : 'outline'}
        class="w-48"
        on:click={handleTogglePreview}
      >
        <Icon
          icon={isPreviewMode ? 'mdi:cog' : 'mdi:eye'}
          class="mr-2 w-4 h-4"
        />
        {isPreviewMode ? 'Configure' : 'Preview'}
      </Button>
    {/if}
  </div>

  {#if selectedPipeline}
    <PipelineBuilder
      pipeline={selectedPipeline}
      {isPreviewMode}
      {onPhaseAdd}
      {onPhaseRemove}
      {onPhaseReorder}
      {onChange}
      {onSavePipeline}
    />
  {:else}
    <div class="py-12 text-center text-muted-foreground">
      <p>Select a pipeline to begin</p>
    </div>
  {/if}
</div>
