<script lang="ts">
  import { Calendar as CalendarPrimitive } from 'bits-ui';
  import { cn } from '$lib/utils/hanta-utils';

  type $$Props = CalendarPrimitive.HeadingProps;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<CalendarPrimitive.Heading
  let:headingValue
  class={cn('text-sm font-medium', className)}
  {...$$restProps}
>
  <slot {headingValue}>
    {headingValue}
  </slot>
</CalendarPrimitive.Heading>
