<script lang="ts">
  import Icon from '@iconify/svelte';
  import { tick, onMount } from 'svelte';
  import * as Command from '$lib/components/ui/command/index.js';
  import * as Popover from '$lib/components/ui/popover/index.js';
  import { Button } from '$lib/components/ui/button/index.js';
  import { cn } from '$lib/utils/hanta-utils.js';

  export let pricelists = [];
  export let selectedPricelist;

  let open = false;

  onMount(() => {
    // Select the first pricelist as default if there are any pricelists
    // and no pricelist is currently selected
    if (pricelists.length > 0 && !selectedPricelist) {
      selectedPricelist = pricelists[0].value;
    }
  });

  $: selectedValue =
    pricelists.find(f => f.value === selectedPricelist)?.label ??
    'Select a pricelist...';

  // We want to refocus the trigger button when the user selects
  // an item from the list so users can continue navigating the
  // rest of the form with the keyboard.
  function closeAndFocusTrigger(triggerId: string) {
    open = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
    });
  }
</script>

<Popover.Root bind:open let:ids>
  <Popover.Trigger asChild let:builder>
    <Button
      builders={[builder]}
      variant="outline"
      role="combobox"
      aria-expanded={open}
      class="justify-between"
    >
      {selectedValue}
      <Icon icon="mdi:chevron-down" class="ml-2 opacity-50 size-4 shrink-0" />
    </Button>
  </Popover.Trigger>
  <Popover.Content class="p-0">
    <Command.Root>
      <Command.Input placeholder="Search a pricelist..." class="h-9" />
      <Command.Empty>No pricelist found.</Command.Empty>
      <Command.Group class="overflow-y-auto max-h-60">
        {#each pricelists as pricelist}
          <Command.Item
            value={pricelist.value}
            onSelect={currentValue => {
              selectedPricelist = currentValue;
              closeAndFocusTrigger(ids.trigger);
            }}
          >
            <Icon
              icon="mdi:check"
              class={cn(
                'mr-2 h-4 w-4',
                selectedPricelist !== pricelist.value && 'text-transparent',
              )}
            />
            {pricelist.label}
          </Command.Item>
        {/each}
      </Command.Group>
    </Command.Root>
  </Popover.Content>
</Popover.Root>
