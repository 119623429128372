<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import { currencyFormatter } from '$lib/app/utils';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import Button from '$lib/components/ui/button/button.svelte';
  import HantaInputCurrency from '$lib/widgets/input/hanta-input-currency.svelte';
  import HantaInputNumber from '$lib/widgets/input/hanta-input-number.svelte';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import { Switch } from '$lib/components/ui/switch/index.js';
  import * as HoverCard from '$lib/components/ui/hover-card';

  import { createEventDispatcher } from 'svelte';
  import Label from '$lib/components/ui/label/label.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import OrderInvoiceSelection from './orders/order-invoice-selection.svelte';

  export let orderType: 'new' | 'existing' | 'one time' = 'new';

  export let form;
  export let formData;
  export let positionIdx = 0;

  let newOrder = {
    name: '',
    weight: '',
    price: '',
    unit: 'Stunden',
  };

  const dispatch = createEventDispatcher();

  function closeModal() {
    dispatch('close');
  }

  function saveOrder() {
    //TODO: validate
    const order = newOrder;

    dispatch('save', { order });
    closeModal();
  }
  const format: (value: number) => string = currencyFormatter().format;

  export const calculatePosition = (position: {
    quantity: number;
    price: number;
    tax: number;
    unit: string;
  }): { net: number; tax: number; sum: number; unit: string } => {
    if (!position) {
      return { net: 0, tax: 0, sum: 0, unit: '' };
    }

    const withoutTax =
      position.unit === 'pauschal'
        ? position.price
        : position.quantity * position.price;
    const tax = withoutTax * (position.tax / 100.0);
    return {
      net: withoutTax,
      tax: tax,
      sum: withoutTax + tax,
      unit: position.unit,
    };
  };
</script>

<div
  class="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50"
>
  <div class="p-6 w-full max-w-lg rounded-lg shadow-lg bg-card">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Add Order</h2>
      <Button variant="ghost" on:click={closeModal}>
        <Icon icon="mdi:close" class="size-6" />
      </Button>
    </div>
    <div class="space-y-4">
      <div class="flex gap-6 text-base">
        <div class="flex items-center">
          <input
            type="radio"
            id="existing-order"
            name="order-type"
            bind:group={orderType}
            value="existing"
          />
          <label for="existing-order" class="ml-2">Existing Orders</label>
        </div>

        <!--div class="flex items-center">
          <input
            type="radio"
            id="new-order"
            name="order-type"
            bind:group={orderType}
            value="new"
          />
          <label for="new-order" class="ml-2">New Order</label>
        </div-->
        <div class="flex items-center">
          <input
            type="radio"
            id="free-position"
            name="order-type"
            bind:group={orderType}
            value="one time"
          />
          <label for="free-position" class="ml-2">One time item</label>
        </div>
      </div>
      {#if orderType === 'existing'}
        <div>
          <label
            for="existing-orders"
            class="block text-sm font-medium text-gray-700"
            >Select Order(s)</label
          >

          <OrderInvoiceSelection customerId={formData.customer?.id} />
        </div>
      {:else if orderType === 'new'}
        <div class="space-y-4">
          <div>
            <label
              for="order-name"
              class="block text-sm font-medium text-gray-700">Name</label
            >
            <input
              type="text"
              id="order-name"
              bind:value={newOrder.name}
              class="block mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              for="order-weight"
              class="block text-sm font-medium text-gray-700">Weight</label
            >
            <input
              type="text"
              id="order-weight"
              bind:value={newOrder.weight}
              class="block mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              for="order-price"
              class="block text-sm font-medium text-gray-700">Unit price</label
            >
            <input
              type="text"
              id="order-price"
              bind:value={newOrder.price}
              class="block mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      {:else if orderType === 'one time' && formData.positions[positionIdx]}
        <div class="gap-2 items-baseline self-start space-y-2">
          <div class="w-full !leading-1 space-y-2">
            <Label for="description">Description</Label>

            <HantaInputTextArea
              id="description"
              class="ring-1 ring-gray-300"
              {form}
              rows={6}
              name="positions[{positionIdx}].text"
              placeholder="Enter position description..."
            />
          </div>
          <div>
            <div class="flex items-center space-x-2">
              <Switch
                id="airplane-mode"
                bind:checked={formData.positions[positionIdx].supplyDate}
              />
              <Label for="airplane-mode">Supply date</Label>

              <HoverCard.Root>
                <HoverCard.Trigger>
                  <Icon icon="mdi:information" class="size-4" />
                </HoverCard.Trigger>
                <HoverCard.Content>
                  If the supply date range for this line item is different from
                  the invoice issue date, specify the date range below. This
                  setting may be required for compliance in certain
                  jurisdictions.
                </HoverCard.Content>
              </HoverCard.Root>
            </div>
            {#if true || formData.positions[positionIdx].supplyDate}
              <div class="flex gap-2 items-center p-4 mt-2">
                <HantaInputDate {form} label={'From'} name="from" />
                <Icon icon="mdi:arrow-right" class="size-4" />
                <HantaInputDate {form} label={'To'} name="to" />
              </div>
            {/if}
          </div>

          <div class="grid grid-cols-3 gap-4 justify-items-end items-baseline">
            <div class="w-20 text-sm font-medium text-right shrink-0 grow-0">
              Quantity
            </div>
            <div class="w-20 text-sm font-medium text-right shrink-0 grow-0">
              Unit
            </div>
            <div class="w-20 text-sm font-medium text-right shrink-0 grow-0">
              Price
            </div>

            <HantaInputNumber
              alignRight={true}
              asFloat={true}
              {form}
              name="positions[{positionIdx}].quantity"
            />

            <HantaSelect
              searchable={false}
              bind:value={newOrder.unit}
              options={[
                { label: 'Stunden', value: 'Stunden' },
                { label: 'pauschal', value: 'pauschal' },
                { label: 'Stück', value: 'Stück' },
              ]}
            />

            <HantaInputCurrency {form} name="positions[{positionIdx}].price" />
          </div>

          <div
            class="grid grid-cols-3 gap-2 justify-items-end items-baseline py-4"
          >
            <div
              class="col-span-2 w-20 text-sm font-medium text-right shrink-0 grow-0"
            >
              Net
            </div>
            <div class="text-lg text-right">
              {format(calculatePosition(formData.positions[positionIdx]).net)}
            </div>
            <div
              class="col-span-2 w-20 text-sm font-medium text-right shrink-0 grow-0"
            >
              Tax rate
            </div>

            <HantaInputSelect
              asInt={true}
              {form}
              name="positions[{positionIdx}].tax"
              options={[
                { label: '0%', value: '0' },
                { label: '7%', value: '7' },
                { label: '19%', value: '19' },
              ]}
            />
            <div
              class="col-span-2 w-20 text-sm font-medium text-right uppercase shrink-0 grow-0"
            >
              Total
            </div>
            <div class="text-lg text-right">
              {format(
                calculatePosition(formData.positions[positionIdx]).net *
                  (1 + formData.positions[positionIdx].tax / 100),
              )}
            </div>
          </div>
        </div>
      {/if}
    </div>
    <div class="flex justify-end mt-6 space-x-2">
      <Button variant="secondary" on:click={closeModal}>Close</Button>
      <Button on:click={saveOrder}>Save</Button>
    </div>
  </div>
</div>

<style>
  input[type='radio'] {
    @apply size-4;
  }
</style>
