<script lang="ts">
  import Separator from './../../components/ui/separator/separator.svelte';
  import { formatDate } from './../../pages/invoices/invoice-utils';
  import { getIconForActivityType } from '$lib/config/crm-config';
  import { createEventDispatcher } from 'svelte';
  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { Badge } from '$lib/components/ui/badge';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';

  export let item: any;
  export let module: string;
  export let withSelection: boolean;
  export let selected: any = [];
  export let disabledIds = [];

  const dispatch = createEventDispatcher();
</script>

{#if module === 'accounts'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="absolute top-0 right-0 p-2 border">
      <Avatar.Root class="rounded-none size-8">
        <Avatar.Image
          src={item.logo}
          alt="Company Logo"
          class="object-scale-down rounded-none"
        />

        <Avatar.Fallback
          class="w-full p-1 text-[8px] text-center text-muted-foreground rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
        >
          {item.name ?? ''}
        </Avatar.Fallback>
      </Avatar.Root>
    </div>
    <div class="flex-1 mr-14 space-y-1">
      <div class="mb-4 text-card-foreground">
        {item.name ?? ''}
      </div>
      <div class="">
        <div class="flex items-center space-x-1">
          {#if item.address?.city}
            <Icon icon="mdi:map-marker" class="w-4 h-4" />
            <div>
              {item.address.city}
            </div>
          {/if}
        </div>

        {#if item.linkedin}
          <div class="block -ml-3">
            <LinkedinButton linkedin={item.linkedin} />
          </div>
        {/if}
      </div>
    </div>
  </div>
{:else if module === 'tariffs'}
  <div class="min-h-[80px]">
    <div class="mb-4 text-xs font-semibold">
      {item.refId}
    </div>
    <div class="overflow-hidden w-full text-card-foreground font-semibold">
      <div class="font-light text-md">{item.name}</div>
    </div>
  </div>
{:else if module === 'issues' || module === 'contracts'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="overflow-hidden w-full">
      <div class="flex justify-between items-center">
        <div class="text-xs text-muted-foreground">
          {item.refId}
        </div>
        <Badge variant="secondary" class="rounded-none">
          {item.status}
        </Badge>
      </div>
      {#if item.name && item.name !== item.refId}
        <div class="mt-1 font-light text-md text-card-foreground font-semibold">
          {item.name}
        </div>
      {/if}

      <CrmAvatar
        size="size-8 my-2"
        module={'accounts'}
        id={item.account?.id}
        name={item.account?.name}
        withBorder={false}
        withHover={false}
      />
    </div>
  </div>
{:else if module === 'invoices'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="overflow-hidden w-full">
      <div class="flex justify-between font-light text-md">
        <Badge variant="outline" class="text-xs rounded-none">
          <div>
            {new Intl.NumberFormat('de-DE', {
              style: 'currency',
              currency: 'EUR',
            }).format(item.gross)}
          </div>
        </Badge>

        <Badge
          variant="outline"
          class={cn(
            'mt-2 rounded-none',
            item.status === 'Overdue' && 'bg-yellow-600 text-white',
            item.status === 'Paid' && 'bg-green-600 text-white',
            item.status === 'Cancellation invoice' && 'bg-red-600 text-white',
            item.status === 'Cancelled' && 'bg-red-600 text-white',
          )}
        >
          {item.status ?? ''}</Badge
        >
      </div>

      <div class="mt-2">
        <div class="flex space-x-2 text-xs">
          <div class="text-muted-foreground">
            {item.invoiceId}
          </div>
          <Separator orientation="vertical" />
          <div class="text-xs text-muted-foreground">
            {formatDate(item.invoiceDate)}
          </div>
        </div>

        <div class="text-card-foreground font-semibold">
          <CrmAvatar
            size="size-8 my-2 "
            module={'accounts'}
            id={item.customer?.id}
            name={item.customer?.name}
            withBorder={true}
            withHover={false}
          />
        </div>
      </div>
    </div>
  </div>
{:else if module === 'leads'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="overflow-hidden w-full">
      <CrmAvatar
        size="size-8"
        module={'accounts'}
        id={item.customer?.id}
        name={item.customer?.name}
        withBorder={true}
        withHover={false}
      />
      <div class="mt-4 font-light text-md text-card-foreground">
        {item.name}
      </div>
    </div>
  </div>
{:else if module === 'orders'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="overflow-hidden w-full">
      <div class="flex justify-end items-center mb-2">
        <Badge variant="outline" class="rounded-none">
          {item.status ?? 'n/a'}
        </Badge>
      </div>
      <CrmAvatar
        size="size-8"
        module={'accounts'}
        id={item.customer?.id}
        name={item.customer?.name}
        withBorder={true}
        withHover={false}
      />
      <div class="mt-4 font-light text-md text-card-foreground">
        {item.name}
      </div>
    </div>
  </div>
{:else if module === 'deals'}
  <div class="relative flex w-full gap-2 min-h-[80px]">
    <div class="overflow-hidden w-full">
      <CrmAvatar
        size="size-8"
        module={'accounts'}
        id={item.accounts?.id}
        name={item.accounts?.name}
        withBorder={false}
        withHover={false}
      />
      <div class="mt-4 font-light text-md text-card-foreground">
        {item.name}
      </div>
    </div>
  </div>
{:else if module === 'activities'}
  <div class="relative w-full gap-2 min-h-[80px]">
    <div class="flex-1 space-y-2">
      <div class="flex items-center justify-between">
        <Icon icon={getIconForActivityType(item.type)} class="w-6 h-6" />
        <h3
          class={cn(
            'text-md font-semibold text-card-foreground',
            item.done ? 'line-through text-muted-foreground' : '',
          )}
        >
          {item.name ?? ''}
        </h3>
      </div>

      <div class="flex items-center space-x-3 text-sm text-muted-foreground">
        <div class="flex items-center space-x-1">
          <Icon icon="mdi:calendar" class="w-4 h-4" />
          <span>{formatDate(item.startDate)} - {formatDate(item.dueDate)}</span>
        </div>
      </div>
    </div>
  </div>
{:else if module === 'contacts'}
  {@const isSelected = selected?.find(el => el?.id === item.id)}
  <div class="relative flex items-start w-full gap-2 group min-h-[80px]">
    <div class="">
      {#if withSelection}
        {@const disabled = disabledIds.includes(item.id)}
        <Avatar.Root
          class={cn(
            'size-12 ',
            isSelected && 'ring-4 ring-yellow-500 relative',
            disabled && 'grayscale',
          )}
        >
          <Button
            variant="outline"
            on:click={e => {
              if (disabled) return;
              dispatch('select', {
                module,
                id: item.id,
                name: item.name,
              });
              e.stopPropagation();
            }}
            class={cn(
              'p-0 rounded-full size-16',
              withSelection && !disabled && 'group-hover:opacity-40',
            )}
          >
            <Avatar.Image
              src={item.photo}
              alt={item.name}
              class={cn(
                'absolute inset-0 object-scale-down',
                withSelection && !disabled && 'group-hover:opacity-0',
              )}
            />
            <Avatar.Fallback
              class="absolute inset-0 p-1 w-full text-base text-center bg-gradient-to-r from-gray-300 to-gray-100 rounded-none text-primary-400"
            >
              {item.firstname?.substring(0, 1) + item.lastname?.substring(0, 1)}
            </Avatar.Fallback>
            {#if !disabled}
              {#if isSelected}
                <div
                  class="absolute inset-0 w-full bg-yellow-900 rounded-full opacity-70"
                >
                  <Icon
                    icon="mdi:check"
                    class="mx-auto mt-3 text-white size-6"
                  />
                </div>
              {:else}
                <div
                  class="hidden absolute inset-0 w-full bg-yellow-900 rounded-full opacity-70 group-hover:block"
                >
                  <Icon
                    icon="mdi:circle"
                    class="mx-auto mt-3 text-white size-6"
                  />
                </div>
              {/if}
            {/if}
          </Button>
        </Avatar.Root>
      {:else}
        <div
          class="object-cover absolute -top-3 -left-3 p-0.5 rounded-none grayscale text-primary/10 bg-card/80 size-24"
        >
          {#if item.photo}
            <img src={item.photo} alt={item.name} />
          {:else}
            <div class="p-2 w-full text-xl">
              {item.firstname?.substring(0, 1) + item.lastname?.substring(0, 1)}
            </div>
          {/if}
        </div>
      {/if}
    </div>
    <div class="w-full text-card-foreground">
      <div class={cn('', withSelection ? 'ml-0' : 'ml-24 min-h-20')}>
        <div class="font-bold">{item.firstname ?? ''}</div>
        <div class="font-bold">
          {item.lastname ?? ''}
        </div>
        {#if !withSelection && item.linkedin}
          <div class="-ml-4">
            <LinkedinButton linkedin={item.linkedin} />
          </div>
        {/if}
      </div>
      <div class="pt-2 mt-1 text-muted-foreground line-clamp-2">
        {item.position ?? ''}
      </div>
    </div>
  </div>
{:else}
  <div class="text-card-foreground min-h-[80px]">{item.name}</div>
{/if}
