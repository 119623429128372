<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import * as Checkbox from '$lib/components/ui/checkbox';
  import { Button } from '$lib/components/ui/button';
  import { popupStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { getItemById } from '$lib/api/queries';
  import type { Pipeline } from '$lib/types/pipeline';
  import { formatDistanceToNow } from 'date-fns';

  export let item;
  export let pipeline: Pipeline;
  let checked = false;

  async function openInfo() {
    const popupItem = item['account'] || item['contact'] || item['invoice'];

    popupStore.openPopup('crmObject', {
      module: pipeline?.module,
      id: popupItem.id,
    });
  }

  $: daysInStatus = formatDistanceToNow(
    new Date(item.statusChangedAt || item.createdAt),
  );
  $: notesCount = item.notes?.length || 0;
  $: commentsCount = item.comments?.length || 0;
  $: tasksCount = item.tasks?.length || 0;
  $: completedTasksCount = item.tasks?.filter(t => t.completed)?.length || 0;
</script>

<div class="overflow-hidden relative rounded-lg border bg-background">
  <!-- Header with checkbox, name and action button -->
  <div class="flex justify-between items-center p-2 border-b">
    <div class="flex overflow-hidden gap-2 items-center">
      <Checkbox.Root
        {checked}
        onCheckedChange={() => (checked = !checked)}
        class="size-4"
      />
      <span class="text-sm font-medium">
        {#if pipeline?.module === 'accounts'}
          <CrmAvatar
            id={item?.account?.id}
            module="accounts"
            size="size-4"
            asDiv={true}
          />
        {:else if pipeline?.module === 'contacts'}
          {#if item?.candidateObj}
            <Avatar.Root class="rounded-none size-12">
              <Avatar.Image src={item?.candidateObj?.photo} />
              <Avatar.Fallback>
                {item?.candidateObj?.name?.substring(0, 1)}.
              </Avatar.Fallback>
            </Avatar.Root>
          {:else}
            <CrmAvatar
              id={item?.candidate}
              module="contacts"
              size="size-16"
              asDiv={true}
              class="rounded-none size-16"
            />
          {/if}
        {/if}
      </span>
    </div>
    <Button variant="ghost" size="icon" class="px-2 size-8" on:click={openInfo}>
      <Icon icon="mdi:open-in-new" class="size-4" />
    </Button>
  </div>

  <!-- Card Content -->
  <div class="p-3">
    <div>{item.data ?? 'No data'}</div>
    {#if item?.description}
      <div class="mt-2 text-base font-extralight overflow-clip">
        {item?.description}
      </div>
    {/if}
  </div>

  <!-- Footer -->
  <div
    class="flex justify-between items-center px-3 py-2 text-sm border-t text-muted-foreground"
  >
    <div class="flex gap-4 items-center">
      <!-- Tasks -->
      <div class="flex gap-1 items-center">
        <Icon icon="mdi:checkbox-outline" class="size-4" />
        <span>{completedTasksCount}/{tasksCount}</span>
      </div>

      <!-- Notes -->
      <div class="flex gap-1 items-center">
        <Icon icon="mdi:note-outline" class="size-4" />
        <span>{notesCount}</span>
      </div>

      <!-- Comments -->
      <div class="flex gap-1 items-center">
        <Icon icon="mdi:comment-outline" class="size-4" />
        <span>{commentsCount}</span>
      </div>
    </div>

    <!-- Time in status -->
    <div class="flex gap-1 items-center">
      <Icon icon="mdi:clock-outline" class="size-4" />
      <span>{daysInStatus}</span>
    </div>
  </div>
</div>

<style>
  /* Optional: Add hover states for the footer items */
  .flex.items-center.gap-1 {
    cursor: pointer;
  }
  .flex.items-center.gap-1:hover {
    color: hsl(var(--foreground));
  }
</style>
