<script lang="ts">
  import { appStore } from '$lib/stores/app-store';

  $: showLinkedInHeader =
    (!!$appStore.linkedInAccount || !!$appStore.linkedInContact) &&
    import.meta.env.MODE === 'plugin';
</script>

{#if showLinkedInHeader}
  <div class="flex justify-between items-center m-2 w-full">
    <div class="flex justify-center items-center space-x-2">
      <img
        class="h-6"
        src="https://uploads.t-next.io/public/eu-central-1/f992dc07-8f55-4bcd-bbac-871d8b20eb43/AUTOx2400/logo.png"
        alt="Hanta Logo"
      />
      <h5 class="font-extralight text-md">
        makes your LinkedIn better. Instantly.
      </h5>
    </div>
  </div>
{/if}
