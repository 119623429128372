<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import NewPipelineDialog from './new-pipeline-dialog.svelte';
  import { pipelineStore } from '$lib/stores/pipeline-store';
  import type { Pipeline } from '$lib/types/pipeline';

  export let onCreate: () => void;
  const moduleTypes = [
    { value: 'accounts', label: 'Accounts', icon: 'mdi:account' },
    { value: 'deals', label: 'Deals', icon: 'mdi:deal' },
    { value: 'activities', label: 'Activities', icon: 'mdi:activity' },
    { value: 'files', label: 'Files', icon: 'mdi:file' },
    { value: 'contacts', label: 'Contacts', icon: 'mdi:contact' },
  ];

  let showNewPipelineDialog = false;
  let newPipeline: Pipeline = createEmptyPipeline();

  function createEmptyPipeline(): Pipeline {
    return {
      id: crypto.randomUUID(),
      name: '',
      module: moduleTypes[0].value,
      modifySource: false,
      sourceColumn: '',
      phases: [],
    };
  }

  function handleCreatePipeline() {
    pipelineStore.addPipeline(newPipeline);
    showNewPipelineDialog = false;
    newPipeline = createEmptyPipeline();
    if (onCreate) {
      onCreate();
    }
  }
</script>

<Button
  variant="ghost"
  size="icon"
  on:click={() => (showNewPipelineDialog = true)}
>
  <Icon icon="mdi:plus" class="w-4 h-4" />
  <span class="sr-only">New Pipeline</span>
</Button>

<NewPipelineDialog
  bind:showDialog={showNewPipelineDialog}
  bind:pipeline={newPipeline}
  {moduleTypes}
  onClose={() => {
    showNewPipelineDialog = false;
    newPipeline = createEmptyPipeline();
  }}
  onCreate={handleCreatePipeline}
/>
