<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import * as Dialog from '$lib/components/ui/dialog';
  import { Input } from '$lib/components/ui/input';
  import { Checkbox } from '$lib/components/ui/checkbox';
  import { Label } from '$lib/components/ui/label';

  export let open = false;
  export let mode: 'create' | 'edit' = 'create';
  export let viewName = '';
  export let shareWithAll = false;
  export let onSubmit: () => void;
  export let onCancel: () => void;

  $: title = mode === 'create' ? 'Create New View' : 'Edit View';
  $: description = mode === 'create'
    ? 'Save your current filters, sort options, and search settings as a new view.'
    : 'Update your view\'s name and sharing settings.';
  $: submitText = mode === 'create' ? 'Create View' : 'Update View';
</script>

<Dialog.Root bind:open>
  <Dialog.Content class="sm:max-w-[425px]">
    <Dialog.Header>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Description class="text-muted-foreground">
        {description}
      </Dialog.Description>
    </Dialog.Header>
    <div class="space-y-4 py-4">
      <div class="grid gap-2">
        <Label for="name">View Name</Label>
        <Input
          id="name"
          bind:value={viewName}
          placeholder="e.g., My Important Tasks"
        />
      </div>
      <div class="flex items-center space-x-2">
        <Checkbox id="share" bind:checked={shareWithAll} />
        <Label for="share">Share with all users</Label>
      </div>
    </div>
    <Dialog.Footer>
      <Button variant="outline" on:click={onCancel}>Cancel</Button>
      <Button on:click={onSubmit}>{submitText}</Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
