<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs/index.js';

  import {
    DateFormatter,
    type DateValue,
    getLocalTimeZone,
    today,
  } from '@internationalized/date';
  import PageWithTitle from '$lib/ui/page-with-title.svelte';
  import type { DateRange } from '$lib/components/hanta/date/hanta-date-range';
  import HantaDateRange from '$lib/components/hanta/date/hanta-date-range.svelte';

  import { getFinancialReport } from '$lib/api/reports-api';
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { VisXYContainer, VisLine, VisAxis } from '@unovis/svelte';
  import { formatCurrency } from '$lib/utils/hanta-utils';
  import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from '$lib/components/ui/select';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import Icon from '@iconify/svelte';
  import CustomersReport from './customers-report.svelte';
  import type {
    ComparisonMetrics,
    KPIDescription,
    ReportData,
  } from './analytics';
  import {
    BUSINESS_HEALTH_KPI_DESCRIPTIONS,
    CASH_FLOW_KPI_DESCRIPTIONS,
    CUSTOMER_KPI_DESCRIPTIONS,
    MARKET_GROWTH_KPI_DESCRIPTIONS,
    OPERATIONAL_EFFICIENCY_KPI_DESCRIPTIONS,
    PROFITABILITY_KPI_DESCRIPTIONS,
    REVENUE_KPI_DESCRIPTIONS,
    SALES_KPI_DESCRIPTIONS,
    WORKING_CAPITAL_KPI_DESCRIPTIONS,
  } from './analytics-kpis';
  import FinancialTrendsChart from './financial-trends-chart.svelte';

  const DEFAULT_END_DATE = today(getLocalTimeZone());
  const DEFAULT_START_DATE = DEFAULT_END_DATE.set({ month: 1, day: 1 });

  let dateRange: DateRange = {
    start: DEFAULT_START_DATE,
    end: DEFAULT_END_DATE,
  };

  let reportData: ReportData = {
    revenue: { current: [], previous: null },
    revenueBreakdown: {
      productLines: {},
      geographicRegions: {},
      recurringRevenue: { current: [], previous: null },
      oneTimeRevenue: { current: [], previous: null },
    },
    expenses: { current: [], previous: null },
    profit: { current: [], previous: null },
    cashFlow: { current: [], previous: null },
    revenueByChannel: {},
    customerMetrics: {
      satisfaction: { current: [], previous: null },
      churn: { current: [], previous: null },
      acquisition: { current: [], previous: null },
      lifetime: { current: [], previous: null },
    },
    operationalMetrics: {
      productivity: { current: [], previous: null },
      utilization: { current: [], previous: null },
      inventory: { current: [], previous: null },
    },
    kpis: {
      newCustomers: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      totalCustomers: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      grossRevenue: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      netRevenue: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      totalRevenue: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      revenueGrowthMoM: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      revenueGrowthYoY: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      averageRevenuePerCustomer: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      revenueByChannel: {},
      revenueBreakdown: {
        productLines: {},
        geographicRegions: {},
        recurringRevenue: {
          current: 0,
          previous: 0,
          change: 0,
          changePercentage: 0,
        },
        oneTimeRevenue: {
          current: 0,
          previous: 0,
          change: 0,
          changePercentage: 0,
        },
      },
      grossProfit: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      grossMargin: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      netProfit: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      ebitda: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      operatingMargin: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      customerAcquisitionCost: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      customerLifetimeValue: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      churnRate: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      customerSatisfactionScore: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      netPromoterScore: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      operatingCashFlow: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      freeCashFlow: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      cashRunway: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      workingCapital: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      accountsReceivableDays: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      accountsPayableDays: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      marketShare: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      yearOverYearGrowth: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      newMarketPenetration: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      productLineGrowth: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      employeeProductivity: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      resourceUtilization: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      operatingExpenseRatio: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      inventoryTurnover: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      debtToEquityRatio: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      currentRatio: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      quickRatio: { current: 0, previous: 0, change: 0, changePercentage: 0 },
      returnOnInvestment: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      returnOnAssets: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      salesPipeline: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      marketGrowthRate: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      innovationMetrics: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      competitivePosition: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      workingCapitalRatio: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      netWorkingCapital: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      cashConversionCycle: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      receivablesTurnover: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      payablesTurnover: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      interestCoverageRatio: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      returnOnEquity: {
        current: 0,
        previous: 0,
        change: 0,
        changePercentage: 0,
      },
      zScore: { current: 0, previous: 0, change: 0, changePercentage: 0 },
    },
  };

  // Function to display KPI details in a tooltip
  function getKPITooltip(kpiDescription: KPIDescription): string {
    return `
      <div class="space-y-2">
        <h4 class="font-bold">${kpiDescription.name}</h4>
        <p>${kpiDescription.description}</p>
        ${kpiDescription.formula ? `<p><strong>Formula:</strong> ${kpiDescription.formula}</p>` : ''}
        ${kpiDescription.benchmark ? `<p><strong>Benchmark:</strong> ${kpiDescription.benchmark}</p>` : ''}
      </div>
    `;
  }

  type ComparePeriod = 'previous_period' | 'previous_year' | 'custom';
  let compareWithPeriod: ComparePeriod = 'previous_period';

  let comparisonDateRange: DateRange = {
    start: undefined,
    end: undefined,
  };

  const comparisonOptions = [
    { value: 'previous_period', label: 'Previous Period' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Period' },
  ];

  $: if (dateRange.start && dateRange.end && compareWithPeriod) {
    loadReport();
  }

  $: if (compareWithPeriod === 'custom') {
    if (comparisonDateRange.start && comparisonDateRange.end) {
      loadReport();
    }
  }

  async function loadReport() {
    try {
      reportData = await getFinancialReport({
        startDate: dateRange.start.toString(),
        endDate: dateRange.end.toString(),
        compareWithPeriod,
        compareStartDate: comparisonDateRange.start?.toString(),
        compareEndDate: comparisonDateRange.end?.toString(),
      });
      console.log('Report Data:', reportData);
    } catch (error) {
      console.error('Failed to load report:', error);
    }
  }

  $: chartData =
    reportData.revenue.current && reportData.revenue.current.length > 0
      ? (() => {
          // Map of months for consistent data points
          const monthlyData = new Map();

          // Process current revenue data
          reportData.revenue.current.forEach(item => {
            const [year, month] = item.date.split('-');
            const date = new Date(parseInt(year), parseInt(month) - 1);
            monthlyData.set(item.date, {
              date,
              revenue: item.value || 0,
              previousRevenue: 0,
              expenses: 0,
              previousExpenses: 0,
              profit: 0,
              previousProfit: 0,
              cashFlow: 0,
              previousCashFlow: 0,
            });
          });

          // Process previous revenue data
          reportData.revenue.previous?.forEach(item => {
            const entry = monthlyData.get(item.date);
            if (entry) {
              entry.previousRevenue = item.value || 0;
            }
          });

          // Process current expenses data
          reportData.expenses.current?.forEach(item => {
            const entry = monthlyData.get(item.date);
            if (entry) {
              entry.expenses = (entry.expenses || 0) + (item.value || 0);
            }
          });

          // Process previous expenses data
          reportData.expenses.previous?.forEach(item => {
            const entry = monthlyData.get(item.date);
            if (entry) {
              entry.previousExpenses =
                (entry.previousExpenses || 0) + (item.value || 0);
            }
          });

          // Calculate profits and cash flow for each month
          monthlyData.forEach(entry => {
            entry.profit = entry.revenue - entry.expenses;
            entry.previousProfit =
              entry.previousRevenue - entry.previousExpenses;

            // Cash flow calculation (if you have cash balance data)
            entry.cashFlow = reportData.kpis.cashBalance?.current
              ? entry.profit *
                (reportData.kpis.cashBalance.current /
                  reportData.kpis.netProfit.current)
              : entry.profit;

            entry.previousCashFlow = reportData.kpis.cashBalance?.previous
              ? entry.previousProfit *
                (reportData.kpis.cashBalance.previous /
                  reportData.kpis.netProfit.previous)
              : entry.previousProfit;
          });

          // Convert to array and sort by date
          return Array.from(monthlyData.values())
            .sort((a, b) => a.date - b.date)
            .slice(-30); // Keep last 30 data points if needed
        })()
      : [];

  // Modify x axis to use timestamp for proper scaling
  const x = d => d.date.getTime();

  const yRevenue = d => d.revenue;
  const yExpenses = d => d.expenses;
  const yProfit = d => d.profit;
  const yCashFlow = d => d.cashFlow;

  function formatChange(metrics: ComparisonMetrics) {
    const percentageChange =
      metrics.changePercentage ||
      (metrics.previous !== 0
        ? ((metrics.current - metrics.previous) / metrics.previous) * 100
        : 0);
    const arrow = percentageChange >= 0 ? '↑' : '↓';
    const colorClass =
      percentageChange >= 0 ? 'text-green-500' : 'text-red-500';
    return `<span class="${colorClass}">${arrow} ${Math.abs(percentageChange).toFixed(1)}%</span>`;
  }
</script>

<PageWithTitle withSidebar={false}>
  <div slot="card" class="flex items-center justify-between w-full">
    <div class="flex items-center justify-between w-full mb-4">
      <h1 class="text-2xl font-bold">Financial Report</h1>

      <div class="flex items-center gap-4">
        <Select
          options={comparisonOptions}
          bind:value={compareWithPeriod}
          label="Compare with"
        />
        <HantaDateRange bind:value={dateRange} showYearsToolbar={true} />
        {#if compareWithPeriod === 'custom'}
          <HantaDateRange
            bind:value={comparisonDateRange}
            label="Comparison Period"
            showYearsToolbar={true}
          />
        {/if}
      </div>
    </div>
  </div>
  <div slot="content" class="mt-8">
    <Tabs.Root value="kpis" class="w-[1000px]">
      <Tabs.List class="grid w-full grid-cols-2">
        <Tabs.Trigger value="kpis">KPIs</Tabs.Trigger>
        <Tabs.Trigger value="accounts">Accounts</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="accounts">
        {#if dateRange?.start && dateRange?.end}
          <CustomersReport {dateRange} />
        {/if}
      </Tabs.Content>
      <Tabs.Content value="kpis">
        <!-- Main KPI Cards -->
        <div class="grid grid-cols-4 gap-4">
          <!-- Revenue Section -->
          <Card>
            <CardHeader>
              <CardTitle>Revenue</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {formatCurrency(reportData.kpis?.totalRevenue?.current ?? 0)}
              </div>
              <div class="text-sm text-muted-foreground">
                Growth: {formatCurrency(
                  reportData.kpis?.totalRevenue?.change ?? 0,
                )}
                {@html formatChange(
                  reportData.kpis?.totalRevenue ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
            </CardContent>
          </Card>

          <!-- Expenses Section -->
          <Card>
            <CardHeader>
              <CardTitle>Expenses</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {formatCurrency(reportData.kpis?.totalExpenses?.current ?? 0)}
              </div>
              <div class="text-sm text-muted-foreground">
                Growth: {formatCurrency(
                  reportData.kpis?.totalExpenses?.change ?? 0,
                )}
                {@html formatChange(
                  reportData.kpis?.totalExpenses ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
            </CardContent>
          </Card>

          <!-- Profitability Section -->
          <Card>
            <CardHeader>
              <CardTitle>Profitability</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {formatCurrency(reportData.kpis?.netProfit?.current ?? 0)}
              </div>

              <div class="text-sm text-muted-foreground">
                Growth: {formatCurrency(
                  reportData.kpis?.netProfit?.change ?? 0,
                )}
                {@html formatChange(
                  reportData.kpis?.netProfit ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
              <div class="text-sm text-muted-foreground">
                Operating Margin: {(
                  reportData.kpis?.operatingMargin?.current ?? 0
                ).toFixed(1)}%
                {@html formatChange(
                  reportData.kpis?.operatingMargin ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
              <div class="text-sm text-muted-foreground">
                Margin: {(reportData.kpis?.grossMargin?.current ?? 0).toFixed(
                  1,
                )}%
                {@html formatChange(
                  reportData.kpis?.grossMargin ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
            </CardContent>
          </Card>

          <!-- Cash Flow Section -->
          <Card>
            <CardHeader>
              <CardTitle>Cash Flow</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {formatCurrency(
                  reportData.kpis?.operatingCashFlow?.current ?? 0,
                )}
              </div>
              <div class="text-sm text-muted-foreground">
                Free Cash Flow: {formatCurrency(
                  reportData.kpis?.freeCashFlow?.current ?? 0,
                )}
              </div>
              <div class="text-sm text-muted-foreground">
                Runway: {reportData.kpis?.cashRunway?.current?.toFixed(1) ?? 0} months
              </div>
            </CardContent>
          </Card>

          <!-- Customers Section -->
          <Card>
            <CardHeader>
              <CardTitle>Total Customers</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {reportData.kpis?.totalCustomers?.current ?? 0}
              </div>

              <div class="text-sm text-muted-foreground">
                Growth: {reportData.kpis?.totalCustomers?.change ?? 0}
                {@html formatChange(
                  reportData.kpis?.totalCustomers ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
              <div class="text-sm text-muted-foreground">
                ARPC: {formatCurrency(
                  reportData.kpis?.averageRevenuePerCustomer?.current ?? 0,
                )}
                {@html formatChange(
                  reportData.kpis?.averageRevenuePerCustomer ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
            </CardContent>
          </Card>

          <!-- New Customers Section -->
          <Card>
            <CardHeader>
              <CardTitle>New Customers</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {reportData.kpis?.newCustomers?.current ?? 0}
              </div>

              <div class="text-sm text-muted-foreground">
                Growth: {reportData.kpis?.newCustomers?.change ?? 0}
                {@html formatChange(
                  reportData.kpis?.newCustomers ?? {
                    current: 0,
                    previous: 0,
                    change: 0,
                    changePercentage: 0,
                  },
                )}
              </div>
            </CardContent>
          </Card>

          <!-- Customer Metrics -->
          <Card>
            <CardHeader>
              <CardTitle>Customer Health</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="text-2xl font-bold">
                {(
                  reportData.kpis?.customerSatisfactionScore?.current ?? 0
                ).toFixed(1)}
              </div>
              <div class="text-sm text-muted-foreground">
                NPS: {reportData.kpis?.netPromoterScore?.current ?? 0}
              </div>
              <div class="text-sm text-destructive">
                Churn: {(reportData.kpis?.churnRate?.current ?? 0).toFixed(1)}%
              </div>
            </CardContent>
          </Card>
        </div>

        <FinancialTrendsChart
          {chartData}
          {x}
          {yRevenue}
          {yExpenses}
          {yProfit}
          {yCashFlow}
        />

        <!-- Detailed Metrics Grid -->
        <div class="grid grid-cols-3 gap-4 mt-4">
          <!-- Financial Health -->
          <Card>
            <CardHeader>
              <CardTitle>Financial Health</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="space-y-2">
                <div class="flex justify-between">
                  <span>Debt to Equity:</span>
                  <span
                    >{(
                      reportData.kpis?.debtToEquityRatio?.current ?? 0
                    ).toFixed(2)}</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Current Ratio:</span>
                  <span
                    >{(reportData.kpis?.currentRatio?.current ?? 0).toFixed(
                      2,
                    )}</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>ROI:</span>
                  <span
                    >{(
                      reportData.kpis?.returnOnInvestment?.current ?? 0
                    ).toFixed(1)}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>ROA:</span>
                  <span
                    >{(reportData.kpis?.returnOnAssets?.current ?? 0).toFixed(
                      1,
                    )}%</span
                  >
                </div>
              </div>
            </CardContent>
          </Card>

          <!-- Operational Efficiency -->
          <Card>
            <CardHeader>
              <CardTitle>Operational Efficiency</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="space-y-2">
                <div class="flex justify-between">
                  <span>Employee Productivity:</span>
                  <span
                    >{formatCurrency(
                      reportData.kpis?.employeeProductivity?.current ?? 0,
                    )}</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Resource Utilization:</span>
                  <span
                    >{(
                      reportData.kpis?.resourceUtilization?.current ?? 0
                    ).toFixed(1)}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Operating Expense Ratio:</span>
                  <span
                    >{(
                      reportData.kpis?.operatingExpenseRatio?.current ?? 0
                    ).toFixed(1)}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Inventory Turnover:</span>
                  <span
                    >{(
                      reportData.kpis?.inventoryTurnover?.current ?? 0
                    ).toFixed(1)}x</span
                  >
                </div>
              </div>
            </CardContent>
          </Card>

          <!-- Growth Indicators -->
          <Card>
            <CardHeader>
              <CardTitle>Growth Indicators</CardTitle>
            </CardHeader>
            <CardContent>
              <div class="space-y-2">
                <div class="flex justify-between">
                  <span>Market Share:</span>
                  <span
                    >{(reportData.kpis?.marketShare?.current ?? 0).toFixed(
                      1,
                    )}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>YoY Growth:</span>
                  <span
                    >{(
                      reportData.kpis?.yearOverYearGrowth?.current ?? 0
                    ).toFixed(1)}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Market Growth Rate:</span>
                  <span
                    >{(reportData.kpis?.marketGrowthRate?.current ?? 0).toFixed(
                      1,
                    )}%</span
                  >
                </div>
                <div class="flex justify-between">
                  <span>Sales Pipeline:</span>
                  <span
                    >{formatCurrency(
                      reportData.kpis?.salesPipeline?.current ?? 0,
                    )}</span
                  >
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <!-- Detailed Revenue Metrics -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Revenue Breakdown
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon
                    icon="mdi:information-outline"
                    class="ml-2 text-muted-foreground"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Revenue Metrics Overview</h3>
                    <p>
                      These metrics provide a comprehensive view of your revenue
                      performance, breaking down income sources and growth
                      trends.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Product Line Revenue -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Revenue by Product Line
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.totalRevenue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-2">
                  {#each Object.entries(reportData.kpis.revenueBreakdown?.productLines ?? {}) as [productLine, metrics]}
                    <div class="flex justify-between">
                      <span>{productLine}</span>
                      <span>
                        {formatCurrency(metrics?.current ?? 0)}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            metrics ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  {/each}
                </div>
              </div>

              <!-- Geographic Revenue -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Revenue by Region
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.totalRevenue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-2">
                  {#each Object.entries(reportData.kpis.revenueBreakdown?.geographicRegions ?? {}) as [region, metrics]}
                    <div class="flex justify-between">
                      <span>{region}</span>
                      <span>
                        {formatCurrency(metrics?.current ?? 0)}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            metrics ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  {/each}
                </div>
              </div>
            </div>

            <!-- Recurring vs One-Time Revenue -->
            <div class="grid grid-cols-2 gap-4 mt-4">
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Recurring Revenue
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.recurringRevenue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-2xl font-bold">
                  {formatCurrency(
                    reportData.kpis.revenueBreakdown?.recurringRevenue
                      ?.current ?? 0,
                  )}
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis.revenueBreakdown?.recurringRevenue ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  One-Time Revenue
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.oneTimeRevenue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-2xl font-bold">
                  {formatCurrency(
                    reportData.kpis.revenueBreakdown?.oneTimeRevenue?.current ??
                      0,
                  )}
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis.revenueBreakdown?.oneTimeRevenue ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
            </div>

            <!-- Additional Revenue Metrics -->
            <div class="grid grid-cols-3 gap-4 mt-4">
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Gross Revenue
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.grossRevenue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {formatCurrency(reportData.kpis?.grossRevenue?.current ?? 0)}
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis?.grossRevenue ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Net Revenue
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(REVENUE_KPI_DESCRIPTIONS.netRevenue)}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {formatCurrency(reportData.kpis?.netRevenue?.current ?? 0)}
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis?.netRevenue ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  ARPC
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.averageRevenuePerCustomer,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {formatCurrency(
                    reportData.kpis?.averageRevenuePerCustomer?.current ?? 0,
                  )}
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis?.averageRevenuePerCustomer ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
            </div>

            <!-- Growth Rates -->
            <div class="grid grid-cols-2 gap-4 mt-4">
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  MoM Revenue Growth
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.revenueGrowthMoM,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {(
                    reportData.kpis?.revenueGrowthMoM?.changePercentage ?? 0
                  ).toFixed(1)}%
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis?.revenueGrowthMoM ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  YoY Revenue Growth
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        REVENUE_KPI_DESCRIPTIONS.revenueGrowthYoY,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {(
                    reportData.kpis?.revenueGrowthYoY?.changePercentage ?? 0
                  ).toFixed(1)}%
                </div>
                <div class="text-sm text-muted-foreground">
                  {@html formatChange(
                    reportData.kpis?.revenueGrowthYoY ?? {
                      current: 0,
                      previous: 0,
                      change: 0,
                      changePercentage: 0,
                    },
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Customer Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Customer Metrics
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon
                    icon="mdi:information-outline"
                    class="ml-2 text-muted-foreground"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Customer Overview</h3>
                    <p>
                      Key metrics tracking customer acquisition, retention, and
                      lifetime value.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Customer Value Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Customer Value
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CUSTOMER_KPI_DESCRIPTIONS.customerLifetimeValue,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- CAC -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Acquisition Cost (CAC)</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.customerAcquisitionCost?.current ??
                            0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.customerAcquisitionCost ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- CLV -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Lifetime Value (CLV)</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.customerLifetimeValue?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.customerLifetimeValue ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- CLV/CAC Ratio -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>CLV/CAC Ratio</span>
                      <span class="text-xl font-bold">
                        {(reportData.kpis?.clvCacRatio?.current ?? 0).toFixed(
                          1,
                        )}x
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.clvCacRatio ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Customer Health Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Customer Health
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CUSTOMER_KPI_DESCRIPTIONS.customerSatisfactionScore,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Churn Rate -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Churn Rate</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.customerChurnRate?.current ?? 0
                        ).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.customerChurnRate ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Growth Rate -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Growth Rate</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.customerGrowthRate?.current ?? 0
                        ).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.customerGrowthRate ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- CSAT Score -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>CSAT Score</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.customerSatisfactionScore?.current ??
                          0
                        ).toFixed(1)}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.customerSatisfactionScore ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Sales & Pipeline Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Sales & Pipeline
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon
                    icon="mdi:information-outline"
                    class="ml-2 text-muted-foreground"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Sales Overview</h3>
                    <p>
                      Comprehensive view of sales performance, pipeline health,
                      and conversion metrics.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Sales Performance -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Sales Performance
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(SALES_KPI_DESCRIPTIONS.salesGrowth)}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Sales Growth -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Sales Growth</span>
                      <span class="text-xl font-bold">
                        {(reportData.kpis?.salesGrowth?.current ?? 0).toFixed(
                          1,
                        )}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.salesGrowth ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Pipeline Value -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Pipeline Value</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.salesPipelineValue?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.salesPipelineValue ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Average Deal Size -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Avg Deal Size</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.averageDealSize?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.averageDealSize ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Pipeline Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Pipeline Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        SALES_KPI_DESCRIPTIONS.conversionRate,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Conversion Rate -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Conversion Rate</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.conversionRate?.current ?? 0
                        ).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.conversionRate ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Sales Cycle Length -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Sales Cycle</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.salesCycleLength?.current ?? 0
                        ).toFixed(1)} days
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.salesCycleLength ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Win Rate -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Win Rate</span>
                      <span class="text-xl font-bold">
                        {(reportData.kpis?.winRate?.current ?? 0).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.winRate ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Profitability Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Profitability Metrics
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon
                    icon="mdi:information-outline"
                    class="ml-2 text-muted-foreground"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Profitability Overview</h3>
                    <p>
                      Comprehensive analysis of profit margins, operational
                      costs, and overall financial efficiency.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Margins Section -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Profit Margins
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        PROFITABILITY_KPI_DESCRIPTIONS.grossProfitMargin,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Gross Profit Margin -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Gross Margin</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.grossProfitMargin?.current ?? 0
                        ).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.grossProfitMargin ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Net Profit Margin -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Net Margin</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.netProfitMargin?.current ?? 0
                        ).toFixed(1)}%
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.netProfitMargin ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operational Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Operational Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        PROFITABILITY_KPI_DESCRIPTIONS.operatingIncome,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- EBITDA -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>EBITDA</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(reportData.kpis?.ebitda?.current ?? 0)}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.ebitda ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Operating Income -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Operating Income</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.operatingIncome?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.operatingIncome ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cost Metrics -->
            <div class="grid grid-cols-2 gap-4 mt-4">
              <!-- COGS -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Cost of Goods Sold
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(PROFITABILITY_KPI_DESCRIPTIONS.cogs)}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {formatCurrency(reportData.kpis?.cogs?.current ?? 0)}
                  <span class="ml-2 text-xs text-muted-foreground">
                    {@html formatChange(
                      reportData.kpis?.cogs ?? {
                        current: 0,
                        previous: 0,
                        change: 0,
                        changePercentage: 0,
                      },
                    )}
                  </span>
                </div>
              </div>

              <!-- Operating Expenses Ratio -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Operating Expenses Ratio
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        PROFITABILITY_KPI_DESCRIPTIONS.operatingExpensesRatio,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {(
                    reportData.kpis?.operatingExpensesRatio?.current ?? 0
                  ).toFixed(1)}%
                  <span class="ml-2 text-xs text-muted-foreground">
                    {@html formatChange(
                      reportData.kpis?.operatingExpensesRatio ?? {
                        current: 0,
                        previous: 0,
                        change: 0,
                        changePercentage: 0,
                      },
                    )}
                  </span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Cash Flow Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Cash Flow Metrics
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon
                    icon="mdi:information-outline"
                    class="ml-2 text-muted-foreground"
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Cash Flow Overview</h3>
                    <p>
                      Analysis of cash generation, conversion efficiency, and
                      liquidity management metrics.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Core Cash Flow Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Core Cash Flows
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CASH_FLOW_KPI_DESCRIPTIONS.operatingCashFlow,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Operating Cash Flow -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Operating Cash Flow</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.operatingCashFlow?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.operatingCashFlow ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Free Cash Flow -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Free Cash Flow</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.freeCashFlow?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.freeCashFlow ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Cash Efficiency Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Cash Efficiency
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CASH_FLOW_KPI_DESCRIPTIONS.cashConversionCycle,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Cash Conversion Cycle -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Cash Conversion Cycle</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.cashConversionCycle?.current ?? 0
                        ).toFixed(1)} days
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.cashConversionCycle ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Days Sales Outstanding -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Days Sales Outstanding</span>
                      <span class="text-xl font-bold">
                        {(
                          reportData.kpis?.daysSalesOutstanding?.current ?? 0
                        ).toFixed(1)} days
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.daysSalesOutstanding ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cash Management Metrics -->
            <div class="grid grid-cols-2 gap-4 mt-4">
              <!-- Cash Runway & Burn Rate -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Cash Management
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CASH_FLOW_KPI_DESCRIPTIONS.cashRunway,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <!-- Cash Runway -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Cash Runway</span>
                      <span class="text-xl font-bold">
                        {(reportData.kpis?.cashRunway?.current ?? 0).toFixed(1)}
                        months
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.cashRunway ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <!-- Burn Rate -->
                  <div>
                    <div class="flex items-center justify-between">
                      <span>Monthly Burn Rate</span>
                      <span class="text-xl font-bold">
                        {formatCurrency(
                          reportData.kpis?.burnRate?.current ?? 0,
                        )}
                        <span class="ml-2 text-xs text-muted-foreground">
                          {@html formatChange(
                            reportData.kpis?.burnRate ?? {
                              current: 0,
                              previous: 0,
                              change: 0,
                              changePercentage: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accounts Receivable Aging -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  AR Aging
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="mdi:information-outline"
                        class="ml-2 text-muted-foreground"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        CASH_FLOW_KPI_DESCRIPTIONS.accountsReceivableAging,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="text-xl font-bold">
                  {formatCurrency(
                    reportData.kpis?.accountsReceivableAging?.current ?? 0,
                  )}
                  <span class="ml-2 text-xs text-muted-foreground">
                    {@html formatChange(
                      reportData.kpis?.accountsReceivableAging ?? {
                        current: 0,
                        previous: 0,
                        change: 0,
                        changePercentage: 0,
                      },
                    )}
                  </span>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Market & Growth Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Market & Growth
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon icon="heroicons:information-circle" class="w-5 h-5" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Market & Growth Overview</h3>
                    <p>
                      Key metrics tracking market position, growth trajectory,
                      and competitive standing.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Market Position -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Market Position
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        MARKET_GROWTH_KPI_DESCRIPTIONS.marketShare,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Market Share</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.marketShare?.current ?? 0).toFixed(1)}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.marketShare ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Competitive Index</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.competitiveIndex?.current ?? 0
                      ).toFixed(1)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.competitiveIndex ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Brand Strength</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.brandStrength?.current ?? 0).toFixed(
                        1,
                      )}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.brandStrength ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Growth Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Growth Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        MARKET_GROWTH_KPI_DESCRIPTIONS.marketGrowthRate,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Market Growth Rate</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.marketGrowthRate?.current ?? 0
                      ).toFixed(1)}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.marketGrowthRate ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>New Market Penetration</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.newMarketPenetration?.current ?? 0
                      ).toFixed(1)}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.newMarketPenetration ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Market Expansion Rate</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.marketExpansionRate?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.marketExpansionRate ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Operational Efficiency Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Operational Efficiency
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon icon="heroicons:information-circle" class="w-5 h-5" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">
                      Operational Efficiency Overview
                    </h3>
                    <p>
                      Key metrics tracking operational performance, resource
                      utilization, and process efficiency.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Resource Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Resource Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        OPERATIONAL_EFFICIENCY_KPI_DESCRIPTIONS.employeeProductivity,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Employee Productivity</span>
                    <span class="text-xl font-bold">
                      {formatCurrency(
                        reportData.kpis?.employeeProductivity?.current ?? 0,
                      )}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.employeeProductivity ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Resource Utilization</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.resourceUtilization?.current ?? 0
                      ).toFixed(1)}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.resourceUtilization ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Asset Utilization</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.assetUtilization?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.assetUtilization ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Process Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Process Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        OPERATIONAL_EFFICIENCY_KPI_DESCRIPTIONS.processEfficiency,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Process Efficiency</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.processEfficiency?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.processEfficiency ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Automation Rate</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.automationRate?.current ?? 0).toFixed(
                        1,
                      )}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.automationRate ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Operational Efficiency</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.operationalEfficiency?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.operationalEfficiency ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Working Capital Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Working Capital
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon icon="heroicons:information-circle" class="w-5 h-5" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Working Capital Overview</h3>
                    <p>
                      Key metrics tracking efficiency of working capital
                      management and cash conversion cycle.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Capital Efficiency -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Capital Efficiency
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        WORKING_CAPITAL_KPI_DESCRIPTIONS.workingCapitalRatio,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Working Capital Ratio</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.workingCapitalRatio?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.workingCapitalRatio ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Net Working Capital</span>
                    <span class="text-xl font-bold">
                      {formatCurrency(
                        reportData.kpis?.netWorkingCapital?.current ?? 0,
                      )}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.netWorkingCapital ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Cash Conversion Cycle</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.cashConversionCycle?.current ?? 0
                      ).toFixed(1)} days
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.cashConversionCycle ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Turnover Metrics -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Turnover Metrics
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        WORKING_CAPITAL_KPI_DESCRIPTIONS.inventoryTurnover,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Inventory Turnover</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.inventoryTurnover?.current ?? 0
                      ).toFixed(2)}x
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.inventoryTurnover ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Receivables Turnover</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.receivablesTurnover?.current ?? 0
                      ).toFixed(2)}x
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.receivablesTurnover ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Payables Turnover</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.payablesTurnover?.current ?? 0
                      ).toFixed(2)}x
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.payablesTurnover ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <!-- Business Health Metrics Card -->
        <Card class="mt-4">
          <CardHeader>
            <CardTitle>
              Business Health
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Icon icon="heroicons:information-circle" class="w-5 h-5" />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <div class="max-w-[300px] space-y-4">
                    <h3 class="text-lg font-bold">Business Health Overview</h3>
                    <p>
                      Key indicators of business sustainability, financial
                      stability, and long-term viability.
                    </p>
                  </div>
                </Tooltip.Content>
              </Tooltip.Root>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div class="grid grid-cols-2 gap-4">
              <!-- Financial Stability -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Financial Stability
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        BUSINESS_HEALTH_KPI_DESCRIPTIONS.debtToEquityRatio,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Debt to Equity</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.debtToEquityRatio?.current ?? 0
                      ).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.debtToEquityRatio ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Interest Coverage</span>
                    <span class="text-xl font-bold">
                      {(
                        reportData.kpis?.interestCoverageRatio?.current ?? 0
                      ).toFixed(2)}x
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.interestCoverageRatio ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Quick Ratio</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.quickRatio?.current ?? 0).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.quickRatio ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Performance Indicators -->
              <div>
                <h3 class="flex items-center mb-2 text-lg font-semibold">
                  Performance Indicators
                  <Tooltip.Root>
                    <Tooltip.Trigger>
                      <Icon
                        icon="heroicons:information-circle"
                        class="w-5 h-5"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                      {@html getKPITooltip(
                        BUSINESS_HEALTH_KPI_DESCRIPTIONS.returnOnEquity,
                      )}
                    </Tooltip.Content>
                  </Tooltip.Root>
                </h3>
                <div class="space-y-4">
                  <div class="flex items-center justify-between">
                    <span>Return on Equity</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.returnOnEquity?.current ?? 0).toFixed(
                        1,
                      )}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.returnOnEquity ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Return on Assets</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.returnOnAssets?.current ?? 0).toFixed(
                        1,
                      )}%
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.returnOnAssets ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between">
                    <span>Z-Score</span>
                    <span class="text-xl font-bold">
                      {(reportData.kpis?.zScore?.current ?? 0).toFixed(2)}
                      <span class="ml-2 text-xs text-muted-foreground">
                        {@html formatChange(
                          reportData.kpis?.zScore ?? {
                            current: 0,
                            previous: 0,
                            change: 0,
                            changePercentage: 0,
                          },
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Tabs.Content>
    </Tabs.Root>
  </div>
</PageWithTitle>
