<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import * as Tabs from '$lib/components/ui/tabs/index.js';
  import * as Card from '$lib/components/ui/card/index.js';
  import { Badge } from '$lib/components/ui/badge';
  import Icon from '@iconify/svelte';
  import { format } from 'date-fns';
  import { getCustomerActivityReport } from '$lib/api/reports-api';
  import { type DateRange } from '$lib/components/hanta/date/hanta-date-range';
  import { today, getLocalTimeZone } from '@internationalized/date';

  const DEFAULT_END_DATE = today(getLocalTimeZone());
  const DEFAULT_START_DATE = DEFAULT_END_DATE.subtract({ years: 1 });

  export let dateRange: DateRange;

  let activeTab = 'all'; // State to track the active tab

  $: customerReportQuery = createQuery({
    queryKey: ['customer_activity_report', dateRange?.start, dateRange?.end],
    queryFn: () =>
      getCustomerActivityReport({
        startDate: dateRange?.start.toString(),
        endDate: dateRange?.end.toString(),
      }),
  });

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  };
</script>

<div class="space-y-4 p-4">
  <Tabs.Root bind:value={activeTab}>
    <Tabs.List class="grid grid-cols-6 mb-4">
      <Tabs.Trigger value="all">All</Tabs.Trigger>
      <Tabs.Trigger value="lost">Lost Customers</Tabs.Trigger>
      <Tabs.Trigger value="new">New Customers</Tabs.Trigger>
    </Tabs.List>
  </Tabs.Root>
  {#if $customerReportQuery.isLoading}
    <div class="text-center">Loading report...</div>
  {:else if $customerReportQuery.isError}
    <div class="text-red-500">Error loading report</div>
  {:else if $customerReportQuery.data}
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {#each ($customerReportQuery.data?.topCustomers || [])
        .map(el => el.jsonb_build_object)
        .filter(el => activeTab === 'all' || (activeTab === 'lost' && el.lost_customer) || (activeTab === 'new' && el.new_customer)) as report}
        <Card.Root>
          <Card.Header>
            <Card.Title class="flex items-center">
              {report.customerName || 'Unknown Customer'}
              {#if report.new_customer}
                <Badge class="ml-2">New</Badge>
              {/if}
              {#if report.lost_customer}
                <Badge class="ml-2" variant="outline">Lost</Badge>
              {/if}
            </Card.Title>
            <div class="text-xs text-muted-foreground">
              {report.customer}
            </div>
          </Card.Header>
          <Card.Content>
            <div class="space-y-2">
              <div class="flex justify-between">
                <span>Total Spent:</span>
                <Badge variant="secondary">
                  {report.current?.totalSpent
                    ? formatCurrency(report.current.totalSpent)
                    : 'N/A'}
                </Badge>
              </div>
              <div class="flex justify-between">
                <span>Avg Purchase:</span>
                <Badge variant="outline">
                  {report.current?.avgPurchase
                    ? formatCurrency(report.current.avgPurchase)
                    : 'N/A'}
                </Badge>
              </div>
              <div class="flex justify-between">
                <span>First Purchase:</span>
                <span
                  >{report.current?.firstPurchase
                    ? new Date(
                        report.current.firstPurchase,
                      ).toLocaleDateString()
                    : 'N/A'}</span
                >
              </div>
              <div class="flex justify-between">
                <span>Last Purchase:</span>
                <span
                  >{report.current?.lastPurchase
                    ? new Date(report.current.lastPurchase).toLocaleDateString()
                    : 'N/A'}</span
                >
              </div>
            </div>
          </Card.Content>
        </Card.Root>
      {/each}
    </div>
  {/if}
</div>
