import { DimensionService } from './DimensionService';
import { PriceEvaluator } from './PriceEvaluator';
import { PriceRequest } from './prices';

export class PriceFinder {
  constructor(
    private readonly dimensionService: DimensionService,
    private readonly priceEvaluator: PriceEvaluator,
  ) {}

  public async findPrice(
    tarif: any,
    item: PriceRequest,
    isSupplier?: boolean,
    isCustomerTarif?: boolean,
  ): Promise<number> {
    if (!tarif) {
      console.debug('tarif not found');
      return 0;
    }

    const quotes = await this.findQuoteInPricelists(tarif, item);
    if (!quotes || quotes.length === 0) return 0;

    const quotesWithPrices = await Promise.all(
      quotes.map(async quote => ({
        ...quote,
        pricelistItem: Object.fromEntries(
          quote.pricelistDimensions.map((el: any[]) => [el[0], el[1]]),
        ),
        price: await this.priceEvaluator.getPrice(
          quote,
          item,
          isSupplier,
          isCustomerTarif,
        ),
      })),
    );

    const quote = quotesWithPrices.reduce((longest, current) =>
      current.prices && current.prices.length > (longest.prices?.length || 0)
        ? current
        : longest,
    );

    this.logPriceCalculation(
      tarif,
      isSupplier,
      isCustomerTarif,
      quote,
      quotesWithPrices,
    );
    return quote.price || 0;
  }

  private async findQuoteInPricelists(
    tarif: any,
    item: PriceRequest,
  ): Promise<any[]> {
    if (!tarif?.pricelists) return [];

    const activePricelists = tarif.pricelists.filter(
      (pricelist: any) => !pricelist.deactivated && pricelist.dimensions,
    );

    const quotes = await Promise.all(
      activePricelists.map(async pricelist => {        
        const tarifDimensions = this.dimensionService.createTarifDimensions(
          pricelist.dimensions,
        );
        const pricelistDimensions =
          await this.dimensionService.getDimensionValues(
            item,
            pricelist.dimensions,
          );

        if (
          !pricelistDimensions.length ||
          !pricelist.prices ||
          Object.keys(tarifDimensions).length !== pricelistDimensions.length
        ) {
          return null;
        }

        const foundPrices = pricelist.prices.filter(price =>
          this.dimensionService.isPriceMatchingDimensions(
            price,
            pricelistDimensions,
            tarifDimensions,
          ),
        );

        if (foundPrices.length === 0) return null;

        return {
          pricelist: pricelist.name,
          prices: foundPrices[0],
          pricelistDimensions,
        };
      }),
    );

    return quotes.filter(Boolean);
  }

  private logPriceCalculation(
    tarif: any,
    isSupplier: boolean | undefined,
    isCustomerTarif: boolean | undefined,
    quote: any,
    quotesWithPrices: any[],
  ): void {
    const priceLog = {
      tarif: `${tarif.name} ${tarif.refId} (${
        isSupplier ? 'supplier' : isCustomerTarif ? 'customer' : 'standard'
      })`,
      price: quote.price,
      quote,
      quotes: quotesWithPrices,
    };
    console.table(priceLog);
  }
}
