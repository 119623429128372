import { HTMLInputAttributes } from 'svelte/elements';
import Root from './input.svelte';
import { tv, type VariantProps } from 'tailwind-variants';

const inputVariants = tv({
  base:
    'inline-flex items-center justify-center rounded-md text-sm font-medium whitespace-nowrap' +
    ' ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2' +
    ' focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  variants: {
    variant: {
      default:
        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm' +
        ' ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium ' +
        'placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 ' +
        'focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      hanta:
        'border-none h-7 p-0 rounded-md outline-none ring-0 hover:bg-muted hover:outline-none' +
        ' hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-b bg-transparent focus:bg-secondary',
      ghost:
        'focus-visible:ring-0 focus-visible:ring-offset-0 rounded-none border-none' +
        ' px-6 py-2 text-lg font-light outline-none ring-0 hover:none hover:outline-none active:ring-0' +
        ' hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-none bg-transparent' +
        ' focus:bg-secondary focus:ring-0 !px-6',
    },
  },
  defaultVariants: {
    variant: 'hanta',
  },
});

type FormInputEvent<T extends Event = Event> = T & {
  currentTarget: EventTarget & HTMLInputElement;
};

export type Variant = VariantProps<typeof inputVariants>['variant'];

type Props = HTMLInputAttributes & {
  variant?: Variant;
};

export type InputEvents = {
  blur: FormInputEvent<FocusEvent>;
  change: FormInputEvent<Event>;
  click: FormInputEvent<MouseEvent>;
  focus: FormInputEvent<FocusEvent>;
  focusin: FormInputEvent<FocusEvent>;
  focusout: FormInputEvent<FocusEvent>;
  keydown: FormInputEvent<KeyboardEvent>;
  keypress: FormInputEvent<KeyboardEvent>;
  keyup: FormInputEvent<KeyboardEvent>;
  mouseover: FormInputEvent<MouseEvent>;
  mouseenter: FormInputEvent<MouseEvent>;
  mouseleave: FormInputEvent<MouseEvent>;
  paste: FormInputEvent<ClipboardEvent>;
  input: FormInputEvent<InputEvent>;
};

export {
  Root,
  type Props,
  //
  Root as Input,
  inputVariants,
};
