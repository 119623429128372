import { writable } from 'svelte/store';
import type { Pipeline } from '../types/pipeline';
import { getPipelines, savePipeline } from '../api/pipeline-api';

function createPipelineStore() {
  const { subscribe, set, update } = writable<Pipeline[]>([]);

  async function initialize() {
    const pipelines = await getPipelines();
    if (pipelines) {
      set(pipelines);
    }
  }

  initialize();

  return {
    subscribe,
    
    // Create new pipeline
    addPipeline: async (pipeline: Pipeline) => {      
      const newPipeline = await savePipeline(pipeline);
      update(pipelines => [...pipelines, newPipeline]);
      return newPipeline;
    },

    // Read all pipelines
    loadPipelines: async () => {
      const pipelines = await getPipelines();
      if (pipelines) {
        set(pipelines);
      }
      return pipelines;
    },

    // Update existing pipeline
    updatePipeline: async (pipeline: Pipeline) => {
      const updatedPipeline = await savePipeline(pipeline);      
      update(pipelines =>
        pipelines.map(p => (p.id === updatedPipeline.id ? updatedPipeline : p)),
      );
      return updatedPipeline;
    },

    // Delete pipeline
    deletePipeline: (id: string) =>
      update(pipelines => pipelines.filter(p => p.id !== id)),

    // Load template pipelines
    loadTemplates: (templates: Pipeline[]) => set(templates),

    // Get pipeline by ID
    getPipelineById: (id: string) => get({ subscribe }).find(p => p.id === id),
  };
}

export const pipelineStore = createPipelineStore();
