<script lang="ts">
  import * as Dialog from '$lib/components/ui/dialog';
  import * as Select from '$lib/components/ui/select';
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import { Switch } from '$lib/components/ui/switch';
  import { Label } from '$lib/components/ui/label';
  import HantaSelect from '$lib/components/hanta/select.svelte';
  import type { Pipeline } from '$lib/types/pipeline';

  export let showDialog = false;
  export let moduleTypes: Array<{ value: string; label: string }>;
  export let pipeline: Pipeline;
  export let onClose: () => void;
  export let onCreate: () => void;

  $: isValid =
    pipeline.name &&
    pipeline.module &&
    (!pipeline.modifySource ||
      (pipeline.modifySource && pipeline.sourceColumn));
</script>

<Dialog.Root bind:open={showDialog}>
  <Dialog.Portal>
    <Dialog.Overlay class="fixed inset-0 bg-black/50" />
    <Dialog.Content
      class="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-[425px] bg-card rounded-lg p-6 shadow-lg"
    >
      <Dialog.Header>
        <Dialog.Title>Create New Pipeline</Dialog.Title>
        <Dialog.Description>
          Configure your new pipeline settings
        </Dialog.Description>
      </Dialog.Header>

      <div class="grid gap-4 py-4">
        <div class="grid gap-2">
          <Label for="name">Name</Label>
          <Input
            id="name"
            bind:value={pipeline.name}
            placeholder="Pipeline name"
          />
        </div>

        <div class="grid gap-2">
          <Label for="module-type">Module Type</Label>
          <HantaSelect
            bind:value={pipeline.module}
            placeholder="Select module type"
            options={moduleTypes}
          />
        </div>

        <div class="flex flex-col gap-2">
          <div class="flex justify-between items-center">
            <Label for="modify-source">Modify Source</Label>
            <Switch id="modify-source" bind:checked={pipeline.modifySource} />
          </div>

          {#if pipeline.modifySource}
            <div class="grid gap-2">
              <Label for="source-column">Source Column</Label>
              <Input
                id="source-column"
                bind:value={pipeline.sourceColumn}
                placeholder="Source column name"
              />
            </div>
          {/if}
        </div>
      </div>

      <Dialog.Footer>
        <Button variant="outline" on:click={onClose}>Cancel</Button>
        <Button variant="default" on:click={onCreate} disabled={!isValid}>
          Create Pipeline
        </Button>
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Portal>
</Dialog.Root>
