<script lang="ts">
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import { Button } from '$lib/components/ui/button';

  import {
    Avatar,
    AvatarImage,
    AvatarFallback,
  } from '$lib/components/ui/avatar';

  import { signOut } from '$lib/api/supabase-auth-api';
  import { authStore } from '$lib/stores/auth-store';
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import AuthUserProfile from './auth-user-profile.svelte';

  let showProfileDialog = false;

  const handleSignOut = async () => {
    await signOut();
  };
</script>

<DropdownMenu.Root>
  <DropdownMenu.Trigger asChild let:builder>
    <Button
      builders={[builder]}
      variant="ghost"
      class="relative w-8 h-8 rounded-full"
    >
      <Avatar class="w-8 h-8">
        {#if $authStore.user?.user_metadata?.avatar_url}
          <AvatarImage
            src={$authStore.user.user_metadata.avatar_url}
            alt={$authStore.user.user_metadata?.full_name || 'User'}
          />
        {/if}
        <AvatarFallback>
          {($authStore.user?.email?.[0] || 'U').toUpperCase()}
        </AvatarFallback>
      </Avatar>
      {#if !$appStore.isCollapsed}
        {$authStore.user?.user_metadata?.full_name}
      {/if}
    </Button>
  </DropdownMenu.Trigger>

  <DropdownMenu.Content class="w-56 z-[99999]" align="end">
    <div class="flex gap-2 justify-start items-center p-2">
      <div class="flex flex-col space-y-1 leading-none">
        {#if $authStore.user?.user_metadata?.full_name}
          <p class="font-medium">
            {$authStore.user.user_metadata.full_name}
          </p>
        {/if}
        {#if $authStore.user?.email}
          <p class="w-[200px] truncate text-sm text-muted-foreground">
            {$authStore.user.email}
          </p>
        {/if}
      </div>
    </div>

    <DropdownMenu.Separator />

    <DropdownMenu.Item on:click={() => (showProfileDialog = true)}>
      <Icon icon="lucide:user" class="mr-2 w-4 h-4" />
      Account settings
    </DropdownMenu.Item>

    <DropdownMenu.Separator />

    <DropdownMenu.Item
      class="text-destructive focus:text-destructive"
      on:click={handleSignOut}
    >
      <Icon icon="lucide:log-out" class="mr-2 w-4 h-4" />
      Log out
    </DropdownMenu.Item>
  </DropdownMenu.Content>
</DropdownMenu.Root>

{#if showProfileDialog}
  <AuthUserProfile bind:showProfileDialog />
{/if}
