<script lang="ts">
  import Popup from '$lib/ui/popup.svelte';
  import {
    type ActivityWithRefs,
    activityWithRefsInsertSchema,
    activityWithRefsSchema,
  } from '$db/schema';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { saveActivity } from '$lib/api/mutations';
  import * as Form from '$lib/components/ui/form';
  import * as Avatar from '$lib/components/ui/avatar';

  import Icon from '@iconify/svelte';
  import { Button } from '$lib/components/ui/button/index';
  import { appStore } from '$lib/stores/app-store';
  import HantaInputTextarea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import { getItems, getLov } from '$lib/api/queries';
  import HantaInputMultiSelect from '$lib/widgets/input/hanta-input-multi-select.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import DealPhaseLarge from '$lib/components/hanta/deals/deal-phase-large.svelte';
  import { onMount } from 'svelte';
  import { fly, fade } from 'svelte/transition';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import DealCandidatesPopover from './deal-candidates-popover.svelte';
  import CrmDeleteObject from '$lib/ui/crm-delete-object.svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';

  let phases = [];

  onMount(async () => {
    const phasesResult = await getLov('Phases');
    phases = phasesResult?.values?.map(el => el.name);
  });

  export let activity: ActivityWithRefs;

  const formSchema = activityWithRefsSchema.partial();

  $: selectedAccount = $formData?.accountObj;
  $: selectedDeal = $formData?.dealObj;

  const loadOptionsCandidates = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'contacts',
      from: 0,
      to: 10,
      search: keyword,
      select: 'id,name,photo',
    });

    return result.data;
  };

  const loadOptionsDeals = async (keyword: string): Promise<any> => {
    const result = await getItems({
      collection: 'deals',
      from: 0,
      to: 10,
      search: keyword,
      filters: [
        {
          field: 'customer',
          operator: 'eq',
          value: selectedAccount.id,
          active: true,
        },
      ],
      select: '*',
    });

    return result.data;
  };

  const form = superForm<ActivityWithRefs>(activity, {
    resetForm: false,
    SPA: true,
    validators: zodClient(formSchema),
    validationMethod: 'oninput',
    dataType: 'json',
    onSubmit: async () => {
      console.debug('Form data', $formData);
      if (Object.keys($errors).length > 0) {
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      if (!$formData.dealObj) {
        console.error('No deal selected!');
        return;
      }

      $formData.name = $formData.dealObj?.name ?? '';
      let parse;
      try {
        parse = activityWithRefsInsertSchema.parse($formData);
      } catch (e) {
        console.error('Error parsing form data', e);
        parse = $formData;
      }

      await saveActivity(parse);
      appStore.closeActivity();

      //TODO: update deal
      $appStore.queryClient.invalidateQueries(['deals', $formData.dealObj.id], {
        exact: true,
        refetchActive: true,
      });
    },
  });

  let showDeleteDialog = false;
  const { form: formData, errors } = form;

  let isUnique = !!activity?.id;
  let isUniqueError = '';

  $: {
    if (
      !$formData.id &&
      $formData.accountObj?.id &&
      $formData.dealObj?.id &&
      $formData.candidateObj?.id
    ) {
      getItems({
        collection: 'activities',
        from: 0,
        to: 10,
        select: 'id',
        filters: [
          {
            field: 'account',
            operator: 'eq',
            value: $formData.accountObj.id,
            active: true,
          },
          {
            field: 'deal',
            operator: 'eq',
            value: $formData.dealObj.id,
            active: true,
          },
          {
            field: 'candidate',
            operator: 'eq',
            value: $formData.candidateObj.id,
            active: true,
          },
        ],
      }).then(result => {
        console.debug('Result', result);
        isUnique = result.data.length === 0;
        if (!isUnique) {
          isUniqueError = 'This candidate was already added to this deal.';
        }
      });
    }
  }
</script>

<Popup {form} minHeight={700} width={1000}>
  <svelte:fragment slot="header">
    <Icon icon="mdi:link" class="mr-4 w-5 h-5" />
    Assign
  </svelte:fragment>
  <div class="flex flex-col py-0 h-full" slot="content">
    <div
      class="flex min-w-0 max-w-full justify-center gap-4 mt-4 m-6 h-[250px] shrink-0"
    >
      <div class="flex flex-col gap-4 w-1/2 h-full">
        <span class="text-xl font-thin">Candidate</span>

        <div
          class="flex flex-row gap-4 p-6 h-full rounded border shadow hover:border-primary-400"
        >
          <Avatar.Root class="rounded-md size-24">
            <Avatar.Image
              class="flex overflow-hidden relative shrink-0"
              src={$formData?.candidateObj?.photo}
            />
            <Avatar.Fallback>-</Avatar.Fallback>
          </Avatar.Root>

          <div class="flex flex-col gap-4 w-full">
            <div class="text-sm">
              {#if $formData?.candidateObj?.name}
                <div class="font-bold">{$formData?.candidateObj?.name}</div>
              {/if}

              <HantaInputMultiSelect
                asArray={false}
                class="w-full"
                {form}
                label="Candidate"
                loadOptions={loadOptionsCandidates}
                multiple={false}
                name="candidateObj"
                placeholder="Select..."
              >
                <svelte:fragment slot="icon">
                  <Icon icon="mdi:person" class="w-3 h-3" />
                </svelte:fragment>

                <div
                  class="flex items-center w-full h-full"
                  let:index
                  let:item
                  slot="item"
                >
                  <Avatar.Root class="size-8">
                    <Avatar.Image
                      class="object-scale-down rounded-none"
                      src={item?.photo}
                    />
                    <Avatar.Fallback>-</Avatar.Fallback>
                  </Avatar.Root>
                  <div class="ml-2 text-sm truncate">{item?.name}</div>
                </div>
                <div
                  class="flex overflow-hidden items-center w-full h-full"
                  let:selection
                  slot="selection"
                >
                  <Avatar.Root class="size-8">
                    <Avatar.Image
                      class="object-scale-down rounded-none"
                      src={selection?.photo}
                    />
                    <Avatar.Fallback>-</Avatar.Fallback>
                  </Avatar.Root>
                  <div class="ml-2 w-full text-sm truncate">
                    {selection?.name}
                  </div>
                </div>
              </HantaInputMultiSelect>
            </div>

            <HantaInputLovSelect
              {form}
              bind:value={$formData.contactedVia}
              type="KontaktiertLOV"
              label="Contacted Via"
              multiple={false}
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 w-1/2 h-full">
        <span class="text-xl font-thin">To Deal</span>
        <div
          class="relative grid-cols-1 gap-0 justify-items-stretch px-6 pt-6 pb-0 h-full rounded border shadow tn-grid hover:border-primary-400"
        >
          {#if !selectedDeal}
            <div
              in:fly|local={{ x: 100, duration: 250, delay: 250 }}
              out:fly|local={{ x: -100, duration: 250 }}
              class="flex flex-col h-full"
            >
              <AccountSelector
                hideLabel={true}
                onChange={async account => {
                  $formData.accountObj = account;
                }}
              />
            </div>

            {#if selectedAccount?.id}
              {#key selectedAccount.id}
                <div
                  class="row-start-2"
                  in:fly|global={{ x: 100, duration: 250, delay: 250 }}
                  out:fly|global={{ x: -100, duration: 250 }}
                >
                  <HantaInputMultiSelect
                    asArray={false}
                    class="w-full"
                    {form}
                    bind:value={$formData.dealObj}
                    label="02. Deal"
                    loadOptions={selectedAccount ? loadOptionsDeals : null}
                    multiple={false}
                    name="dealObj"
                    placeholder="Select..."
                    disabled={!selectedAccount}
                  >
                    <svelte:fragment slot="icon">
                      <Icon
                        icon="fluent:people-search-20-filled"
                        class="w-3 h-3"
                      />
                    </svelte:fragment>

                    <div
                      class="flex items-center w-full h-full"
                      let:index
                      let:item
                      slot="item"
                    >
                      <div class="ml-2 w-full text-sm font-medium truncate">
                        {item?.name}
                      </div>
                      <Badge variant="secondary" class="ml-2"
                        >{item.state}</Badge
                      >
                    </div>
                    <div
                      class="flex overflow-hidden items-center w-full h-full"
                      let:selection
                      slot="selection"
                    >
                      <div class="ml-2 w-full text-sm font-medium truncate">
                        {selection?.name}
                      </div>
                    </div>
                  </HantaInputMultiSelect>
                </div>
              {/key}
            {/if}
          {:else}
            <div
              in:fly|local={{ x: 100, duration: 250, delay: 250 }}
              out:fly|local={{ x: -100, duration: 250 }}
              class="absolute flex-col gap-4 p-6 w-full h-full"
            >
              <div class="flex flex-row gap-4">
                <Avatar.Root
                  class="p-2 rounded-md border border-solid border-primary/20 size-24"
                >
                  <Avatar.Image
                    class="object-scale-down"
                    src={$formData?.accountObj?.logo}
                  />
                  <Avatar.Fallback>-</Avatar.Fallback>
                </Avatar.Root>

                <div class="flex overflow-hidden flex-col gap-4 w-full">
                  <div>
                    <div class="text-sm font-bold">
                      {$formData?.dealObj?.name}
                      <Badge variant="secondary" class="ml-2"
                        >{selectedDeal.state
                          ? selectedDeal.state
                          : 'No state'}</Badge
                      >
                    </div>
                    <div class="text-sm">
                      at {$formData?.accountObj?.name}
                    </div>
                  </div>
                  <div>
                    <DealCandidatesPopover dealId={selectedDeal.id} />
                  </div>
                </div>
              </div>
            </div>
            <Button
              class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-muted data-[state=open]:text-muted-foreground z-100"
              on:click={() => {
                $formData.dealObj = undefined;
              }}
              variant="ghost"
            >
              <Icon icon="mdi:close" class="size-4" />
              <span class="sr-only">Close</span>
            </Button>
          {/if}
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 mx-6" transition:fade>
      <DealPhaseLarge bind:currentPhase={$formData.status} {phases} />
    </div>

    <hr class="mx-6 mb-4" />

    <div class="flex flex-row">
      <HantaInputTextarea
        class="w-1/2 h-full"
        {form}
        name="description"
        placeholder="Start typing to leave a note"
        resizable={false}
        variant="ghost"
      />
      {#if phases.length > 0 && phases.indexOf($formData.status) === phases.length - 1}
        <div
          transition:fly|local={{ x: 100, duration: 250, delay: 250 }}
          class="grid-cols-2 grid-rows-1 gap-4 px-6 w-1/2 tn-grid justify-stretch"
        >
          <HantaInputLovSelect
            {form}
            bind:value={$formData.rejectedBy}
            type="Absagegrund"
            label="Rejected by"
            multiple={false}
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.rejectedReason}
            type="Absagegrund 2"
            label="Rejected reason"
            multiple={false}
          />
        </div>
      {/if}
    </div>
  </div>

  <svelte:fragment slot="footer">
    <div class="flex justify-end space-x-2 w-full">
      <Button
        on:click={e => {
          appStore.closeActivity();
        }}
        variant="outline"
        >Cancel
      </Button>
      <Form.Button variant="default" disabled={!isUnique && isUniqueError}
        >{$formData?.id ? 'Update' : 'Create'} assignment
      </Form.Button>
      {#if !isUnique}
        <div class="self-center text-sm text-red-500">{isUniqueError}</div>
      {/if}
    </div>

    {#if $formData.id}
      <CrmDeleteObject
        bind:open={showDeleteDialog}
        module="activities"
        id={$formData?.id}
        onDeleteFn={() => {
          appStore.closeActivity();
        }}
      />

      <Button
        variant="ghost"
        class="absolute left-4 text-red-500"
        on:click={() => (showDeleteDialog = true)}>Remove assignment</Button
      >
    {/if}
  </svelte:fragment>
</Popup>
