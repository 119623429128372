<script lang="ts">
  import { appStore, contextStore, popupStore } from '$lib/stores/app-store';
  import Navigation from './navigation.svelte';
  import SecondaryNav from './secondary-nav.svelte';
  import HantaSheet from '../ui/hanta-sheet.svelte';
  import HantaPopup from '$lib/ui/hanta-popup.svelte';
  import HantaSpecialPages from '$lib/ui/hanta-speicial-pages.svelte';
  import HantaContext from '$lib/ui/hanta-context.svelte';
  import LinkedInHeader from './linkedin-header.svelte';

  $: showSecondaryNav = !!$appStore.currentModule;
</script>

<div class="w-full">
  <div class="z-30 bg-card dark:bg-card">
    <LinkedInHeader />
    <Navigation />
    {#if showSecondaryNav}
      {#key $appStore.currentModule}
        <SecondaryNav />
      {/key}
    {/if}
  </div>

  {#if $appStore.selected || $appStore.currentModule === 'dashboard'}
    <HantaSheet
      open={$appStore.openPopup}
      module={$appStore.selected?.module}
      id={$appStore.selected?.id}
    />
  {/if}

  {#if !$appStore.openPopup && $appStore.currentModule !== 'dashboard'}
    <HantaSpecialPages module={$appStore.currentModule} />
  {/if}

  {#if $popupStore.isOpen}
    <HantaPopup />
  {/if}
  {#if $contextStore.isOpen}
    <HantaContext />
  {/if}
</div>
