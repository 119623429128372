<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { getActivity } from '$lib/api/queries';
  export let assignment;

  async function openInfo() {
    console.debug('openAssignment', assignment);
    const activityToEdit = await getActivity(assignment.id);
    appStore.openActivity(activityToEdit);
  }
  function openCandidate() {
    console.debug('openCandidate', assignment);
    appStore.setSelectedStack([
      {
        id: assignment?.deal,
        module: 'deals',
        tab: 'kanban',
      },
    ]);
    appStore.select({
      id: assignment?.candidate,
      module: 'contacts',
    });
    appStore.openPopup(true);
  }
</script>

<div
  class="relative min-h-[50px] flex flex-col justify-between w-full my-3 group"
>
  <div class="flex overflow-hidden relative pr-10">
    {#if assignment?.candidateObj}
      <Avatar.Root class="rounded-none size-12">
        <Avatar.Image src={assignment?.candidateObj?.photo} />
        <Avatar.Fallback
          >{assignment?.candidateObj?.name?.substring(0, 1)}.</Avatar.Fallback
        >
      </Avatar.Root>
    {:else}
      <CrmAvatar
        id={assignment?.candidate}
        module="contacts"
        size="size-16"
        asDiv={true}
        class="rounded-none size-16"
      />
    {/if}

    <div
      class="hidden absolute top-0 right-0 flex-col space-y-2 opacity-0 transition-all duration-300 transform -translate-y-4 group-hover:flex group-hover:opacity-100 group-hover:translate-y-0"
    >
      <button on:click={openInfo}>
        <Icon
          icon="mdi:pencil"
          class="size-4 text-muted-foreground hover:text-foreground"
        />
      </button>
      <button on:click={openCandidate}>
        <Icon
          icon="mdi:information"
          class="text-muted-foreground size-4 hover:text-foreground"
        />
      </button>
    </div>
  </div>

  {#if assignment?.candidateObj}
    <div class="flex mt-2 text-xs text-left">
      <div class="text-base font-bold text-md">
        {assignment?.candidateObj?.name}
      </div>
    </div>
  {/if}

  {#if assignment?.description}
    <div class="mt-2 text-base font-extralight overflow-clip">
      {assignment?.description}
    </div>
  {/if}

  <!--div class="mt-2 text-muted-foreground">
    added <Time relative timestamp={assignment?.startDate} />
  </div-->
</div>
