<script lang="ts">
  import AuthUserButtonSupabase from './supabase/user/auth-user-button-supabase.svelte';
  import AuthUserButtonClerk from './clerk/auth-user-button-clerk.svelte';

  import { appStore } from '$lib/stores/app-store';
</script>

<div class="relative">
  {#if import.meta.env.VITE_AUTH_PROVIDER === 'clerk'}
    <AuthUserButtonClerk />
  {:else if import.meta.env.VITE_AUTH_PROVIDER === 'supabase'}
    <AuthUserButtonSupabase />
  {/if}

  <div
    class="size-2 rounded-full transition-colors duration-200 ml-7 -mt-1"
    class:bg-green-500={$appStore.connectionStatus === 'connected'}
    class:bg-red-500={$appStore.connectionStatus === 'disconnected' ||
      $appStore.connectionStatus === 'error'}
    class:bg-yellow-500={$appStore.connectionStatus === 'connecting'}
  />
</div>
