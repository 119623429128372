<script>
  import { Input } from '$lib/components/ui/input';
  import Icon from '@iconify/svelte';
  import { uploadFile } from '$lib/api/storage';
  import { toast } from 'svelte-sonner';
  import { cn } from '$lib/utils/hanta-utils';

  import { appStore } from '$lib/stores/app-store';

  export let folder;
  export let compact = false;
  export let singleFileName;
  export let onUpload = () => {};

  let files;
  export let refresh = 0;

  async function uploadFiles(files) {
    for (const file of files) {
      try {
        await uploadFile(
          file,
          folder,
          singleFileName ? singleFileName : file.name,
          !!singleFileName,
        );
        toast.success(`File ${file.name} uploaded`, {
          duration: 3000,
        });
        onUpload();
      } catch (error) {
        console.error(error);
        toast.error(`File ${file.name} failed to upload: ${error.message}`, {
          duration: 3000,
        });
      }
    }
    refresh = refresh + 1;
  }
</script>

<div
  class={cn(
    'relative flex flex-col items-center mt-2 space-y-5 border border-dashed bg-primary-100 group group:focus:bg-red-100',
    compact ? 'h-28' : 'h-32',
  )}
>
  {#key refresh}
    <Input
      class="w-full absolute top-0 left-0 right-0 bottom-0 z-[1] opacity-0       disabled:!opacity-0 cursor-pointer  h-full"
      key="files"
      id="files"
      type="file"
      bind:files
      multiple={singleFileName ? false : true}
      on:change={e => uploadFiles(e.target.files)}
    />

    {#if !compact}
      <Icon icon="mdi:file-upload" size="40" class="mx-auto mb-0" />
    {/if}

    <div class="text-center">
      <Icon
        icon="mdi:file-upload"
        class="mx-auto mb-2 size-8 text-muted-foreground"
      />
      <span class="text-xs text-gray-500">
        Click

        {#if !$appStore.isMobile}
          or drag files into this field to upload them
        {/if}
      </span>
      <label for="file-upload" class="block mt-2 text-blue-500 cursor-pointer"
        >Choose files</label
      >
    </div>
  {/key}
</div>
