<script>
  import { onMount } from 'svelte';
  import {
    findContactsByLinkedInLink,
    findAccountsByLinkedInLink,
  } from '../api/queries';
  import { contactLocal } from '$lib/mock-data';
  import { linkedInUrlWithoutAbout } from '$lib/utils/string-utils';
  import { appStore } from '$lib/stores/app-store';

  export let linkedin;

  $: linkedin && getCrmContact();
  $: url =
    import.meta.env.DEV || import.meta.env.MODE === 'spa'
      ? contactLocal.linkedin
      : linkedInUrlWithoutAbout(window.location.href);

  async function getCrmContact() {
    try {
      appStore.setCrmContact(undefined);
      appStore.setCrmAccount(undefined);

      if (location.pathname.startsWith('/company/')) {
        const [account, ..._] = await findAccountsByLinkedInLink(url);
        appStore.setCrmAccount(account);
      } else {
        const [contact, ..._] = await findContactsByLinkedInLink(url);
        appStore.setCrmContact(contact);
      }
    } catch (e) {
      console.error(e.message);
    }
  }

  onMount(() => {
    getCrmContact();
  });
</script>
