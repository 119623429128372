<script lang="ts">
  import AccountOwnership from './account-ownership.svelte';
  import { getItems } from '$lib/api/queries';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { createQuery } from '@tanstack/svelte-query';
  import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from '$lib/components/ui/dialog';
  import { appStore } from '$lib/stores/app-store';

  export let accountId: string;

  const ownershipsQuery = createQuery({
    queryKey: ['ownerships', accountId],
    queryFn: () =>
      getItems({
        collection: 'ownerships',
        from: 0,
        to: 100,
        filters: [
          {
            field: 'accountId',
            operator: 'eq',
            value: accountId,
            active: true,
          },
        ],
        select: '*, sales:users(id, name, photo)',
      }),
    enabled: !!accountId,
  });

  let showDialog = false;
  let newAssignment = {};

  function handleChanges() {
    showDialog = false;
    $appStore.queryClient.invalidateQueries(['ownerships', accountId], {
      exact: true,
      refetchActive: true,
    });
  }

  function addAssignment() {
    newAssignment = {};
    showDialog = true;
  }

  function editAssignment(assignment) {
    newAssignment = assignment;
    showDialog = true;
  }

  function closeDialog() {
    showDialog = false;
  }
</script>

<div class="p-16 w-full bg-card">
  <h2 class="mb-4 text-xl font-bold">Sales ownership</h2>
  <Dialog bind:open={showDialog} on:close={closeDialog}>
    <DialogTrigger asChild>
      <Button variant="default" on:click={addAssignment} class="mt-2 mb-8">
        <Icon icon="mdi:plus-circle" class="mr-2" />
        New Ownership
      </Button>
    </DialogTrigger>
    <DialogContent class="sm:max-w-[900px]">
      <DialogHeader>
        <DialogTitle>Add New Ownership</DialogTitle>
      </DialogHeader>
      <AccountOwnership
        bind:assignment={newAssignment}
        {accountId}
        onChanges={handleChanges}
      />
    </DialogContent>
  </Dialog>

  {#if $ownershipsQuery.isLoading}
    <p>Loading...</p>
  {:else if $ownershipsQuery.isError}
    <p>Error: {$ownershipsQuery.error.message}</p>
  {:else}
    {#each $ownershipsQuery.data?.data || [] as assignment, index (assignment.id)}
      {#key `${assignment.id}-${assignment.modifiedAt}`}
        <AccountOwnership
          readOnly={true}
          {assignment}
          {accountId}
          onChanges={handleChanges}
          onEdit={e => editAssignment(e)}
        />
      {/key}
    {/each}
  {/if}
</div>
