export const helpData = {
    "languages": {      
      "ru": {
        "title": "CRM Система - Руководство пользователя",
        "description": "Полное руководство по использованию CRM системы",
        "sections": [
          {
            "id": "companies",
            "title": "1. Работа с компаниями",
            "items": [
              { "text": "Как создать новую компанию", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как редактировать данные компании", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как удалить компанию", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как привязать компанию к куратору", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как создать карточку компании из LinkedIn", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как работать с канбан (Pipeline)", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отметить задолженность и финансовое состояние", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "contacts",
            "title": "2. Работа с контактными лицами",
            "items": [
              { "text": "Как добавить контактное лицо", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как редактировать или удалить контактное лицо", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как найти контактное лицо через быстрый поиск", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как добавить контактное лицо из LinkedIn", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "leads",
            "title": "3. Работа с лидами (LEADS)",
            "items": [
              { "text": "Как посчитать стоимость доставки", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как создать новый лид", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменить статус лида", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменить статус заказа", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменить статус инвойса", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "todo",
            "title": "4. Работа с планами (ToDo)",
            "items": [
              { "text": "Как создать новый план для компании", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как создать новый план для контактного лица", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как создать запись истории звонка", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "orders",
            "title": "5. Создание заказов (ORDER)",
            "items": [
              { "text": "Как создать новый заказ", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как добавить товары или услуги в заказ", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как добавить дополнительные услуги (Страховка, ODA)", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменить статус заказа", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "invoices",
            "title": "6. Создание счета (Invoice)",
            "items": [
              { "text": "Как создать инвойс (счет)", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как редактировать инвойс", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как удалить инвойс", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "payments",
            "title": "7. Платежи и предоплаты",
            "items": [
              { "text": "Как создать предварительный счет", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как добавить платеж в систему", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как закрыть оплату счета частями", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как закрыть оплату счета полностью", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как провести возврат средств", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "filters",
            "title": "8. Фильтры и поиск",
            "items": [
              { "text": "Как использовать фильтры для поиска клиентов", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как найти заказы или инвойсы за определенный период", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отфильтровать историю за период", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отфильтровать компании по категории", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отфильтровать компании Existing или Potential", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отфильтровать компании по последнему контакту", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как фильтровать заказы по статусу или сотруднику", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как использовать фильтры в лидах", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "reports",
            "title": "9. Отчеты и аналитика",
            "items": [
              { "text": "Как получить отчет по общему объему продаж", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как просмотреть информацию о клиентах с наибольшей активностью", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как получить отчет по доходам (TEW & Cost)", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как получить отчет по общему объему отправлений", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как получить отчет по продажам и бонусам", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "users",
            "title": "10. Управление пользователями",
            "items": [
              { "text": "Как создать нового пользователя", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как назначить роли и права доступа", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменить данные пользователя", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как заблокировать или удалить пользователя", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "curators",
            "title": "11. Настройка кураторов",
            "items": [
              { "text": "Как назначить куратора для компании", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как отобразить бонус для Sales", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как настроить процент от прибыли Cost", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "contracts",
            "title": "12. Модуль контракты",
            "items": [
              { "text": "Как создать новый контракт", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как изменять статусы контракта от создания до подписания", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "complaints",
            "title": "13. Модуль Complaint",
            "items": [
              { "text": "Как создать Complaint", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как связать историю с Complaint", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "notifications",
            "title": "14. Работа с уведомлениями",
            "items": [
              { "text": "Как настроить уведомления о новых лидах и заказах", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как настроить уведомления о снижении/увеличении объема отправлений", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как настроить уведомления о снижении/увеличении прибыли", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как сравнить показатели с прошлым годом", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как сравнить показатели с прошлым кварталом", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как сравнить показатели с прошлым месяцем", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "import-export",
            "title": "15. Импорт и экспорт данных",
            "items": [
              { "text": "Как экспортировать данные (лиды, заказы)", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как экспортировать отчеты", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как экспортировать списки клиентов", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "integrations",
            "title": "16. Интеграции",
            "items": [
              { "text": "Как настроить автоматизацию", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как настроить автоответы", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как настроить напоминания", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          },
          {
            "id": "history",
            "title": "17. История действий",
            "items": [
              { "text": "Как посмотреть историю действий по заказу", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как посмотреть историю действий по клиенту", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" },
              { "text": "Как узнать, кто из сотрудников вносил изменения в систему", "link": "https://scribehow.com/shared/How_to_Create_a_New_Account__X7T8AgLLT4i5EcApbhVjgA" }
            ]
          }],      
      },
      "en": {

      }, 
      "de": {
        
      }
    }
  }