<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';

  import { type Account, type ActivityWithRefs, type Deal } from '$db/schema';

  import ActivityActionBar from '../activities/activity-action-bar.svelte';
  import { appStore } from '$lib/stores/app-store';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import { cn } from '$lib/utils/hanta-utils';

  export let deal: Deal;
  export let activity: ActivityWithRefs;

  $: officialName = deal?.name;
  $: customer = deal && deal.customerObj;

  $: logo = customer?.logo || null;

  const openItem = (account: Account) => {
    appStore.setCurrentModule('accounts');
    appStore.select({
      id: account.id,
      module: 'accounts',
    });
    appStore.openPopup(true);
  };
</script>

<Avatar.Root class="rounded-none size-16">
  <Avatar.Image
    alt="Company Logo"
    class="object-scale-down rounded-none"
    src={logo}
  />

  <Avatar.Fallback
    class="w-full p-1 text-[8px] text-center text-muted-foreground rounded-none bg-gradient-to-r from-gray-300 to-gray-100 "
  >
    {officialName}
  </Avatar.Fallback>
</Avatar.Root>

<div class="flex flex-col">
  <div class="text-base font-bold">
    {officialName}
  </div>
  <div class="hidden text-sm text-muted-foreground">
    {customer?.name ?? ''}
  </div>
  <CrmAvatar
    hideIcon={true}
    id={customer?.id}
    module="accounts"
    name={customer?.name}
    on:open={() => openItem(customer)}
    size="size-7"
  />
</div>
<div class={cn($appStore.isMobile ? 'hidden' : 'absolute right-2')}>
  <ActivityActionBar {activity} mode="collapsed" />
</div>
