<script lang="ts">
  import * as Dialog from '$lib/components/ui/dialog';
  import { Button } from '$lib/components/ui/button';
  import * as Accordion from '$lib/components/ui/accordion';
  import { ScrollArea } from '$lib/components/ui/scroll-area';
  import { helpData } from '$lib/config/help-data';
  import { authStore } from '$lib/stores/auth-store';

  export let open = false;
  export let language: 'en' | 'ru' | 'de' = authStore.isLogistics()
    ? 'ru'
    : 'en';

  const closeText = {
    en: 'Close',
    ru: 'Закрыть',
    de: 'Schließen',
  };

  $: content = authStore.isLogistics()
    ? helpData.languages['ru']
    : helpData.languages[language];
</script>

<Dialog.Root bind:open>
  <Dialog.Content class="sm:max-w-[800px] max-h-[80vh]">
    <Dialog.Header>
      <Dialog.Title>{content?.title}</Dialog.Title>
      <Dialog.Description>
        {content?.description}
      </Dialog.Description>
    </Dialog.Header>

    <ScrollArea class="h-[60vh] pr-4">
      <Accordion.Root>
        {#each content?.sections || [] as section}
          <Accordion.Item value={section.id}>
            <Accordion.Trigger>{section.title}</Accordion.Trigger>
            <Accordion.Content>
              <ul class="space-y-2 list-disc pl-6">
                {#each section.items as item}
                  <li>
                    <a
                      href={item.link}
                      target="_blank"
                      class="hover:underline text-blue-500"
                    >
                      {item.text}
                    </a>
                  </li>
                {/each}
              </ul>
            </Accordion.Content>
          </Accordion.Item>
        {/each}
      </Accordion.Root>
    </ScrollArea>

    <Dialog.Footer class="flex justify-between items-center">
      <div class="flex gap-2">
        <Button variant="ghost" on:click={() => (language = 'en')}>EN</Button>
        {#if authStore.isCRM()}
          <Button variant="ghost" on:click={() => (language = 'ru')}>RU</Button>
        {/if}
        <Button variant="ghost" on:click={() => (language = 'de')}>DE</Button>
      </div>
      <Button variant="outline" on:click={() => (open = false)}>
        {closeText[language]}
      </Button>
    </Dialog.Footer>
  </Dialog.Content>
</Dialog.Root>
