<script lang="ts">
  import Icon from '@iconify/svelte';
  import AccountSelector from '$lib/widgets/accounts/account-selector.svelte';
  import ContactsSelector from '$lib/widgets/contacts/contacts-selector.svelte';

  export let form;
  let { form: formData } = form;

  export let readOnly = false;
  export let simpleMode = false;
</script>

<div class="p-6 space-y-4 rounded-lg shadow-md bg-card">
  <h2 class="flex items-center mb-4 text-2xl font-semibold">
    <Icon icon="mdi:account-circle" class="mr-2" />
    Customer Info
  </h2>

  <AccountSelector bind:value={$formData.account} {readOnly} />

  {#if !simpleMode}
    {#key $formData.account?.id}
      <ContactsSelector
        {readOnly}
        accountId={$formData.account?.id}
        bind:value={$formData.contactObj}
      />
    {/key}
  {/if}

  <div class="space-y-4">
    <div class="flex items-center">
      <strong class="w-32 text-muted-foreground">Credit Rating:</strong>
      <span class="text-foreground"
        >{$formData.account?.creditRating ?? '-'}</span
      >
    </div>
    <div class="flex items-center">
      <strong class="w-32 text-muted-foreground">Debt Reason:</strong>
      <span class="text-foreground">{$formData.account?.debtReason ?? '-'}</span
      >
    </div>
    {#if !simpleMode}
      <div class="flex items-center">
        <strong class="w-32 text-muted-foreground">Current Balance:</strong>
        <span class="text-foreground"
          >{$formData.account?.currentBalance?.toFixed(0) ?? '-'}</span
        >
      </div>
      <div class="flex items-center">
        <strong class="w-32 text-muted-foreground">Max Debt:</strong>
        <span class="text-foreground"
          >{$formData.account?.maxDebt?.toFixed(0) ?? '-'}</span
        >
      </div>
    {/if}
    <div class="flex items-center">
      <strong class="w-32 text-muted-foreground">Current Credit Limit:</strong>
      <span class="text-foreground"
        >{$formData.account?.currentCreditLimit?.toFixed(0) ?? '-'}</span
      >
    </div>

    {#if !simpleMode}
      <div class="flex items-center">
        <strong class="w-32 text-muted-foreground">Customer Tariff:</strong>
        <span class="text-foreground"
          >{$formData.account?.customerTariff?.name ?? '-'}</span
        >
      </div>
      <div class="flex items-center">
        <strong class="w-32 text-muted-foreground">Base Tariff:</strong>
        <span class="text-foreground"
          >{$formData.account?.baseTariff?.name ?? '-'}</span
        >
      </div>
    {/if}
  </div>
</div>
