<script lang="ts">
  import { slide } from 'svelte/transition';
  import { Button } from '$lib/components/ui/button';
  import { Badge } from '$lib/components/ui/badge';
  import * as Popover from '$lib/components/ui/popover';
  import Icon from '@iconify/svelte';
  import { deepEqual } from 'fast-equals';

  export let form;

  let { form: formData, errors } = form;
  let initialData = {};
  let popoverOpen = false;

  $: {
    // Update initial data when form is reset
    if (!form.isTainted()) {
      initialData = { ...$formData };
    }
  }

  // Normalize empty values (undefined, null, empty string)
  function normalizeEmpty(value: any): any {
    if (value === undefined || value === null || value === '') return null;
    return value;
  }

  // Compare current form data with initial data to find changed fields
  $: changedFields = $formData
    ? Object.keys($formData).filter(key => {
        const initial = normalizeEmpty(initialData[key]);
        const current = normalizeEmpty($formData[key]);
        return !deepEqual(initial, current);
      })
    : [];

  $: hasChanges = changedFields?.length > 0;
  $: errorCount = Object.keys($errors).length;
  $: changedFieldsCount = changedFields.length;

  // Format field name for display
  function formatFieldName(field: string): string {
    return field
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
      .replace(/Id$/, '') // Remove Id suffix
      .trim();
  }
</script>

{#if hasChanges || errorCount > 0}
  <div
    class="sticky bottom-0 pb-0 mb-0 border-t border-solid shadow border-t-primary-200/30 bg-muted"
    out:slide={{ duration: 200 }}
    in:slide={{ duration: 200 }}
  >
    <div class="container flex justify-between items-center py-4">
      <div class="flex items-center space-x-3">
        {#if errorCount > 0}
          <div class="text-destructive flex items-center gap-2">
            <Icon icon="mdi:alert-circle" class="size-4" />
            {errorCount}
            {errorCount === 1 ? 'error' : 'errors'} in form
          </div>
        {/if}

        {#if hasChanges}
          <Popover.Root bind:open={popoverOpen}>
            <Popover.Trigger class="cursor-pointer">
              <div class="flex items-center gap-2">
                <Icon icon="mdi:pencil" class="size-4 text-muted-foreground" />
                <span class="text-muted-foreground">
                  {changedFieldsCount} changed {changedFieldsCount === 1
                    ? 'field'
                    : 'fields'}
                </span>
              </div>
            </Popover.Trigger>
            <Popover.Content
              class="z-50 w-72 p-3 rounded-lg border bg-popover text-popover-foreground shadow-md"
              sideOffset={5}
            >
              <div class="space-y-2">
                <h4 class="font-medium text-sm">Changed Fields</h4>
                <div class="flex flex-wrap gap-1">
                  {#each changedFields as field}
                    <Badge variant="outline" class="text-xs">
                      {formatFieldName(field)}
                    </Badge>
                  {/each}
                </div>
              </div>
            </Popover.Content>
          </Popover.Root>
        {/if}
      </div>

      <div class="flex items-center space-x-2">
        <Button
          type="button"
          variant="outline"
          on:click={() => {
            form.reset();
            initialData = { ...$formData };
          }}
        >
          Reset
        </Button>
        <Button type="submit" disabled={errorCount > 0}>Save Changes</Button>
      </div>
    </div>
  </div>
{/if}
