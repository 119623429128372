import { supabase } from '$lib/api/supabase';
import { authStore } from '$lib/stores/auth-store';
import { type AuthStoreType } from '$lib/stores/auth-store';

interface Organization {
  id: string;
  name: string;
  slug: string;
  logo_url?: string;
  created_at: string;
  updated_at: string;
  role?: string;
}

// Helper to update auth store
function updateAuthStore(updates: Partial<AuthStoreType>) {
  authStore.update(state => ({ ...state, ...updates }));
}

// Initialize auth state
export async function initializeAuth() {
  // Get initial session
  const {
    data: { session },
  } = await supabase().auth.getSession();
  if (session?.user) {
    await handleAuthChange('SIGNED_IN', session);
  }

  // Set up auth state change listener
  const {
    data: { subscription },
  } = supabase().auth.onAuthStateChange(handleAuthChange);

  return subscription;
}

// Handle auth state changes
async function handleAuthChange(event: string, session: any) {
  const user = session?.user ?? null;

  if (user) {
    updateAuthStore({ user });
    const orgs = await fetchUserOrganizations(user.id);

    // Restore last used organization or use first available
    const storedOrgId = localStorage.getItem('currentOrgId');
    let organization = null;
    if (storedOrgId && orgs.some(org => org.id === storedOrgId)) {
      organization = await switchOrganization(storedOrgId);
    } else if (orgs.length > 0) {
      organization = await switchOrganization(orgs[0].id);
    }
    updateAuthStore({ organizations: orgs, organization });
  } else {
    updateAuthStore({
      user: null,
      organization: null,
    });
    localStorage.removeItem('currentOrgId');
  }
}

// Auth methods
export async function signInWithEmail(email: string, password: string) {
  const {
    data: { user },
    error,
  } = await supabase().auth.signInWithPassword({
    email,
    password,
  });

  if (error) {
    throw new Error(error.message);
  }

  return user;
}

export async function signInWithGoogle() {
  const { data, error } = await supabase().auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: window.location.origin,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export async function signInWithLinkedIn() {
  const { data, error } = await supabase().auth.signInWithOAuth({
    provider: 'linkedin',
    options: {
      redirectTo: window.location.origin,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export async function signOut() {
  const { error } = await supabase().auth.signOut();
  if (error) {
    throw new Error(error.message);
  }
}

// Organization methods
export async function fetchUserOrganizations(
  userId: string,
): Promise<Organization[]> {
  const { data: orgMemberships, error } = await supabase()
    .from('organization_members')
    .select(
      `
      organization_id,
      role,
      organizations (
        id,
        name,
        slug,
        logo_url,
        created_at,
        updated_at
      )
    `,
    )
    .eq('user_id', userId);
  if (error) {
    throw new Error(error.message);
  }

  return orgMemberships.map(membership => ({
    ...membership.organizations,
    role: membership.role,
  }));
}

export async function createOrganization(orgData: {
  name: string;
  slug: string;
  logoUrl?: string;
}) {
  let authState: AuthState;
  authStore.subscribe(state => {
    authState = state;
  })();

  const { data: org, error: orgError } = await supabase()
    .from('organizations')
    .insert([
      {
        name: orgData.name,
        slug: orgData.slug,
        logo_url: orgData.logoUrl,
      },
    ])
    .select()
    .single();

  if (orgError) {
    throw new Error(orgError.message);
  }

  const orgs = await fetchUserOrganizations(authState.user?.id!);
  updateAuthStore({ organizations: orgs });

  return org;
}

export async function switchOrganization(orgId: string): Promise<Organization> {
  try {
    // Set the org_id in JWT claims
    await supabase().rpc('switch_organization', { org_id: orgId });

    // Force token refresh
    /*const {
      data: { session },
      error: refreshError,
    } = await supabase().auth.refreshSession();
    if (refreshError) throw refreshError;*/
    //  refreshClient();

    const { data: org, error } = await supabase()
      .from('organizations')
      .select('*')
      .eq('id', orgId)
      .single();

    if (error) throw error;

    updateAuthStore({ organization: org });
    localStorage.setItem('currentOrgId', orgId);

    return org;
  } catch (error) {
    console.error('Error switching organization:', error);
    throw error;
  }
}
