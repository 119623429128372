<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { Input } from '$lib/components/ui/input';
  import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
  } from '$lib/components/ui/select';
  import * as Resizable from '$lib/components/ui/resizable';
  import Icon from '@iconify/svelte';
  import { writable } from 'svelte/store';

  export let dimensions: any[] = [];

  let selectedDimension = writable(null);
  let isEditing = writable(false);
  let searchTerm = '';

  const typeOptions = ['Single Value', 'Range'];
  const fieldOptions = [
    { key: 'this.operator', label: 'Operator' },
    { key: 'this.product', label: 'Product' },
    { key: 'this.weight', label: 'Weight' },
    { key: 'this.volume', label: 'Volume' },
  ];

  function handleEdit(dimension) {
    $selectedDimension = JSON.parse(JSON.stringify(dimension)); // Deep clone
    $isEditing = true;
  }

  function handleUpdate() {
    const index = dimensions.findIndex(d => d.name === $selectedDimension.name);
    if (index !== -1) {
      dimensions[index] = { ...$selectedDimension };
    }
    $isEditing = false;
  }

  function handleCancel() {
    $isEditing = false;
    $selectedDimension = null;
  }

  function handleAdd() {
    const newDimension = {
      name: 'New Dimension',
      type: 'Single Value',
      field: fieldOptions[0],
      values: [],
    };
    dimensions = [...dimensions, newDimension];
    handleEdit(newDimension);
  }

  function handleRemove(dimension) {
    if (
      confirm(
        `Are you sure you want to remove the dimension "${dimension.name}"?`,
      )
    ) {
      dimensions = dimensions.filter(d => d.name !== dimension.name);
      if ($selectedDimension && $selectedDimension.name === dimension.name) {
        handleCancel();
      }
    }
  }

  function addValue() {
    $selectedDimension.values = [
      ...$selectedDimension.values,
      { name: '', from: '', to: '' },
    ];
  }

  function removeValue(index) {
    $selectedDimension.values = $selectedDimension.values.filter(
      (_, i) => i !== index,
    );
  }

  $: filteredDimensions = dimensions.filter(
    d =>
      d.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      d.field.toLowerCase().includes(searchTerm.toLowerCase()),
  );
</script>

<div class="p-4">
  <div class="flex justify-between items-center mb-4">
    <h1 class="text-2xl font-bold">Dimensions Manager</h1>
    <Button on:click={handleAdd}>
      <Icon icon="mdi:plus" class="size-4" />
      Add Dimension
    </Button>
  </div>

  <div class="mb-4">
    <Input
      type="text"
      placeholder="Search dimensions..."
      bind:value={searchTerm}
    >
      <Icon icon="mdi:magnify" class="text-muted-foreground size-4" />
    </Input>
  </div>

  <Resizable.PaneGroup
    direction="horizontal"
    class="h-[600px] max-w-4xl rounded-lg border"
  >
    <Resizable.Pane defaultSize={40} minSize={30}>
      <div class="overflow-y-auto p-4 h-full">
        <h2 class="mb-4 text-xl font-bold">Dimensions</h2>
        {#if filteredDimensions.length === 0}
          <p class="text-gray-500">No dimensions found</p>
        {/if}
        {#each filteredDimensions as dimension (dimension.name)}
          <div
            class="flex items-center justify-between p-2 rounded transition-colors duration-200 ease-in-out
                      {$selectedDimension &&
            $selectedDimension.name === dimension.name
              ? 'bg-blue-100 hover:bg-blue-200'
              : 'hover:bg-gray-100'}"
          >
            <span>{dimension.name}</span>
            <div>
              <Button
                variant="ghost"
                size="sm"
                on:click={() => handleEdit(dimension)}
              >
                <Icon icon="mdi:pencil" class="size-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                on:click={() => handleRemove(dimension)}
              >
                <Icon icon="mdi:trash" class="size-4" />
              </Button>
            </div>
          </div>
        {/each}
      </div>
    </Resizable.Pane>
    <Resizable.Handle withHandle />
    <Resizable.Pane defaultSize={60} minSize={40}>
      <div class="overflow-y-auto p-4 h-full">
        {#if $isEditing}
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-xl font-bold">Edit Dimension</h2>
            <Button variant="ghost" size="sm" on:click={handleCancel}>
              <Icon icon="mdi:close" class="size-4" />
            </Button>
          </div>
          <div class="space-y-4">
            <div>
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <Input
                type="text"
                bind:value={$selectedDimension.name}
                id="name"
              />
            </div>
            <div>
              <label for="type" class="block text-sm font-medium text-gray-700"
                >Type</label
              >

              <Select bind:value={$selectedDimension.type} id="type">
                <SelectTrigger class="w-full">
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  {#each typeOptions as option}
                    <SelectItem value={option}>{option}</SelectItem>
                  {/each}
                </SelectContent>
              </Select>
            </div>
            <div>
              <label for="field" class="block text-sm font-medium text-gray-700"
                >Field</label
              >
              <Select bind:value={$selectedDimension.field} id="field">
                <SelectTrigger class="w-full">
                  <SelectValue placeholder="Select field" />
                </SelectTrigger>
                <SelectContent>
                  {#each fieldOptions as option}
                    <SelectItem value={option.key}>{option.label}</SelectItem>
                  {/each}
                </SelectContent>
              </Select>
            </div>
            <div>
              <div class="block text-sm font-medium text-gray-700">Values</div>
              {#each $selectedDimension.values as value, i}
                <div class="flex mb-2 space-x-2">
                  {#if $selectedDimension.type === 'Single Value'}
                    <Input
                      type="text"
                      placeholder="Name"
                      bind:value={value.name}
                    />
                  {/if}
                  {#if $selectedDimension.type === 'Range'}
                    <Input
                      type="text"
                      placeholder="From"
                      bind:value={value.from}
                    />
                    <Input type="text" placeholder="To" bind:value={value.to} />
                  {/if}
                  <Button
                    variant="outline"
                    size="sm"
                    on:click={() => removeValue(i)}
                  >
                    <Icon icon="mdi:trash" class="size-4" />
                  </Button>
                </div>
              {/each}
              <Button variant="outline" size="sm" on:click={addValue}>
                <Icon icon="mdi:plus" class="size-4" />
                Add Value
              </Button>
            </div>
            <Button on:click={handleUpdate}>Update Dimension</Button>
          </div>
        {:else}
          <div class="flex justify-center items-center h-full text-gray-500">
            Select a dimension to edit
          </div>
        {/if}
      </div>
    </Resizable.Pane>
  </Resizable.PaneGroup>
</div>
