<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { superForm } from 'sveltekit-superforms';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { type Ownership, ownershipsSchema } from './../../../db/schema';
  import UsersSelector from './../../widgets/users/users-selector.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';
  import { deleteOwnership, saveOwnership } from '$lib/api/mutations';
  import Icon from '@iconify/svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';

  export let assignment;
  export let accountId;
  export let onChanges = () => {};
  export let onEdit = e => {};
  export let readOnly = false;
  const form = superForm<Ownership>(assignment, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(ownershipsSchema),
    onSubmit: async () => {
      console.debug('submit', $formData);
      await saveOwnership($formData, accountId);
      setTimeout(() => {
        onChanges();
      }, 300);
    },
  });

  const { form: formData, enhance } = form;

  const remove = () => {
    assignment.id && deleteOwnership(assignment.id);
    onChanges();
  };
</script>

<form
  method="POST"
  use:enhance
  class="grid grid-cols-4 gap-6 items-baseline p-4 pt-12 max-w-4xl rounded-lg shadow-sm transition-colors duration-200 bg-card hover:bg-muted/50"
>
  {#if readOnly}
    <div class="flex flex-col gap-2">
      <Button
        variant="ghost"
        class="self-end w-12 h-full"
        on:click={() => onEdit(assignment)}
      >
        <Icon icon="mdi:pencil" class="size-4" />
      </Button>

      <Button variant="ghost" class="self-end w-12 h-full" on:click={remove}>
        <Icon icon="mdi:trash" class="size-4" />
      </Button>
    </div>
  {/if}
  <UsersSelector
    bind:value={$formData.sales}
    label="Assigned to"
    multiple={false}
    {readOnly}
  />

  <HantaInputDate {form} name="validFrom" label="From" {readOnly} />

  <HantaInputDate {form} name="validTo" label="Till" {readOnly} />

  <div class="col-span-3">
    <FormActions {form} />
  </div>
</form>
