import { supabase } from './supabase';
import { get } from 'svelte/store';
import { authStore } from '$lib/stores/auth-store';

interface ReportParams {
  startDate: string;
  endDate: string;
  compareWithPeriod?: 'previous_period' | 'previous_year' | 'custom';
  compareStartDate?: string;
  compareEndDate?: string;
}

interface FinancialMetric {
  date: string;
  value: number;
}

interface ComparisonMetrics {
  current: number;
  previous: number;
  change: number;
  changePercentage: number;
}

interface KPIs {
  totalRevenue: ComparisonMetrics;
  totalExpenses: ComparisonMetrics;
  netProfit: ComparisonMetrics;
  cashBalance: ComparisonMetrics;
  profitMargin: ComparisonMetrics;
  revenueGrowth: ComparisonMetrics;
  operatingExpenseRatio: ComparisonMetrics;
}

interface FinancialReport {
  revenue: FinancialMetric[];
  expenses: FinancialMetric[];
  profit: FinancialMetric[];
  cashFlow: FinancialMetric[];
  kpis: KPIs;
}

interface CustomerActivityReport {
  customer: string;
  current: {
    invoiceCount: number;
    totalSpent: number;
    avgPurchase: number;
    firstPurchase: string;
    lastPurchase: string;
  };
  previous: {
    invoiceCount: number;
    totalSpent: number;
    avgPurchase: number;
    firstPurchase: string;
    lastPurchase: string;
  };
  changes: {
    invoiceCount: number;
    totalSpent: number;
    avgPurchase: number;
    growthRate: number | null;
  };
}

export async function getFinancialReport(
  params: ReportParams,
): Promise<FinancialReport> {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'get_financial_report',
    {
      start_date: params.startDate,
      end_date: params.endDate,
      compare_with_period: params.compareWithPeriod || 'previous_period',
      compare_start_date: params.compareStartDate,
      compare_end_date: params.compareEndDate,
    },
  );

  if (error) throw error;
  return data;
}

export async function getCustomerActivityReport(
  params: Pick<ReportParams, 'startDate' | 'endDate'>
): Promise<CustomerActivityReport[]> {
  const { data, error } = await supabase(get(authStore)?.token).rpc(
    'get_customer_activity', 
    {
      start_date: params.startDate,
      end_date: params.endDate,
      compare_with_period: 'previous_period'
    }
  );

  if (error) throw error;
  return data || [];
}
