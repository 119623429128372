<script lang="ts">
  import { onMount } from 'svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { setMode, resetMode, mode } from 'mode-watcher';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
  import * as Toggle from '$lib/components/ui/toggle';
  import Icon from '@iconify/svelte';

  import { appStore } from '$lib/stores/app-store';
  import { writable } from 'svelte/store';

  const themes = [
    { value: 'gray', label: 'Gray', color: 'bg-gray-500' },
    { value: 'rose', label: 'Rose', color: 'bg-rose-500' },
    { value: 'orange', label: 'Orange', color: 'bg-orange-500' },
    { value: 'blue', label: 'Blue', color: 'bg-blue-500' },
    {
      value: 'lightblue',
      label: 'Light Blue',
      color: 'bg-sky-500',
    },
    {
      value: 'yellow',
      label: 'Yellow',
      color: 'bg-yellow-500',
    },
  ];

  // Create a store to manage the current theme
  const currentTheme = writable('gray');

  function setTheme(theme: string) {
    console.log(`Setting theme: ${theme}`);
    currentTheme.set(theme);

    // Update localStorage to persist theme
    localStorage.setItem('app-theme', theme);

    // Directly pass the theme value instead of accessing store
    applyTheme(theme);
  }

  function toggleMode() {
    // Toggle between light and dark modes
    if ($mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  }

  function applyTheme(theme: string) {
    const root = document.documentElement;

    // Handle dark mode
    if ($mode === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }

    // Remove all theme classes
    const themeClasses = themes.map(t => `theme-${t.value}`);
    root.classList.remove(...themeClasses);

    // Always add the theme class, even for gray
    root.classList.add(`theme-${theme}`);
  }

  // New 3D state management
  type ThemeState = 'light' | 'dark' | 'system';
  let currentThemeState: ThemeState = 'system';

  function cycleThemeState() {
    const states: ThemeState[] = ['light', 'dark', 'system'];
    const currentIndex = states.indexOf(currentThemeState);
    const nextIndex = (currentIndex + 1) % states.length;
    currentThemeState = states[nextIndex];

    // Apply the corresponding mode based on the state
    switch (currentThemeState) {
      case 'light':
        setMode('light');
        break;
      case 'dark':
        setMode('dark');
        break;
      case 'system':
        resetMode();
        break;
    }
  }

  // Determine icon based on current theme state
  function getThemeIcon() {
    switch (currentThemeState) {
      case 'light':
        return 'mdi:white-balance-sunny';
      case 'dark':
        return 'material-symbols:dark-mode-outline';
      case 'system':
        return 'material-symbols:settings-suggest-outline';
      default:
        return 'mdi:white-balance-sunny';
    }
  }

  onMount(() => {
    // Retrieve theme from localStorage on mount
    const savedTheme = localStorage.getItem('app-theme') || 'gray';
    console.log(`Saved theme from localStorage: ${savedTheme}`);
    currentTheme.set(savedTheme);

    // Apply theme on mount
    applyTheme(savedTheme);

    // Subscribe to both theme and mode changes
    const unsubscribeTheme = currentTheme.subscribe(theme => {
      applyTheme(theme);
    });

    const unsubscribeModeWatcher = mode.subscribe(currentMode => {
      console.log(`Mode changed to: ${currentMode}`);
      applyTheme($currentTheme);
    });

    // Cleanup subscriptions on component destroy
    return () => {
      unsubscribeTheme();
      unsubscribeModeWatcher();
    };
  });
</script>

<div class="flex items-center gap-2">
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="outline"
        size="icon"
        class={cn($appStore.isCollapsed ? 'w-10' : 'w-24 px-3')}
      >
        {#if $appStore.isCollapsed}
          <Icon
            icon="mdi:white-balance-sunny"
            class={cn(
              'absolute h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all',
              'light:rotate-0 light:scale-100',
              'dark:-rotate-90 dark:scale-0',
              'system:rotate-180 system:scale-50',
              $appStore.isCollapsed ? 'w-24' : 'w-[1.2rem]',
            )}
          />

          <Icon
            icon="material-symbols:dark-mode-outline"
            class={cn(
              'absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all',
              'light:rotate-90 light:scale-0',
              'dark:rotate-0 dark:scale-100',
              'system:rotate-180 system:scale-50',
            )}
          />

          <Icon
            icon="material-symbols:settings-suggest-outline"
            class={cn(
              'absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all',
              'light:rotate-90 light:scale-0',
              'dark:rotate-90 dark:scale-0',
              'system:rotate-0 system:scale-100',
            )}
          />
        {:else}
          <Icon
            icon={$mode === 'dark'
              ? 'material-symbols:dark-mode-outline'
              : $mode === 'light'
                ? 'mdi:white-balance-sunny'
                : 'material-symbols:settings-suggest-outline'}
            class="h-[1.2rem] w-[1.2rem] visible"
          />
          <span class="ml-2 flex-1" class:sr-only={$appStore.isCollapsed}
            >Theme</span
          >
        {/if}
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content align="end" class="z-[99900] w-56">
      <DropdownMenu.Group>
        <DropdownMenu.Label>Mode</DropdownMenu.Label>
        <DropdownMenu.Item on:click={() => setMode('light')}
          >Light</DropdownMenu.Item
        >
        <DropdownMenu.Item on:click={() => setMode('dark')}
          >Dark</DropdownMenu.Item
        >
        <DropdownMenu.Item on:click={() => resetMode()}
          >System</DropdownMenu.Item
        >
      </DropdownMenu.Group>

      <DropdownMenu.Separator />

      <DropdownMenu.Group>
        <DropdownMenu.Label>Theme</DropdownMenu.Label>
        {#each themes as theme}
          <DropdownMenu.Item
            on:click={() => setTheme(theme.value)}
            class="flex justify-between items-center"
          >
            <span>{theme.label}</span>
            <span class={`w-4 h-4 rounded-full ${theme.color}`}></span>
          </DropdownMenu.Item>
        {/each}
      </DropdownMenu.Group>
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  {#if !$appStore.isCollapsed}
    <Toggle.Root
      pressed={$mode === 'dark'}
      onPressedChange={() => toggleMode()}
      class="border"
    >
      <Icon
        icon={$mode === 'dark'
          ? 'material-symbols:dark-mode-outline'
          : 'mdi:white-balance-sunny'}
        class="h-5 w-5"
      />
    </Toggle.Root>
  {/if}
</div>
