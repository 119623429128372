<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import Icon from '@iconify/svelte';
  import { Button } from '$lib/components/ui/button';
  import * as Drawer from '$lib/components/ui/drawer';

  import Filters from './filters.svelte';
  import Badge from '$lib/components/ui/badge/badge.svelte';

  export let collection: string = 'accounts';
  export let filters: any[] = [];
  let open = false;

  const dispatch = createEventDispatcher();

  $: filters = filters || []; // Ensure filters is never null

  function onCancel() {
    open = false;
  }
  function onApplyFilters(filters) {
    console.debug('onApplyFilters', filters);
    dispatch('filters', filters);
    open = false;
  }
</script>

<Drawer.Root bind:open>
  <Drawer.Trigger asChild let:builder>
    <Button
      builders={[builder]}
      variant={filters.length > 0 ? 'secondary' : 'outline'}
      class="space-x-2 text-xs"
    >
      <Icon icon="mdi:filter" />
      {#if filters.length > 0}
        <Badge variant="default">{filters.length}</Badge>
      {/if}
    </Button>
  </Drawer.Trigger>
  <Drawer.Content class="z-[10000]">
    <div class="relative mx-auto w-full max-w-6xl">
      <Drawer.Header>
        <Drawer.Title>Filters</Drawer.Title>
        <Drawer.Description>Add fields to filter your data.</Drawer.Description>
      </Drawer.Header>
      <div class="flex flex-col h-full">
        <div class="flex-1">
          <Filters
            hideButtons={false}
            {collection}
            {filters}
            on:cancel={onCancel}
            on:filters={event => onApplyFilters(event.detail)}
          />
        </div>
      </div>
    </div>
  </Drawer.Content>
</Drawer.Root>
