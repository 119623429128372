<script lang="ts">
  import { getContext } from 'svelte';

  import HantaInputMultiSelectLean from './hanta-input-multi-select-lean.svelte';
  import Icon from '@iconify/svelte';
  import { Label } from '$lib/components/ui/label';
  import { cn } from '$lib/utils/hanta-utils';
  import { Button } from '$lib/components/ui/button';
  import { toast } from 'svelte-sonner';
  import { createQuery, useQueryClient } from '@tanstack/svelte-query';
  import { getItems } from '$lib/api/queries';
  import { insertLov, updateLov } from '$lib/api/mutations';
  import { insertLovsSchema, type Lovs } from '$db/schema';
  import { type LovType } from '$lib/widgets/input/lov-types';
  import { getFlagEmoji } from '$lib/app/utils';

  export let form: any;
  export let value: any;
  export let type: LovType;
  export let name: string;
  export let label: string;
  export let placeholder: string = 'Select';
  export let multiple: boolean = true;
  export let addable: boolean = false;
  export let readOnly: boolean = false;
  export let icon: any | undefined = undefined;
  export let renderAs: 'dropdown' | 'buttons' | undefined = undefined;
  export let optionsPerRow: number = 2;

  $: lov = $lovQuery.data?.data[0] as Lovs | undefined;
  $: options = updateOptions(lov);

  $: gridColumns = `grid-cols-${Math.min(Math.max(optionsPerRow, 1), 6)}`;

  let focusedIndex: number = -1;
  let gridRef: HTMLDivElement;

  const queryClient = useQueryClient();

  const lovQuery = createQuery({
    queryKey: ['lovs', type],
    queryFn: () =>
      getItems({
        collection: 'lovs',
        from: 0,
        to: 1,
        search: '',
        select: 'id,name,values',
        filters: [
          {
            field: 'name',
            operator: 'eq',
            value: type,
            active: true,
          },
        ],
      }),
  });

  function updateOptions(lov: Lovs | undefined) {
    return (lov?.values || [])
      .flat()
      .map(el =>
        type === 'Countries'
          ? `${el.name} / ${el.code} ${getFlagEmoji(el.code)}`
          : el.name || el.code || '',
      );
  }

  async function onNewLovAdded(newValue: string) {
    if (!lov) {
      await insertLov({
        name: type,
        values: [{ name: newValue }],
      });
      queryClient.invalidateQueries(['lovs']);
      return;
    }

    const updatedLov = { ...lov, values: [...lov.values, { name: newValue }] };
    const lovToUpdate = insertLovsSchema.parse(updatedLov) as Lovs;

    const UNDO_TIMEOUT = 5000;
    const timeoutId = setTimeout(async () => {
      const newLov = await updateLov(lovToUpdate);
      queryClient.setQueryData(['lovs', type], { data: [newLov] });
    }, UNDO_TIMEOUT);

    // Optimistically update the UI
    queryClient.setQueryData(['lovs', type], { data: [lovToUpdate] });

    toast(`New ${label ?? 'tag'} entry created: "${newValue}"`, {
      duration: UNDO_TIMEOUT,
      action: {
        label: 'Undo',
        onClick: e => {
          clearTimeout(timeoutId);
          const revertedLov = {
            ...lov,
            values: lov.values.filter(el => el.name !== newValue),
          };
          queryClient.setQueryData(['lovs', type], { data: [revertedLov] });
        },
      },
    });
  }

  function handleKeydown(event: KeyboardEvent) {
    if (!options.length) return;

    if (renderAs === 'dropdown' || renderAs === undefined) {
      if (event.key === 'Enter' || event.key === 'Backspace') {
        event.preventDefault();
        if (options.length > 0) {
          const firstOption = options[0];
          value = value === firstOption ? null : firstOption;
        }
        return;
      }
    }

    switch (event.key) {
      case 'ArrowRight':
        event.preventDefault();
        focusedIndex = focusedIndex + 1;
        if (focusedIndex >= options.length) {
          focusedIndex = 0;
        }
        break;
      case 'ArrowLeft':
        event.preventDefault();
        focusedIndex = focusedIndex - 1;
        if (focusedIndex < 0) {
          focusedIndex = options.length - 1;
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        focusedIndex = focusedIndex + optionsPerRow;
        if (focusedIndex >= options.length) {
          focusedIndex = focusedIndex % optionsPerRow;
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        focusedIndex = focusedIndex - optionsPerRow;
        if (focusedIndex < 0) {
          const lastRowStart =
            options.length - (options.length % optionsPerRow || optionsPerRow);
          focusedIndex = Math.min(
            lastRowStart + (focusedIndex + optionsPerRow),
            options.length - 1,
          );
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (focusedIndex >= 0) {
          const newValue = options[focusedIndex];
          value = value === newValue ? null : newValue;
        }
        break;
      case 'Backspace':
      case 'Delete':
        event.preventDefault();
        value = null;
        break;
      case 'Tab':
        focusedIndex = -1;
        break;
      case ' ':
        event.preventDefault();
        if (focusedIndex >= 0) {
          const newValue = options[focusedIndex];
          value = value === newValue ? null : newValue;
        }
        break;
    }
  }

  $: if (focusedIndex >= 0 && gridRef) {
    const buttons = gridRef.querySelectorAll('button');
    buttons[focusedIndex]?.focus();
  }
</script>

{#if renderAs === 'dropdown' || renderAs === undefined}
  <HantaInputMultiSelectLean
    {readOnly}
    {form}
    bind:value
    {label}
    {placeholder}
    {name}
    {icon}
    onItemAdded={onNewLovAdded}
    {options}
    {multiple}
    {addable}
    simpleMode={true}
  />
{:else if renderAs === 'buttons'}
  <div class="space-y-2">
    {#if label}
      <Label>{label}</Label>
    {/if}
    <!-- svelte-ignore a11y-interactive-supports-focus -->
    <div
      bind:this={gridRef}
      class={cn('grid gap-2 focus-within:outline-none', gridColumns)}
      on:keydown={handleKeydown}
      role="listbox"
      aria-label={label}
    >
      {#each options as option, i}
        {@const isSelected = Array.isArray(value)
          ? value.includes(option)
          : value === option}

        <Button
          variant="outline"
          size="lg"
          role="option"
          aria-selected={isSelected}
          data-focused={i === focusedIndex}
          tabindex={i === 0 ? '0' : '-1'}
          disabled={readOnly}
          class={cn(
            'h-auto relative flex items-center justify-between w-full p-3 transition-colors duration-200 hover:border-primary/50 focus-visible:ring-primary disabled:opacity-100',
            isSelected &&
              'border-primary bg-primary/20 hover:bg-card dark:bg-primary-muted dark:hover:bg-primary/70',
            i === focusedIndex && 'ring-2 ring-primary ring-offset-2',
            readOnly &&
              'cursor-not-allowed bg-secondary border-secondary-foreground/30 hover:bg-secondary hover:border-secondary-foreground/30',
          )}
          on:click={() => !readOnly && (value = option)}
          on:focus={() => !readOnly && (focusedIndex = i)}
        >
          <div class="flex items-center">
            {#if option.icon}
              <Icon icon={option.icon} class="w-5 h-5 mr-2" />
            {/if}
            <span class="text-sm font-medium"
              >{option === null || option === '' || option === undefined
                ? 'n/a'
                : option}</span
            >
          </div>
          {#if isSelected}
            <Icon
              icon="mdi:check-circle"
              class="w-5 h-5 text-primary bg-card rounded-full p-0"
            />
          {/if}
        </Button>
      {/each}
    </div>
  </div>
{/if}
