<script lang="ts">
  import NewContact from './new-contact.svelte';
  import NewAccount from './accounts/new-account.svelte';

  import { appStore } from '$lib/stores/app-store';
  import Contacts from '$lib/pages/contacts/contacts.svelte';
  import Accounts from '$lib/pages/accounts/accounts.svelte';
</script>

<div class="w-full bg-primary-800 max-h-[70vh] relative overflow-scroll">
  {#if $appStore.linkedInAccount}
    {#if $appStore.crmAccount}
      <Accounts crmAccount={$appStore.crmAccount} isPluginMode={true} />
    {:else}
      <NewAccount />
    {/if}
  {:else if $appStore.linkedInContact}
    {#if $appStore.crmContact}
      <Contacts
        crmContact={$appStore.crmContact}
        contact={$appStore.linkedInContact}
        isPluginMode={true}
      />
    {:else}
      <NewContact />
    {/if}
  {/if}
</div>
