<script lang="ts">
  import { Pagination as PaginationPrimitive } from 'bits-ui';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';

  type $$Props = PaginationPrimitive.NextButtonProps;
  type $$Events = PaginationPrimitive.NextButtonEvents;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<PaginationPrimitive.NextButton asChild let:builder>
  <Button
    variant="ghost"
    class={cn('gap-1 pr-2.5', className)}
    builders={[builder]}
    on:click
    {...$$restProps}
  >
    <slot>
      <span>Next</span>
      <Icon icon="mdi:chevron-right" class="w-4 h-4" />
    </slot>
  </Button>
</PaginationPrimitive.NextButton>
