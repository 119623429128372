<script lang="ts">
  import * as Collapsible from '$lib/components/ui/collapsible/index.js';
  import { cn } from '$lib/utils/hanta-utils';
  import type { Route } from '$lib/config';
  import Button from '$lib/components/ui/button/button.svelte';
  import * as Tooltip from '$lib/components/ui/tooltip';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import { appStore } from '$lib/stores/app-store';
  import MenuItem from './navigation/menu-item.svelte';
  import Icon from '@iconify/svelte';
  export let routes: Route[];

  function handleClick(route: Route) {
    if ($appStore.currentModule === route.module) {
      appStore.setCurrentModule(null);
    } else {
      appStore.setCurrentModule(route.module);
      if (route.module === 'dashboard') {
        appStore.openPopup(true);
      }
    }
  }
</script>

{#if $appStore.isCollapsed}
  <nav>
    {#each routes || [] as route}
      {#if route?.children?.length > 0}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild let:builder>
            <Button
              data-testid={route.title}
              builders={[builder]}
              variant={route.module === $appStore.currentModule
                ? 'default'
                : 'ghost'}
              size="icon"
              class={cn(
                'relative w-full ml-2 size-9 pl-2.5',
                'justify-start my-0.5 hover:bg-card',
                route.module === $appStore.currentModule &&
                  'bg-muted text-primary hover:bg-muted/80 border-primary border-l-4 pl-1.5 rounded-none text-primary',
              )}
            >
              <Icon icon={route.icon} class="size-4" aria-hidden="true" />
              <Icon
                icon={builder.open ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                class="size-4 text-primary"
                aria-hidden="true"
              />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content side="right" align="start" class="w-48 ml-2">
            <DropdownMenu.Label>{route.title}</DropdownMenu.Label>
            <DropdownMenu.Separator />
            <DropdownMenu.Group>
              {#each route.children as childRoute}
                <DropdownMenu.Item
                  on:click={() => handleClick(childRoute)}
                  class={cn(
                    'cursor-pointer',
                    childRoute.module === $appStore.currentModule &&
                      'bg-accent',
                  )}
                >
                  <div class="flex items-center w-full">
                    {#if childRoute.icon}
                      <Icon icon={childRoute.icon} class="w-4 h-4 mr-2" />
                    {/if}
                    {childRoute.title}
                    {#if childRoute.label}
                      <span class="ml-auto text-xs text-muted-foreground">
                        {childRoute.label}
                      </span>
                    {/if}
                  </div>
                </DropdownMenu.Item>
              {/each}
            </DropdownMenu.Group>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      {:else}
        <Tooltip.Root openDelay={100}>
          <Tooltip.Trigger asChild let:builder>
            <MenuItem {route} collapsed={true} />
          </Tooltip.Trigger>
          <Tooltip.Content side="right" class="flex gap-4 items-center">
            {route.title}
            {#if route.label}
              <span class="ml-auto text-muted-foreground">
                {route.label}
              </span>
            {/if}
          </Tooltip.Content>
        </Tooltip.Root>
      {/if}
    {/each}
  </nav>
{:else}
  {#each routes || [] as route}
    {#if route?.children?.length > 0}
      <Collapsible.Root class="w-full">
        <Collapsible.Trigger asChild let:builder>
          <Button
            builders={[builder]}
            variant="ghost"
            size="sm"
            class="flex justify-between items-center p-0 w-full"
            data-testid={route.title}
          >
            <div class="flex items-center">
              <Icon
                icon={route.icon}
                class={cn('size-4', 'mr-2', 'ml-3')}
                aria-hidden="true"
              />

              <div class="">{route.title}</div>
            </div>

            <Icon
              icon={builder['data-state'] === 'open'
                ? 'mdi:chevron-up'
                : 'mdi:chevron-down'}
              class="w-4 h-4"
            />

            <span class="sr-only">Toggle</span>
          </Button>
        </Collapsible.Trigger>

        <Collapsible.Content class="pl-4 space-y-1">
          {#each route.children as childRoute}
            <MenuItem route={childRoute} />
          {/each}
        </Collapsible.Content>
      </Collapsible.Root>
    {:else}
      <MenuItem {route} />
    {/if}
  {/each}
{/if}
