import Root from './textarea.svelte';
import type { VariantProps } from 'tailwind-variants';
import { HTMLTextareaAttributes } from 'svelte/elements';
import { tv } from 'tailwind-variants';

const textareaVariants = tv({
  base:
    'flex min-h-[60px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm' +
    'ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none ' +
    'focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed ' +
    'disabled:opacity-50',
  variants: {
    variant: {
      default:
        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background ' +
        'file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground ' +
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ' +
        'disabled:cursor-not-allowed disabled:opacity-50 !px-3',
      hanta:
        'border-none text-sm font-medium p-0 rounded-md outline-none ring-0 hover:bg-muted hover:outline-none hover:ring-0 active:ring-0 ' +
        'active:border-none focus:ring-0 hover:border-b bg-transparent focus:bg-secondary',
      ghost:
        ' focus-visible:ring-0 focus-visible:ring-offset-0 rounded-none border-none' +
        ' px-6 py-2 text-lg font-light outline-none ring-0 hover:none hover:outline-none active:ring-0' +
        ' hover:ring-0 active:ring-0 active:border-none focus:ring-0 hover:border-none bg-transparent' +
        ' focus:bg-secondary focus:ring-0 ',
      ghostOLD:
        'text-lg font-light !px-6 py-2 border-none focus-visible:ring-offset-0 focus-visible:ring-0 focus-visible:outline-none ' +
        'rounded-md outline-none ring-0 hover:bg-muted hover:outline-none hover:ring-0 active:ring-0 ' +
        'active:border-none focus:ring-0 hover:border-b bg-transparent focus:bg-white hover:bg-color-none',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type Variant = VariantProps<typeof textareaVariants>['variant'];

type Props = HTMLTextareaAttributes & {
  variant?: Variant;
};

type TextareaEvents = {
  blur: FormTextareaEvent<FocusEvent>;
  change: FormTextareaEvent<Event>;
  click: FormTextareaEvent<MouseEvent>;
  focus: FormTextareaEvent<FocusEvent>;
  keydown: FormTextareaEvent<KeyboardEvent>;
  keypress: FormTextareaEvent<KeyboardEvent>;
  keyup: FormTextareaEvent<KeyboardEvent>;
  mouseover: FormTextareaEvent<MouseEvent>;
  mouseenter: FormTextareaEvent<MouseEvent>;
  mouseleave: FormTextareaEvent<MouseEvent>;
  paste: FormTextareaEvent<ClipboardEvent>;
  input: FormTextareaEvent<InputEvent>;
};

export {
  Root,
  //
  type Props,
  Root as Textarea,
  type TextareaEvents,
  type FormTextareaEvent,
  textareaVariants,
};
