<script lang="ts">
  import { getPrimaryRoutes, secondaryRoutes } from '$lib/config';
  import SearchWindow from './search-window.svelte';
  import NewItemWindow from './new-item/new-item-window.svelte';
  import Nav from './nav.svelte';
  import Favorites from './navigation/favorites.svelte';
  import HelpMenu from './navigation/help-menu.svelte';
  import * as Sheet from '$lib/components/ui/sheet';
  import PipelinesList from './navigation/pipelines-list.svelte';
  import { Button } from '$lib/components/ui/button';

  import Icon from '@iconify/svelte';
  import AuthUserButton from '$lib/auth/auth-user-button.svelte';
  import AuthOrgButton from '$lib/auth/auth-org-button.svelte';
  import ThemeSwitcher from '$lib/ui/theme-switcher.svelte';
  import { appStore } from '$lib/stores/app-store';
  import SidebarToggle from './sidebar-toggle.svelte';
  import Separator from '$lib/components/ui/separator/separator.svelte';
  import Trash from './navigation/trash.svelte';
  import Tasks from './navigation/tasks.svelte';
  import NewItemNavButton from './navigation/new-item-nav-button.svelte';
</script>

{#if $appStore.isMobile}
  <Sheet.Root>
    <Sheet.Trigger asChild let:builder>
      <div
        class="w-full fixed bottom-0 flex space-x-2 bg-secondary z-[999] justify-around items-center"
      >
        <Button builders={[builder]} variant="ghost">
          <Icon icon="mdi:menu" class="size-5" />
        </Button>
        <SearchWindow />
        <NewItemWindow />

        <AuthOrgButton />
      </div>
    </Sheet.Trigger>
    <Sheet.Content side="bottom" class="bg-secondary z-[999]">
      <Nav routes={getPrimaryRoutes()} />
      <div class="flex justify-between pt-4 mt-4 w-full border-t border-solid">
        <AuthUserButton />
        <ThemeSwitcher />
      </div>
    </Sheet.Content>
  </Sheet.Root>
{:else}
  <div
    class="flex overflow-y-auto flex-col justify-between crmapp bg-background"
    class:w-[58px]={$appStore.isCollapsed}
    class:w-[200px]={!$appStore.isCollapsed}
  >
    <div>
      <SidebarToggle />
      <SearchWindow />

      <NewItemWindow />

      <div class="my-3">
        <Tasks />
        <Nav routes={getPrimaryRoutes()} />
      </div>

      {#if !$appStore.isCollapsed}
        <Separator />
      {/if}
      <PipelinesList />
      {#if !$appStore.isCollapsed}
        <Separator />
      {/if}
      <Favorites />
      <Separator />

      <Trash />
      <!--
      <Nav routes={secondaryRoutes} />
      -->
      <HelpMenu />
    </div>
    <div class="flex flex-col items-start px-2 py-2 mb-12 space-y-2">
      {#if import.meta.env.MODE !== 'plugin'}
        <ThemeSwitcher />
      {/if}
      <Button
        variant="ghost"
        size="icon"
        class={'hidden size-9 my-0.5 dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'}
      >
        <Icon icon="mdi:settings" class="size-4" />
      </Button>

      <AuthOrgButton />
      <AuthUserButton />
    </div>
  </div>
{/if}

<style>
  .crmapp {
    position: fixed;
    left: 0px;
    padding: 1px;
    padding-left: 5px;
    padding-right: 1px;

    z-index: 10000;
    top: 52px;
    height: 100svh;
  }
</style>
