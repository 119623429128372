<script lang="ts">
  import { onMount } from 'svelte';
  import { geoPath, geoMercator } from 'd3-geo';
  import { feature } from 'topojson-client';
  import { scaleThreshold } from 'd3-scale';
  import { fade } from 'svelte/transition';
  import worldMap from './world-countries';

  export let width = 960;
  export let height = 500;
  export let data = {};

  let refreshId = 1;
  let colorRanges: number[] = [];
  let colorScale;

  // Calculate dynamic ranges based on data
  function calculateRanges(data: Record<string, number>) {
    const values = Object.values(data).filter(
      v => v !== undefined && v !== null,
    );
    if (!values.length) return [0, 50, 100, 200, 500, 1000, 10000]; // fallback ranges

    const min = Math.min(...values);
    const max = Math.max(...values);

    // Create 7 ranges between min and max
    const step = (max - min) / 6; // 6 steps for 7 ranges
    return Array.from({ length: 7 }, (_, i) => {
      // Round the numbers to make them more readable
      const value = min + step * i;
      if (value < 100) return Math.round(value);
      if (value < 1000) return Math.round(value / 10) * 10;
      if (value < 10000) return Math.round(value / 100) * 100;
      return Math.round(value / 1000) * 1000;
    });
  }

  const colors = [
    '#f7fbff',
    '#deebf7',
    '#c6dbef',
    '#9ecae1',
    '#6baed6',
    '#4292c6',
    '#2171b5',
  ];

  // Update ranges and color scale when data changes
  $: {
    if (data && Object.keys(data).length) {
      colorRanges = calculateRanges(data);
      colorScale = scaleThreshold().domain(colorRanges).range(colors);
      refreshId++; // Trigger re-render
    }
  }

  let countries;
  let path;
  let tooltip = { visible: false, x: 0, y: 0, country: null, value: null };

  onMount(async () => {
    countries = feature(worldMap, worldMap.objects.countries).features.filter(
      country =>
        country.properties.name !== 'Antarctica' && country.id !== 'AQ',
    );
    const projection = geoMercator().fitSize([width, height], {
      type: 'FeatureCollection',
      features: countries,
    });
    path = geoPath().projection(projection);
  });

  function getCountryColor(countryName) {
    if (!colorScale) return '#ccc';
    const value = data[countryName];
    if (value !== undefined) {
      return colorScale(value);
    }
    return '#ccc'; // Default color for countries with no data
  }

  function showTooltip(event, country) {
    const countryName = country.properties.name;
    const value = data[countryName];
    tooltip = {
      visible: true,
      x: event.clientX,
      y: event.clientY,
      country: countryName,
      value: value !== undefined ? value.toLocaleString() : 'No data',
    };
  }

  function hideTooltip() {
    tooltip.visible = false;
  }
</script>

<div class="map-container">
  <svg {width} {height}>
    {#key refreshId}
      {#if countries}
        {#each countries as country}
          <path
            role="button"
            tabindex="0"
            d={path(country)}
            fill={getCountryColor(country.properties.name)}
            stroke="#fff"
            stroke-width="0.5"
            aria-label={country.properties.name}
            on:mousemove={event => showTooltip(event, country)}
            on:mouseleave={hideTooltip}
            on:keydown={event => {
              if (event.key === 'Enter' || event.key === ' ') {
                showTooltip(event, country);
              }
            }}
            on:blur={hideTooltip}
          />
        {/each}
      {/if}
    {/key}
  </svg>

  {#if tooltip.visible}
    <div
      class="tooltip"
      style="left: {tooltip.x + 10}px; top: {tooltip.y + 10}px;"
      transition:fade={{ duration: 100 }}
    >
      <strong>{tooltip.country}</strong>
      <br />
      Value: {tooltip.value}
    </div>
  {/if}

  <div class="legend">
    {#each colorRanges as range, i}
      <div class="legend-item">
        <div class="color-box" style="background-color: {colors[i]}"></div>
        <span
          >{range.toLocaleString()} - {(
            colorRanges[i + 1] || '∞'
          ).toLocaleString()}</span
        >
      </div>
    {/each}
  </div>
</div>

<style>
  .map-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  svg {
    max-width: 100%;
    height: auto;
  }
  .tooltip {
    position: fixed;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    pointer-events: none;
    z-index: 1000;
  }
  .legend {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 4px;
  }
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .color-box {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
</style>
