<script lang="ts">
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import { DateFormatter } from '@internationalized/date';
  import { VisXYContainer, VisLine, VisAxis } from '@unovis/svelte';

  const dateFormatter = new DateFormatter('en-US', {
    month: 'short',
    year: 'numeric',
  });

  export let chartData: Array<any> = [];
  export let x: (d: any) => any;
  export let yRevenue: (d: any) => number;
  export let yExpenses: (d: any) => number;
  export let yProfit: (d: any) => number;
  export let yCashFlow: (d: any) => number;
  const formatDate = (timestamp: number) =>
    dateFormatter.format(new Date(timestamp));
</script>

<Card class="mt-4">
  <CardHeader>
    <CardTitle>Financial Trends</CardTitle>
  </CardHeader>
  <CardContent>
    <div class="h-[400px]">
      {#if chartData.length > 0}
        <div class="flex space-x-4 mb-4">
          <div class="flex items-center">
            <div
              class="w-4 h-4 bg-green-600 border border-gray-800 rounded mr-2"
            ></div>
            <span>Revenue</span>
          </div>
          <div class="flex items-center">
            <div
              class="w-4 h-4 bg-red-500 border border-gray-800 rounded mr-2"
            ></div>
            <span>Expenses</span>
          </div>
          <div class="flex items-center">
            <div
              class="w-4 h-4 bg-primary border border-gray-800 rounded mr-2"
            ></div>
            <span>Profit</span>
          </div>
          <div class="flex items-center">
            <div
              class="w-4 h-4 bg-accent border border-gray-800 rounded mr-2"
            ></div>
            <span>Cash Flow</span>
          </div>
        </div>
        <VisXYContainer data={chartData} height={400}>
          <VisLine
            {x}
            y={yRevenue}
            name="Revenue"
            color="green"
            strokeWidth={3}
          />
          <VisLine
            {x}
            y={yExpenses}
            name="Expenses"
            color="red"
            strokeWidth={3}
          />
          <VisLine
            {x}
            y={yProfit}
            name="Profit"
            color="black"
            strokeWidth={3}
          />
          <VisLine
            {x}
            y={yCashFlow}
            name="Cash Flow"
            color="gray"
            strokeWidth={3}
          />
          <VisAxis
            type="x"
            label="Date"
            tickFormat={formatDate}
            numTicks={6}
            tickRotate={-45}
            gridVisible={true}
          />
          <VisAxis
            type="y"
            label="Amount"
            tickFormat={d => `$${d.toLocaleString()}`}
            gridVisible={true}
          />
        </VisXYContainer>
      {:else}
        <div
          class="flex items-center justify-center h-full text-muted-foreground"
        >
          No data available for the selected period
        </div>
      {/if}
    </div>
  </CardContent>
</Card>
