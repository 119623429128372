<script lang="ts">
  import { RangeCalendar as RangeCalendarPrimitive } from 'bits-ui';
  import { buttonVariants } from '$lib/components/ui/button';
  import { cn } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';

  type $$Props = RangeCalendarPrimitive.PrevButtonProps;
  type $$Events = RangeCalendarPrimitive.PrevButtonEvents;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<RangeCalendarPrimitive.PrevButton
  on:click
  class={cn(
    buttonVariants({ variant: 'outline' }),
    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
    className,
  )}
  {...$$restProps}
  let:builder
>
  <slot {builder}>
    <Icon icon="mdi:chevron-left" class="w-4 h-4" />
  </slot>
</RangeCalendarPrimitive.PrevButton>
