<script lang="ts">
  import { Clerk } from '@clerk/clerk-js';
  import { onMount } from 'svelte';
  import { authStore } from '$lib/stores/auth-store';
  import { currentUserStore } from '$lib/stores/current-user-store';
  import { clerkStore } from './clerk-store';

  const clerkPublishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
  const clerk = new Clerk(clerkPublishableKey);

  let user;
  onMount(async () => {
    clerkStore.set({ clerk });
    await clerk.load({
      appearance: {
        variables: {
          fontSize: '1.2rem',
        },
      },
    });

    if (clerk.user) {
      console.debug('User is logged in');
      const env = import.meta.env.VITE_SUPABASE_ENVIRONMENT;
      const template =
        import.meta.env[`VITE_CLERK_SUPABASE_TEMPLATE_${env.toUpperCase()}`] ||
        'supabase-production';
      const access_token = await clerk.session.getToken({
        template,
      });

      const { data: memberships } =
        await clerk.user.getOrganizationMemberships();

      const lastOrgId = clerk?.session?.lastActiveOrganizationId;
      const organization = lastOrgId
        ? memberships.find(m => m.organization.id === lastOrgId)
            ?.organization || memberships[0]?.organization
        : memberships[0]?.organization || {};

      const packages =
        clerk?.session?.lastActiveToken?.jwt?.claims?.org_metadata?.packages;
      user = clerk.user;
      authStore.set({ user, token: access_token, organization, packages });

      // Load user data after auth is confirmed and token is set
      await currentUserStore.loadUser();
    } else {
      console.debug('User is not logged in');
      authStore.set({
        user: undefined,
        token: undefined,
        organization: undefined,
        packages: undefined,
      });
      currentUserStore.logout();
    }

    // Set up auth state change listener
    clerk.addListener(event => {
      if (event.user) {
        // User signed in
        currentUserStore.loadUser();
      } else {
        // User signed out
        currentUserStore.logout();
      }
    });

    if (!clerk.user) {
      clerk.openSignIn({});
    }
  });
</script>

<div id="sign-in" />
{#if !!user}
  <slot />
{/if}
<pre class="hidden mt-24 ml-24">{JSON.stringify(user, null, 2)}</pre>
