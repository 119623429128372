<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils/hanta-utils';
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import { newItemStore } from '$lib/stores/new-item-store';
  import * as Tooltip from '$lib/components/ui/tooltip';

  export let collapsed = false;

  function createNewItem() {
    if ($appStore.currentModule) {
      newItemStore.createItem($appStore.currentModule);
    }
    newItemStore.openDialog();
  }
</script>

{#if collapsed}
  <Tooltip.Root openDelay={100}>
    <Tooltip.Trigger asChild let:builder>
      <Button
        on:click={createNewItem}
        variant="ghost"
        size="icon"
        class={cn(
          'relative w-full ml-2 size-9 pl-2.5',
          'justify-start my-0.5 hover:bg-card',
        )}
        data-testid="nav-new-item"
      >
        <Icon icon="mdi:plus" class="size-4" aria-hidden="true" />
      </Button>
    </Tooltip.Trigger>
    <Tooltip.Content side="right" class="flex gap-4 items-center">
      Create New
      <span class="ml-auto text-xs text-muted-foreground">⌘K</span>
    </Tooltip.Content>
  </Tooltip.Root>
{:else}
  <Button
    on:click={createNewItem}
    variant="ghost"
    size="icon"
    data-testid="nav-new-item"
  >
    <Icon icon="mdi:plus" class="size-4 text-primary" aria-hidden="true" />
    <span class="sr-only">Create New</span>
    <span class="ml-auto text-xs text-muted-foreground hidden">⌘K</span>
  </Button>
{/if}
