<script lang="ts">
  import { generateUUID } from '$lib/utils/browser-utils';
  import Icon from '@iconify/svelte';

  import * as Command from '$lib/components/ui/command';
  import * as Popover from '$lib/components/ui/popover';
  import { Button } from '$lib/components/ui/button';
  import { cn } from '$lib/utils/hanta-utils';
  import { afterUpdate, beforeUpdate, tick } from 'svelte';
  import { Input } from '../ui/input';

  type $$Props = {
    type: 'text' | 'password';
    value: any;
    class?: string;
  };

  let open = false;
  let posChanged = false;
  export let value = '';
  export let type = 'text';

  let className: $$Props['class'] = 'w-full';
  export { className as class };

  // We want to refocus the trigger button when the user selects
  // an item from the list so users can continue navigating the
  // rest of the form with the keyboard.
  function closeAndFocusTrigger(triggerId: string) {
    open = false;
    posChanged = false;
    tick().then(() => {
      document.getElementById(triggerId)?.focus();
      posChanged = false;
    });
  }

  let popoverId = generateUUID();
  let hostElem: HTMLDivElement;

  $: if (open) {
    tick().then(() => {
      let elementById = document.getElementById(popoverId);
      console.debug({ popoverId, elementById });
      let boundingClientRect = hostElem?.getBoundingClientRect();

      elementById?.setAttribute(
        'style',
        `width: ${boundingClientRect?.width}px;`,
      );
      posChanged = true;
    });
  }
</script>

<div bind:this={hostElem} class={cn(className)}>
  <Popover.Root bind:open let:ids>
    <Popover.Trigger asChild let:builder>
      <Button
        aria-expanded={open}
        builders={[builder]}
        class={cn('justify-between group h-auto bg-transparent w-full')}
        role="combobox"
        variant="outline"
      >
        <div class="h-auto truncate">{value}</div>

        <Icon
          icon="mdi:pencil"
          class={cn(
            `w-4 h-4 ml-2 opacity-0 shrink-0 group-hover:opacity-100`,
            open && 'opacity-100',
          )}
        />
      </Button>
    </Popover.Trigger>
    <Popover.Content class={cn('p-0 my-popover')} id={popoverId}>
      <Command.Root>
        <Command.Group>
          <Command.Item
            class="flex space-x-2"
            on:click={() => {
              closeAndFocusTrigger(ids.trigger);
            }}
          >
            {#if $$slots.default}
              <slot />
            {:else}
              <Input
                {type}
                bind:value
                class="w-full"
                on:keydown={e => {
                  if (e.key === 'Enter') {
                    open = false;
                  }
                }}
              />
            {/if}
          </Command.Item>
        </Command.Group>
      </Command.Root>
    </Popover.Content>
  </Popover.Root>
</div>
