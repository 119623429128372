<script lang="ts">
  export let rate;
</script>

{#if rate}
  <div
    class="flex justify-between p-6 my-4 rounded-lg shadow-md bg-card"
  >
    <div class="w-1/2">
      <h3 class="mb-4 text-xl font-semibold">Rate Details</h3>
      <div class="grid grid-cols-1 gap-4">
        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Total EUR:</strong>
          <span class="">{rate.totalEUR} €</span>
        </div>
        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Total National:</strong>
          <span class="">{rate.totalNational ?? 0} MDL</span>
        </div>
        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Customer Price:</strong>
          <span class="">{rate.customerPrice ?? 0} €</span>
        </div>
        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Revenue EUR:</strong>
          <span class="">{rate.revenueEur ?? 0} €</span>
        </div>
        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Supplier EUR:</strong>
          <span class="">{rate.supplierEur ?? 0} €</span>
        </div>

        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Discount EUR:</strong>
          <span class="">{rate.discountEur ?? 0} €</span>
        </div>

        <div class="flex items-center">
          <strong class="w-40 text-gray-600">Exchange Rate:</strong>
          <span class="">{rate.exchangeRate ?? 0}</span>
        </div>
      </div>
    </div>

    <div class="w-1/2">
      <h4 class="mt-6 mb-2 text-lg font-semibold">
        Additional Services <span class="text-sm text-foreground/30"
          >included in rate</span
        >
      </h4>
      <div class="grid grid-cols-2 gap-2">
        {#each rate?.services || [] as service}
          <div
            class="p-4 my-2 rounded-lg border transition-all duration-300 hover:shadow-md hover:bg-card/10"
          >
            <h5 class="mb-2 text-xl font-semibold text-gray-700 text-md">
              {service.product}
            </h5>
            <div class="grid grid-cols-1 gap-2">
              <div class="flex items-center">
                <strong class="w-32 text-gray-600">Price:</strong>
                <span class="">{service.price} {service.dealCurrency}</span>
              </div>
              <div class="flex items-center">
                <strong class="w-32 text-gray-600">Weight/Price:</strong>
                <span class="">{service.weight}</span>
              </div>

              <div class="flex items-center">
                <strong class="w-32 text-gray-600">Revenue:</strong>
                <span class=""
                  >{service.revenueEur} € ({service.revenuePercents *
                    100}%)</span
                >
              </div>
              <div class="flex items-center">
                <strong class="w-32 text-gray-600">Supplier:</strong>
                <span class=""
                  >{service.supplierEur} € ({service.supplierPercents *
                    100}%)</span
                >
              </div>
              {#if service.week}
                <div class="flex items-center">
                  <strong class="w-32 text-gray-600">Week:</strong>
                  <span class="">{service.week ?? ''}</span>
                </div>
              {/if}
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
{/if}
