<script lang="ts">
  import * as Command from '$lib/components/ui/command';
  import Icon from '@iconify/svelte';
  import { createItem, createShipment } from '$lib/api/mutations';
  import { getPrimaryRoutes } from '$lib/config';
  import NewItemForm from './new-item-form.svelte';
  import { authStore } from '$lib/stores/auth-store';
  import { appStore } from '$lib/stores/app-store';
  import { newItemStore } from '$lib/stores/new-item-store';

  $: ({ open, insertMode, module } = $newItemStore);

  async function createNewItem(event) {
    const { country, ...item } = event.detail;
    try {
      const crmModule = module === 'candidates' ? 'contacts' : module;
      const data = await createItem(crmModule, item);

      if (module === 'leads' || module === 'orders') {
        await createShipment(data[0].id, module, country);
      }

      appStore.select({ id: data[0].id, module: crmModule });
      appStore.openPopup(true);
      newItemStore.closeDialog();
    } catch (error) {
      console.error(error);
    }
  }

  function create(moduleName: string) {
    newItemStore.createItem(moduleName);
  }

  function flattenRoutes(routes) {
    return routes.reduce((acc, route) => {
      acc.push(route);
      if (route.children) {
        acc.push(...flattenRoutes(route.children));
      }
      return acc;
    }, []);
  }

  $: allRoutes = flattenRoutes(getPrimaryRoutes());
  $: currentModuleRoutes = allRoutes.filter(
    route => route.module === $appStore.currentModule,
  );

  function handleDialogChange(isOpen: boolean) {
    if (!isOpen) {
      newItemStore.closeDialog();
    }
  }
</script>

<Command.Dialog {open} onOpenChange={handleDialogChange} class="w-full pr-12">
  {#if insertMode}
    <NewItemForm
      {module}
      data-testid-title="title-input"
      data-testid-description="description-input"
      data-testid-save="save-button"
      data-testid-cancel="cancel-button"
      data-testid-error="error-message"
      on:submit={createNewItem}
      on:cancel={() => newItemStore.closeDialog()}
    />
  {:else}
    <Command.Input
      data-testid="search-input"
      placeholder="Select from list or type here for search..."
    />
    <Command.List>
      <Command.Empty>No results found.</Command.Empty>
      <Command.Group data-testid="suggestions-section" heading="Suggestions">
        {#if $appStore.currentModule && currentModuleRoutes.length > 0}
          {#each currentModuleRoutes as route}
            {#if route.module !== 'dashboard'}
              <Command.Item onSelect={() => create(route.module)}>
                <Icon
                  icon={route.icon}
                  class="mr-2 w-4 size-4"
                  aria-hidden="true"
                />
                <span>New record in <b>{route.title}</b></span>
              </Command.Item>
            {/if}
          {/each}
        {/if}
      </Command.Group>
      <Command.Group data-testid="crm-objects-section" heading="CRM Objects">
        {#if authStore.isCRM()}
          <Command.Item
            data-testid="account-option"
            onSelect={() => create('accounts')}
          >
            <Icon icon="mdi:building" class="mr-2 w-4 h-4" />
            <span>Account</span>
          </Command.Item>
        {/if}
        {#if authStore.isLogistics()}
          <Command.Item onSelect={() => create('invoices')}>
            <Icon icon="mdi:euro" class="mr-2 w-4 h-4" />
            <span>Invoice</span>
          </Command.Item>
          <Command.Item onSelect={() => create('contracts')}>
            <Icon icon="mdi:section" class="mr-2 w-4 h-4" />
            <span>Contract</span>
          </Command.Item>
        {/if}
        <Command.Item
          data-testid="contact-option"
          onSelect={() => create('contacts')}
        >
          <Icon icon="mdi:account-group" class="mr-2 w-4 h-4" />
          <span>Contact</span>
        </Command.Item>
        {#if authStore.isHeadhunter()}
          <Command.Item onSelect={() => create('candidates')}>
            <Icon icon="mdi:account-group" class="mr-2 w-4 h-4" />
            <span>Candidate</span>
          </Command.Item>

          <Command.Item onSelect={() => create('deals')}>
            <Icon icon="fluent:people-search-20-filled" class="mr-2 w-4 h-4" />
            <span>Deal</span>
          </Command.Item>
        {/if}
        {#if authStore.isLogistics()}
          {@const modules = ['leads', 'orders', 'issues']}
          {#each modules as module}
            {@const route = allRoutes.find(r => r.module === module)}
            <Command.Item onSelect={() => create(module)}>
              <Icon icon={route.icon} class="mr-2 w-4 h-4" />
              <span>{route.title}</span>
            </Command.Item>
          {/each}
        {/if}
        <Command.Separator />
        {#if authStore.isHeadhunter()}
          <Command.Item onSelect={() => create('assignments')}>
            <Icon icon="mdi:send" class="mr-2 w-4 h-4" />
            <span>Assignment</span>
          </Command.Item>
        {/if}
      </Command.Group>
    </Command.List>
  {/if}
</Command.Dialog>
