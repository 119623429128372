<script lang="ts">
  import * as Card from '$lib/components/ui/card';
  import PhasesList from './phases-list.svelte';
  import KanbanHint from './kanban-hint.svelte';
  import type { Phase } from '$lib/types/pipeline';
  import type { Pipeline } from '$lib/types/pipeline';
  import PipelinePhaseListItems from './pipeline-phase-list-items.svelte';
  import { addPipelineItem } from '$lib/api/pipeline-api';

  export let pipeline: Pipeline;
  export let isPreviewMode = false;
  export let onPhaseAdd: () => void;
  export let onPhaseRemove: (id: string) => void;
  export let onPhaseReorder: (phases: Phase[]) => void;
  export let onSavePipeline: () => void;
  export let onChange: (phase: Phase, items: any[]) => void;

  let phases = pipeline.phases || [];
  let onChanges = (
    phase: Phase,
    e: { items: any[]; added: any[]; removed: any[] },
  ) => {
    console.log('onChanges ', phase?.name, e);
    phase.items = e.items;
    if (e.added.length) {
      onChange(phase, e.items);
    }
  };

  async function onAddItem(phase: Phase, item: any) {
    if (pipeline.modifySource) {
      pipeline.phases.find(p => p.id === phase.id)?.items.push(item);
    } else {
      // insert into pipelineitems
      await addPipelineItem(phase.id, pipeline.module, {accountId: item});
    }
  }
</script>

<div class="hidden">
  <KanbanHint />
</div>
<Card.Root>
  <Card.Content>
    <div class="flex flex-row gap-4 h-screen">
      {#if isPreviewMode}
        {#each phases || [] as phase}
          <PipelinePhaseListItems
            items={phase.items}
            {pipeline}
            {phase}
            on:changes={e => onChanges(phase, e.detail)}
            on:add={e => onAddItem(phase, e.detail)}
          />
        {/each}
      {:else}
        <div class="p-4">
          <PhasesList
            {pipeline}
            {isPreviewMode}
            {onPhaseRemove}
            {onPhaseReorder}
            {onPhaseAdd}
            {onSavePipeline}
          />
        </div>
      {/if}
    </div>
  </Card.Content>
</Card.Root>
