<script>
  import { confetti } from '@neoconfetti/svelte';

  import { getActivity } from '$lib/api/queries';
  import Badge from '$lib/components/ui/badge/badge.svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { appStore } from '$lib/stores/app-store';

  export let assignment = undefined;
  export let phases = [];
  export let currentPhase;

  let localPhase;
  let showConfetti = false;

  $: currentPhaseIndex = phases.indexOf(localPhase || currentPhase);

  async function openAssignment() {
    console.debug('openAssignment', assignment);
    const item = await getActivity(assignment?.id);
    assignment && appStore.openActivity(item);
  }
</script>

<div class="w-full">
  <div class="flex items-stretch space-x-2">
    <!--
      on:click|stopPropagation|preventDefault={() => {
          if (localPhase === 'Contract!' && currentPhase !== 'Contract!') {
            showConfetti = true;
          }
          currentPhase = phase;
        }}
        on:focus={() => (localPhase = phase)}
        on:mouseover={() => (localPhase = phase)}
    -->
    {#each phases as phase, idx}
      <button
        tabindex="-1"
        on:click={openAssignment}
        class={cn('w-full h-2 rounded hover:bg-blue-300', {
          'bg-primary/10': currentPhaseIndex < idx,
          'bg-primary': currentPhaseIndex >= idx,
        })}
      />
    {/each}
  </div>
  {#if localPhase || currentPhase}
    {@const phaseToShow = localPhase || currentPhase}

    <Badge
      class="mt-2 rounded-none"
      variant={phaseToShow === 'Refused'
        ? 'destructive'
        : phaseToShow === 'Contract!'
          ? 'default'
          : 'secondary'}
    >
      <div class="text-sm">{localPhase || currentPhase}</div>
    </Badge>
  {/if}
  {#if showConfetti}
    <div use:confetti={{ particleCount: 200, force: 0.3 }} />
  {/if}
</div>
