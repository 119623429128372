import { writable } from 'svelte/store';

type NewItemState = {
  open: boolean;
  insertMode: boolean;
  module: string;
};

function createNewItemStore() {
  const { subscribe, set, update } = writable<NewItemState>({
    open: false,
    insertMode: false,
    module: '',
  });

  return {
    subscribe,
    openDialog: () => update(state => ({ ...state, open: true })),
    closeDialog: () => set({ open: false, insertMode: false, module: '' }),
    createItem: (moduleName: string) => 
      update(state => ({ ...state, insertMode: true, module: moduleName })),
  };
}

export const newItemStore = createNewItemStore();
