<script lang="ts">
  import HantaInputText from '$lib/widgets/input/hanta-input-text.svelte';
  import HantaInputSelect from '$lib/widgets/input/hanta-input-select.svelte';
  import HantaInputTextArea from '$lib/widgets/input/hanta-input-textarea.svelte';
  import HantaInputTextLean from '$lib/widgets/input/hanta-input-text-lean.svelte';

  export let form;
  const { form: formData } = form;

  export let isLocked;
</script>

<div
  class="flex flex-col gap-4 p-6 mb-6 text-left bg-opacity-50 rounded border border-solid shadow-xs bg-primary-800"
>
  <div class="flex flex-row gap-4 items-baseline">
    {#if $formData.delivery === 'E-Mail'}
      <div class="flex flex-row gap-2 items-baseline w-full">
        <HantaInputText
          label="Subject"
          class=" w-full"
          {form}
          name="subject"
          placeholder="Enter subject..."
        />
      </div>
    {:else}
      <div class="w-full text-lg font-semibold tracking-tight leading-none">
        Introduction
      </div>
    {/if}

    <HantaInputSelect
      {form}
      readOnly={isLocked}
      name="delivery"
      options={[
        { label: 'Letter', value: 'Letter' },
        { label: 'E-Mail', value: 'E-Mail' },
      ]}
    />
  </div>
  <HantaInputTextArea
    {form}
    name="introduction"
    placeholder="Please write the cover letter here..."
    readOnly={isLocked}
  />
</div>
