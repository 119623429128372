<script lang="ts">
  import Icon from '@iconify/svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import HantaInputCheckbox from '$lib/widgets/input/hanta-input-checkbox.svelte';
  import HantaInputDate from '$lib/widgets/input/hanta-input-date.svelte';

  export let form;
  export let readOnly = false;
  export let simpleMode = false;
  let { form: formData, enhance, errors } = form;
</script>

<div class="p-6 rounded-lg shadow-md bg-card">
  <h2 class="flex items-center mb-4 text-2xl font-semibold">
    <Icon icon="mdi:truck" class="mr-2" />
    Shipping Details
  </h2>

  {#if $formData.shipment}
    <div class="space-y-4">
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.country}
        label="Country"
        type="Countries"
        multiple={false}
        {readOnly}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.operator}
        label="Operator"
        type="Deals > Operator"
        multiple={false}
        {readOnly}
        renderAs="buttons"
        optionsPerRow={3}
      />
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.direction}
        label="Direction"
        type="Deals > Direction"
        multiple={false}
        {readOnly}
        renderAs="buttons"
        optionsPerRow={3}
      />
      {#if !simpleMode}
        <HantaInputLovSelect
          {form}
          bind:value={$formData.shipment.paymentSide}
          label="Payment Side"
          type="Deals > PaidSide"
          multiple={false}
          {readOnly}
          renderAs="buttons"
          optionsPerRow={3}
        />
      {/if}
      <HantaInputLovSelect
        {form}
        bind:value={$formData.shipment.product}
        label="Product"
        type="Deals > Products"
        multiple={false}
        {readOnly}
        renderAs="buttons"
        optionsPerRow={4}
      />
      {#if !simpleMode}
        <HantaInputLovSelect
          {form}
          bind:value={$formData.shipment.paymentMethod}
          name="paymentMethod"
          label="Payment Method"
          type="Deals > Payment"
          multiple={false}
          {readOnly}
          renderAs="buttons"
          optionsPerRow={2}
        />
      {/if}
      {#if $formData.shipment.operator === 'FedEx'}
        <HantaInputLovSelect
          {form}
          bind:value={$formData.shipment.packageType}
          label="Package Type"
          type="Deals > Packages"
          multiple={false}
          {readOnly}
        />
      {/if}
      <HantaInputCheckbox
        {form}
        label="Security Fee"
        bind:value={$formData.shipment.securityFee}
        {readOnly}
      />
      <HantaInputCheckbox
        {form}
        label="Fuel Fee"
        bind:value={$formData.shipment.fuelFee}
        {readOnly}
      />
      <HantaInputDate
        {form}
        name="shipment.date"
        label="Shipment Date"
        {readOnly}
      />
    </div>
  {/if}
</div>
