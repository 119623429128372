<script lang="ts">
  import type { HTMLTdAttributes } from 'svelte/elements';
  import { cn } from '$lib/utils/hanta-utils.js';

  type $$Props = HTMLTdAttributes;

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<td
  class={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</td>
