<script lang="ts">
  import { type ActivityWithRefs, activityWithRefsSchema } from '$db/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveActivity } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import { alertStore } from '$lib/stores/alert-store';
  import Icon from '@iconify/svelte';
  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import ActivityFormContent from './activity-form-content.svelte';
  import { getIconForActivityType } from '$lib/config/crm-config';

  export let activity: ActivityWithRefs;
  export let type = 'about';

  export let onUpdateActivity = newActivity => {};

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<ActivityWithRefs> =>
      Promise.resolve(saveActivity(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['activities'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['activities', activity.id] });
      const previousItems = client.getQueryData<ActivityWithRefs[]>([
        'activities',
        activity.id,
      ]);

      client.setQueryData(['activities', activity.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['activities'], context.previousItems);
      }
      alertStore.show(
        'Error Saving Activity',
        err.message || 'Failed to save activity information',
        undefined,
        () => {
          $addMutation.mutate(variables);
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['activities', activity.id] });
    },
  });

  const form = superForm<ActivityWithRefs>(activity, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(activityWithRefsSchema),
    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        alertStore.show(
          'Validation Error',
          'Please check the form for errors',
          undefined,
          () => {},
        );
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);
      $addMutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
          alertStore.show(
            'Error Saving Activity',
            error.message || 'Failed to save activity information',
            undefined,
            () => {
              $addMutation.mutate($formData);
            },
          );
        },
        onSuccess: activity => {
          onUpdateActivity(activity);
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;
</script>

<form
  class="space-y-6 w-full"
  method="POST"
  use:enhance
  data-testid="activity-form"
>
  {#if type === 'about'}
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center">
          <Icon icon={getIconForActivityType(activity.type)} class="mr-2" />
          Activity Details
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ActivityFormContent
          {activity}
          {form}
          onClose={() => {}}
          showButtons={false}
        />
      </CardContent>
    </Card>
  {/if}

  <FormActions {form} data-testid="activity-form-actions" />
</form>
