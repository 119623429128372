<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import ActivityForm from './activity-form.svelte';
  import Assignment from './activity-assigment-popup.svelte';

  $: type =
    $appStore.openActivity &&
    $appStore.currentActivity &&
    $appStore.currentActivity.type;

  $: activity = $appStore.currentActivity;
</script>

{#if activity}
  {#if type === 'Assignment'}
    <Assignment {activity} />
  {:else}
    <ActivityForm {activity} {type} />
  {/if}
{/if}
