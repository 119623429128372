<script lang="ts">
  import { cn } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';
  export let communication;
  export let isPrivate = false;

  let className = undefined;
  export { className as class };
</script>

<dl class={cn('', className ?? '')}>
  {#each Object.entries(communication ?? {}) as [key, value]}
    {#if value && key !== 'values'}
      <div class="flex items-baseline space-x-1">
        <dd class="text-sm font-normal text-primary">
          {#if key === 'email' || key === 'secondaryEmail'}
            <a href={`mailto:${value}?bcc=crm@hanta.io`}>{value}</a>
          {:else if key === 'values'}
            <a href={`${value}`} target="_blank">
              <Icon icon="mdi:list-check" class="size-4" />
            </a>
          {:else}
            {value}
          {/if}
        </dd>
        <dt class="text-xs font-thin">
          {key}
          {isPrivate ? ' / private' : ' / work'}
        </dt>
      </div>
    {/if}
  {/each}
</dl>
