export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

interface Result {
  vorname: string;
  name: string;
  [key: string]: any;
}

function copyToClipboard(callback: (result: Result) => void): void {
  const resultString = localStorage.getItem('result');
  if (!resultString) return;
  
  const result = JSON.parse(resultString) as Result;
  if (result && result.vorname && result.name) {
    callback(result);
  }
}

export function fallbackUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function generateUUID(): string {
  if (window.crypto && typeof window.crypto.randomUUID === 'function') {
    return window.crypto.randomUUID();
  } else {
    return fallbackUUID();
  }
}

export function scrollToSmoothly(pos: number | string, time?: number): void {
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    console.warn('Position must be a number or a numeric String.');
    throw 'Position must be a number';
  }
  if (pos < 0 || (time !== undefined && time < 0)) {
    return;
  }
  
  const currentPos = window.scrollY || window.screenTop;
  let start: number | null = null;
  time = time || 500;

  window.requestAnimationFrame(function step(currentTime: number): void {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      const progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      const progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}
