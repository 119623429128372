<script lang="ts">
  import { Input } from '$lib/components/ui/input/index.js';
  import * as Card from '$lib/components/ui/card';
  import { cn } from '$lib/utils/hanta-utils';
  import { writable } from 'svelte/store';
  import Activities from '../activities/activities.svelte';
  import { tabs } from '$lib/config/crm-config';
  import HantaInputMultiSelectLean from '$lib/widgets/input/hanta-input-multi-select-lean.svelte';

  type $$Props = {
    class?: string;
  };

  let filterValue = writable('');
  let type = {
    value: 'all',
    label: 'All',
  };

  const loadOptions = async (keyword: string): Promise<any> => {
    return tabs.filter(tab =>
      tab.label.toLowerCase().includes(keyword.toLowerCase()),
    );
  };

  let className: $$Props['class'] = undefined;
  export { className as class };
</script>

<Card.Root class={cn('h-full overflow-hidden flex flex-col', className)}>
  <Card.Header
    class="flex relative flex-row justify-between space-y-0 border-b border-solid"
  >
    <Card.Title class="hidden gap-2 items-center md:flex">Timeline</Card.Title>

    <div class="">
      <HantaInputMultiSelectLean
        class="w-64"
        bind:value={type}
        {loadOptions}
        multiple={false}
        placeholder="Filter by Type"
      >
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:index
          let:item
          slot="item"
        >
          {item.label}
        </div>
        <div
          class="flex overflow-hidden items-center w-full h-full"
          let:selection
          slot="selection"
        >
          {selection.label}
        </div>
      </HantaInputMultiSelectLean>
    </div>
  </Card.Header>
  <Card.Content
    class="flex overflow-scroll relative flex-col gap-2 p-1 h-full md:p-6"
  >
    <div class="h-full">
      <Activities
        module="dashboard"
        search={$filterValue}
        type={type.value}
        withSearch={false}
        withTabs={false}
      />
    </div>
  </Card.Content>
  <Card.Footer
    class="gap-4 justify-between pt-1 pb-1.5 border-t border-solid shadow-sm border-gray 200 bg-primary-800"
  >
    <Input
      bind:value={$filterValue}
      class="pl-2 w-full"
      placeholder="Filter activities"
      type="text"
    />
  </Card.Footer>
</Card.Root>
