<script lang="ts">
  import { createQuery, useQueryClient } from '@tanstack/svelte-query';
  import {
    getTrashItems,
    restoreItem,
    deleteItemPermanently,
    emptyTrash,
  } from '$lib/api/trash-api';
  import { appStore } from '$lib/stores/app-store';
  import * as Command from '$lib/components/ui/command';
  import * as AlertDialog from '$lib/components/ui/alert-dialog';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { getPrimaryRoutes } from '$lib/config/routes';

  let isOpen = false;
  let showEmptyConfirm = false;
  const queryClient = useQueryClient();

  const trashQuery = createQuery({
    queryKey: ['trash'],
    queryFn: () => getTrashItems(),
  });

  const refreshQueries = () => {
    queryClient.invalidateQueries({ queryKey: ['trash'] });
  };

  async function handleRestore(item: any) {
    await restoreItem(item.module, item.id);
    refreshQueries();
  }

  async function handleDelete(item: any) {
    await deleteItemPermanently(item.module, item.id);
    refreshQueries();
  }

  async function handleEmptyTrash() {
    try {
      await emptyTrash();
      refreshQueries();
      showEmptyConfirm = false;
    } catch (error) {
      console.error('Error emptying trash:', error);
    }
  }

  // Get icon based on item type
  function getItemIcon(type: string) {
    return getPrimaryRoutes().find(route => route.module === type)?.icon;
  }

  function openItem({ module, id }) {
    appStore.select({
      module,
      id,
    });
    appStore.openPopup(true);
    isOpen = false;
  }
</script>

<!-- Trigger Button -->
<div class="my-2">
  <button
    class="flex relative gap-2 items-center px-2 pt-5 pb-4 w-full text-sm rounded-md hover:bg-muted group"
    on:click={() => (isOpen = true)}
  >
    <div class="flex gap-2 items-center">
      <Icon
        icon="mdi:trash-can"
        class={cn(
          'text-muted-foreground size-5',
          $appStore.isCollapsed ? 'ml-2' : '',
        )}
      />
      {#if !$appStore.isCollapsed}
        <span>Trash</span>
      {/if}
    </div>

    {#if $trashQuery.data?.length > 0}
      <div
        class={cn(
          'absolute flex items-center justify-center',
          $appStore.isCollapsed ? 'right-1 top-1' : 'right-2',
          'min-w-[20px] h-5 rounded-full bg-muted px-1 text-xs',
          'group-hover:bg-muted-foreground/20',
        )}
      >
        {$trashQuery.data.length}
      </div>
    {/if}
  </button>
</div>

<Command.Dialog bind:open={isOpen}>
  <Command.Input placeholder="Search trash items..." />
  <Command.List class="max-h-[400px] overflow-y-auto">
    <Command.Empty>No trash items found.</Command.Empty>

    {#if $trashQuery.isLoading}
      <Command.Loading>Loading trash items...</Command.Loading>
    {:else if $trashQuery.isError}
      <Command.Item>Error loading trash items</Command.Item>
    {:else if $trashQuery.data?.length === 0}
      <Command.Item>Trash is empty</Command.Item>
    {:else}
      <Command.Group heading="Recently Deleted">
        {#each $trashQuery.data || [] as item}
          <Command.Item
            class="flex justify-between items-center cursor-pointer"
            onSelect={() => openItem(item)}
          >
            <div class="flex gap-2 items-center">
              <Icon
                icon={getItemIcon(item.module)}
                class="text-muted-foreground size-4"
              />
              <span>{item.name}</span>
              <span class="text-xs text-muted-foreground">
                {item.module}
              </span>
            </div>
            <div class="flex gap-2">
              <button
                class="p-1 rounded hover:bg-primary/10 hover:text-primary"
                on:click|stopPropagation={() => handleRestore(item)}
              >
                <Icon icon="mdi:restore" class="size-4" />
                <span class="sr-only">Restore</span>
              </button>
              <button
                class="p-1 rounded hover:bg-destructive/10 hover:text-destructive"
                on:click|stopPropagation={() => handleDelete(item)}
              >
                <Icon icon="mdi:delete-forever" class="size-4" />
                <span class="sr-only">Delete permanently</span>
              </button>
            </div>
          </Command.Item>
        {/each}
      </Command.Group>
    {/if}
  </Command.List>

  {#if $trashQuery.data?.length > 0}
    <div class="flex justify-between items-center p-2 border-t">
      <span class="text-sm text-muted-foreground">
        {$trashQuery.data.length} item{$trashQuery.data.length === 1 ? '' : 's'}
      </span>
      <button
        class="text-sm text-destructive hover:text-destructive/80"
        on:click={() => (showEmptyConfirm = true)}
      >
        Empty Trash
      </button>
    </div>
  {/if}
</Command.Dialog>

<AlertDialog.Root bind:open={showEmptyConfirm}>
  <AlertDialog.Content class="z-[60]">
    <AlertDialog.Header>
      <AlertDialog.Title>Empty Trash?</AlertDialog.Title>
      <AlertDialog.Description>
        This will permanently delete all items in the trash. This action cannot
        be undone.
      </AlertDialog.Description>
    </AlertDialog.Header>
    <AlertDialog.Footer>
      <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
      <AlertDialog.Action asChild>
        <Button variant="destructive" on:click={handleEmptyTrash}>
          Empty Trash
        </Button>
      </AlertDialog.Action>
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
