<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { quill } from './quill';
  import './styles.css';

  export let data = '';
  export let options = {};

  const dispatch = createEventDispatcher();
  let editor;
  let container;
  let toolbar;

  function handleSelectionChange(range) {
    if (toolbar) {
      if (range) {
        toolbar.classList.add('ql-toolbar-active');
      } else {
        toolbar.classList.remove('ql-toolbar-active');
      }
    }
  }

  onMount(() => {
    const cleanup = quill(container, data, {
      theme: 'snow',
      ...options,
    });

    editor = container.querySelector('.ql-editor');
    toolbar = container.querySelector('.ql-toolbar');

    const quillInstance = container.__quill;
    if (quillInstance) {
      quillInstance.on('selection-change', handleSelectionChange);
    }

    container.addEventListener('text-change', event => {
      dispatch('text-change', event.detail);
    });

    return () => {
      cleanup();
      if (quillInstance) {
        quillInstance.off('selection-change', handleSelectionChange);
      }
      container.removeEventListener('text-change', dispatch);
    };
  });
</script>

<div bind:this={container} class="quill-editor" />

<style>
  .quill-editor {
    width: 100%;
    height: 100%;
  }
</style>
