<script lang="ts">
  import * as Accordion from '$lib/components/ui/accordion';
</script>

<Accordion.Root class="w-full sm:max-w-[70%]">
  <Accordion.Item value="item-1">
    <Accordion.Trigger>Hint</Accordion.Trigger>
    <Accordion.Content>
      <div
        class="p-6 mb-4 w-full max-w-4xl rounded-lg shadow-lg bg-primary-800"
      >
        <div class="">
          <h3 class="mb-2 font-semibold">Using the Mouse:</h3>
          <p class="mb-4">
            Hold <kbd class="px-2 py-1 rounded bg-primary-200">Ctrl</kbd>
            (Windows/Linux) or
            <kbd class="px-2 py-1 rounded bg-primary-200">Cmd</kbd> (Mac) and click
            to add items for dragging. Drag them to the new location after selection.
          </p>

          <h3 class="mb-2 font-semibold">Using the Keyboard:</h3>
          <p class="mb-2">
            Tab to navigate. Use <kbd class="px-2 py-1 rounded bg-primary-200"
              >Ctrl + Shift</kbd
            >
            or <kbd class="px-2 py-1 rounded bg-primary-200">Cmd + Shift</kbd>
            to select multiple items. Press
            <kbd class="px-2 py-1 rounded bg-primary-200">Space</kbd> to initiate
            drag mode for moving items.
          </p>
          <p class="mb-2">
            During drag mode, use <kbd class="px-2 py-1 rounded bg-primary-200"
              >Tab</kbd
            >
            to move between potential drop locations.
          </p>
          <p>
            Press <kbd class="px-2 py-1 rounded bg-primary-200">Enter</kbd> to
            complete the move action, or
            <kbd class="px-2 py-1 rounded bg-primary-200">Esc</kbd> to cancel.
          </p>
        </div>
      </div>
    </Accordion.Content>
  </Accordion.Item>
</Accordion.Root>
