<script lang="ts">
  import { Button } from '$lib/components/ui/button';
  import {
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
  } from '$lib/components/ui/dropdown-menu';
  import Icon from '@iconify/svelte';

  export let filter: any;
  export let onTogglePin: (filter: any) => void;
  export let onClone: (filter: any) => void;
  export let onDelete: (filter: any) => void;
  export let onEdit: (filter: any) => void;
  export let handleAction: (event: MouseEvent, action: () => void) => void;

  $: isPinned = filter?.isPinned || false;
  $: isStandard = filter?.isStandard || false;
</script>

<DropdownMenuSub>
  <DropdownMenuSubTrigger
    class="ml-2 opacity-0 group-hover:opacity-100 transition-opacity"
  >
    <Button variant="ghost" size="sm" class="h-6 w-6 p-0">
      <Icon icon="mdi:dots-vertical" class="size-4" />
    </Button>
  </DropdownMenuSubTrigger>
  {#if !isStandard}
    <DropdownMenuSubContent class="w-36 space-y-0.5">
      <Button
        variant="ghost"
        class="flex items-center justify-start px-1.5 py-1 text-sm rounded-sm outline-none cursor-pointer hover:bg-muted hover:text-accent-foreground h-auto w-full"
        on:click={e => handleAction(e, () => onTogglePin(filter))}
      >
        <Icon
          icon={isPinned ? 'mdi:pin-off' : 'mdi:pin'}
          class="size-3.5 mr-2"
        />
        <span class="text-left w-full"
          >{isPinned ? 'Unpin View' : 'Pin View'}</span
        >
      </Button>
      <Button
        variant="ghost"
        class="flex items-center justify-start px-1.5 py-1 text-sm rounded-sm outline-none cursor-pointer hover:bg-muted hover:text-accent-foreground h-auto w-full"
        on:click={e => handleAction(e, () => onClone(filter))}
      >
        <Icon icon="mdi:content-copy" class="size-3.5 mr-2" />
        <span class="text-left w-full">Clone View</span>
      </Button>
      {#if !isStandard}
        <Button
          variant="ghost"
          class="flex items-center justify-start px-1.5 py-1 text-sm rounded-sm outline-none cursor-pointer text-destructive hover:bg-destructive/10 h-auto w-full"
          on:click={e => handleAction(e, () => onDelete(filter))}
        >
          <Icon icon="mdi:delete" class="size-3.5 mr-2" />
          <span class="text-left w-full">Delete View</span>
        </Button>
        <Button
          variant="ghost"
          class="flex items-center justify-start px-1.5 py-1 text-sm rounded-sm outline-none cursor-pointer hover:bg-muted hover:text-accent-foreground h-auto w-full"
          on:click={e => handleAction(e, () => onEdit(filter))}
        >
          <Icon icon="mdi:pencil" class="size-3.5 mr-2" />
          <span class="text-left w-full">Edit View</span>
        </Button>
      {/if}
    </DropdownMenuSubContent>
  {/if}
</DropdownMenuSub>
