<script lang="ts">
  import { weightsAndZones } from '$lib/config';
  import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from '$lib/components/ui/tabs';

  const {
    weights_TNT_DOX,
    weights_TNT_XPS,
    weights_TNT_ECO,
    weights_FEDEX_ENV,
    weights_FEDEX_PAK,
    zones_TNT,
    zones_FEDEX,
  } = weightsAndZones;

  import GenericPricingTable from './table-with-prices.svelte';
  import Calculator from './calculator.svelte';

  export let baseTariff: any | undefined = undefined;
  export let customerTariff: any | undefined = undefined;
  export let refDate: Date | undefined = undefined;
  export let withMdl: boolean = false;
  export let currency: string = 'EUR';
  export let withCalculator = false;

  let tab = withCalculator ? 'calculator' : 'tnt';
</script>

<Tabs bind:value={tab} class="w-full">
  <TabsList class="grid grid-cols-8 w-full">
    {#if withCalculator}
      <TabsTrigger value="calculator">Calculator</TabsTrigger>
    {/if}
    <TabsTrigger value="tnt">TNT GE</TabsTrigger>
    <TabsTrigger value="tnt-eco">TNT Eco</TabsTrigger>
    <TabsTrigger value="fedex-export">FedEx Export</TabsTrigger>
    <TabsTrigger value="fedex-import">FedEx Import</TabsTrigger>
  </TabsList>
  <TabsContent value="calculator" class="mx-auto">
    {#if tab === 'calculator'}
      <Calculator />
    {/if}
  </TabsContent>
  <TabsContent value="tnt">
    {#if tab === 'tnt'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="TNT Documents"
          weights={weights_TNT_DOX}
          zones={zones_TNT}
          operator="TNT"
          product="DOX"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
        <GenericPricingTable
          caption="TNT Non-Documents"
          weights={weights_TNT_XPS}
          zones={zones_TNT}
          operator="TNT"
          product="XPS"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
  <TabsContent value="tnt-eco">
    {#if tab === 'tnt-eco'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="TNT EXPORT-IMPORT ECONOMY EXPRESS "
          weights={weights_TNT_ECO}
          zones={zones_TNT}
          operator="TNT"
          product="ECO"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
  <TabsContent value="fedex-export">
    {#if tab === 'fedex-export'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="EXPORT DOCUMENTS - FedEx Envelope"
          weights={weights_FEDEX_ENV}
          zones={zones_FEDEX}
          operator="FedEx"
          product="ENV"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
        <GenericPricingTable
          caption="EXPORT DOCUMENTS AND NON-DOCUMENTS in FedEx PAK"
          weights={weights_FEDEX_PAK}
          zones={zones_FEDEX}
          operator="FedEx"
          product="PAK"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
  <TabsContent value="fedex-import">
    {#if tab === 'fedex-import'}
      <div class="pricing-tables">
        <GenericPricingTable
          caption="IMPORT DOCUMENTS - FedEx Envelope"
          weights={weights_FEDEX_ENV}
          zones={zones_FEDEX}
          operator="FedEx"
          product="ENV"
          type="IMPORT"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
        <GenericPricingTable
          caption="IMPORT DOCUMENTS AND NON-DOCUMENTS in FedEx PAK"
          weights={weights_FEDEX_PAK}
          zones={zones_FEDEX}
          operator="FedEx"
          product="PAK"
          type="IMPORT"
          {baseTariff}
          {customerTariff}
          {refDate}
          {withMdl}
          {currency}
        />
      </div>
    {/if}
  </TabsContent>
</Tabs>
