<script lang="ts">
  import * as Avatar from '$lib/components/ui/avatar';
  import * as Card from '$lib/components/ui/card';
  import DealPhase from '$lib/components/hanta/deals/deal-phase.svelte';
  import { findAssignments, getLov } from '$lib/api/queries';
  import { onMount } from 'svelte';
  import { Badge } from '$lib/components/ui/badge';
  import { Button } from '$lib/components/ui/button';
  import { appStore } from '$lib/stores/app-store';

  export let contact;
  let phases = [];
  let deals = [];
  let dealsCount = 0;
  let isLoading = true;

  onMount(async () => {
    try {
      const [phasesResult, assignmentsResult] = await Promise.all([
        getLov('Phases'),
        findAssignments({
          id: contact?.id,
          module: 'contacts',
          page: 1,
          itemsPerPage: 10,
          search: '',
        }),
      ]);

      phases = phasesResult?.values?.map(el => el.name) ?? [];
      deals = assignmentsResult.data ?? [];
      dealsCount = assignmentsResult.count ?? 0;
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error state here
    } finally {
      isLoading = false;
    }
  });

  function openItem(module, id) {
    appStore.select({ module, id });
    appStore.openPopup(true);
  }
</script>

<Card.Root class="deals-card">
  <Card.Header>
    <div class="flex justify-between items-center">
      <Card.Title class="text-xl font-bold"
        >Deals <Badge variant="secondary">{dealsCount}</Badge></Card.Title
      >
      <Button variant="outline" size="sm">View All</Button>
    </div>
    <Card.Description>Last Deals</Card.Description>
  </Card.Header>
  <Card.Content>
    {#if isLoading}
      <div class="flex justify-center items-center h-32">
        <span class="loading loading-spinner loading-md"></span>
      </div>
    {:else if deals.length === 0}
      <p class="py-8 text-center text-muted-foreground">No deals found.</p>
    {:else}
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        {#each deals as assignment (assignment.id)}
          <div
            class="flex flex-col p-4 rounded-lg border transition-shadow bg-card hover:shadow-md"
          >
            <div class="flex items-center space-x-4">
              <Avatar.Root class="rounded-lg">
                <Avatar.Image
                  src={assignment.accounts[0]?.logo}
                  alt={assignment.accounts[0]?.name}
                  class="object-cover"
                />
                <Avatar.Fallback
                  >{assignment.accounts[0]?.name?.[0] ?? ''}</Avatar.Fallback
                >
              </Avatar.Root>
              <div class="flex-grow">
                <Button
                  variant="link"
                  class="p-0 h-auto text-lg font-semibold hover:underline"
                  on:click={() =>
                    openItem('accounts', assignment.accounts[0]?.id)}
                >
                  {assignment?.accounts[0]?.name}
                </Button>
                <Button
                  variant="link"
                  class="p-0 h-auto text-muted-foreground hover:underline"
                  on:click={() => {
                    openItem('deals', assignment?.deals[0]?.id);
                  }}
                >
                  {assignment.deals[0]?.name}
                </Button>
              </div>

              {#if assignment.deals[0]?.value}
                <Badge variant="outline" class="text-sm">
                  {assignment.deals[0].value}€
                </Badge>
              {/if}
            </div>
            <div class="mt-4">
              <DealPhase
                {phases}
                currentPhase={assignment.status}
                {assignment}
              />
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </Card.Content>
</Card.Root>
