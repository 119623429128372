<script lang="ts">
  import {
    type Account,
    accountsSchemaWithSales,
    type AccountWithSales,
  } from '$db/schema';
  import { createMutation, useQueryClient } from '@tanstack/svelte-query';
  import { saveAccount } from '$lib/api/mutations';
  import { zodClient } from 'sveltekit-superforms/adapters';
  import { superForm } from 'sveltekit-superforms';
  import HantaInputAddress from '../input/hanta-input-address.svelte';
  import HantaInputText from '../input/hanta-input-text.svelte';
  import HantaInputNumber from '../input/hanta-input-number.svelte';
  import HantaInputTextarea from '../input/hanta-input-textarea.svelte';
  import HantaInputCommunication from '../input/hanta-input-communication.svelte';
  import HantaInputLovSelect from '$lib/widgets/input/hanta-input-lov-select.svelte';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';

  import Icon from '@iconify/svelte';

  import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
  } from '$lib/components/ui/card';

  import CrmObjectSelector from '../crm-object-selector.svelte';
  import HantaInputCheckbox from '../input/hanta-input-checkbox.svelte';
  import FormActions from '$lib/app/forms/form-actions.svelte';
  import HantaInputDate from '../input/hanta-input-date.svelte';
  import { alertStore } from '$lib/stores/alert-store';

  export let crmAccount;
  export let type = 'about';
  export let onUpdateCrmAccount = newAccount => {};

  const client = useQueryClient();

  const addMutation = createMutation({
    mutationFn: (variables): Promise<Account> =>
      Promise.resolve(saveAccount(variables)),
    onSuccess: () => client.invalidateQueries({ queryKey: ['accounts'] }),
    onMutate: async newItem => {
      await client.cancelQueries({ queryKey: ['accounts', crmAccount.id] });
      const previousItems = client.getQueryData<Account[]>([
        'accounts',
        crmAccount.id,
      ]);

      client.setQueryData(['accounts', crmAccount.id], { ...newItem });

      return { previousItems };
    },
    onError: (err: any, variables: any, context: any) => {
      if (context?.previousItems) {
        client.setQueryData(['accounts'], context.previousItems);
      }
      alertStore.show(
        'Error Saving Account',
        err.message || 'Failed to save account information',
        undefined,
        () => {
          // Optional: Add retry logic
          $addMutation.mutate(variables);
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey: ['accounts', crmAccount.id] });
    },
  });

  let initAccount = {
    ...crmAccount,
    address: crmAccount.address ?? {},
    communication: crmAccount.communication ?? {},
  };

  const form = superForm<AccountWithSales>(initAccount, {
    resetForm: false,
    SPA: true,
    dataType: 'json',
    validators: zodClient(accountsSchemaWithSales),

    onSubmit: async () => {
      if (Object.keys($errors).length > 0) {
        alertStore.show(
          'Validation Error',
          'Please check the form for errors',
          undefined,
          () => {
            // Optional: Add form resubmission logic
          },
        );
        console.error('Validation errors', $errors, { form: $formData });
        $errors = {};
        return;
      }

      console.debug('Form data', $formData);
      $addMutation.mutate($formData, {
        onError(error) {
          console.error('Error', error);
          alertStore.show(
            'Error Saving Account',
            error.message || 'Failed to save account information',
            undefined,
            () => {
              // Optional: Add retry logic
              $addMutation.mutate($formData);
            },
          );
        },
        onSuccess: account => {
          onUpdateCrmAccount(account);
        },
      });
    },
  });

  let { form: formData, enhance, errors } = form;
</script>

<form
  class="space-y-6 w-full"
  method="POST"
  use:enhance
  data-testid="account-form"
>
  {#if type === 'about'}
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:building" class="mr-2" /> Company Details</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-4">
        <HantaInputText
          {form}
          name="refId"
          label="Id"
          readOnly={true}
          icon="mdi:file-text"
          data-testid="account-ref-id-input"
        />
        <HantaInputText
          {form}
          name="name"
          label="Name"
          icon="mdi:building"
          data-testid="account-name-input"
        />

        <HantaInputLovSelect
          {form}
          bind:value={$formData.accountStatus}
          label="Status"
          type="Account > Status"
          multiple={false}
          data-testid="account-status-select"
        />

        <HantaInputNumber
          {form}
          name="foundedOn"
          label="Founded Date"
          icon="mdi:calendar"
          data-testid="account-founded-on-input"
        />
        <HantaInputText
          {form}
          name="tagline"
          label="Tagline"
          icon="mdi:file-text"
          data-testid="account-tagline-input"
        />

        <HantaInputTextarea
          {form}
          name="philosophy"
          label="Philosophy"
          icon="mdi:file-text"
          data-testid="account-philosophy-input"
        />
        <HantaInputText
          {form}
          name="websiteUrl"
          label="Website"
          icon="mdi:globe"
          data-testid="account-website-url-input"
        />
        <HantaInputText
          {form}
          name="officialName"
          label="Official name"
          icon="mdi:building"
          data-testid="account-official-name-input"
        />
        <HantaInputNumber
          {form}
          name="revenue"
          label="Revenue"
          icon="mdi:banknote"
          data-testid="account-revenue-input"
        />
        <HantaInputNumber
          {form}
          name="employeeCount"
          label="Employees Count"
          icon="mdi:users"
          data-testid="account-employee-count-input"
        />
        <HantaInputNumber
          {form}
          name="employees"
          label="Employees"
          icon="mdi:users"
          data-testid="account-employees-input"
        />
        <HantaInputText
          {form}
          name="employeeCountRange"
          label="Employees Range"
          icon="mdi:users"
          data-testid="account-employee-count-range-input"
        />
        <HantaInputText
          {form}
          name="industry"
          label="Industry"
          icon="mdi:briefcase"
          data-testid="account-industry-input"
        />
        <HantaInputTextarea
          {form}
          name="description"
          label="Description"
          icon="mdi:file-text"
          data-testid="account-description-input"
        />
        <HantaInputText
          {form}
          name="linkedin"
          label="LinkedIn"
          icon="mdi:linkedin"
          data-testid="account-linkedin-input"
        />
        <HantaInputText
          {form}
          name="url"
          label="URL"
          icon="mdi:link"
          data-testid="account-url-input"
        />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:phone" class="mr-2" /> Contact Information</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputCommunication
          {form}
          name="communication"
          label="Contact"
          types={['phone', 'email']}
          data-testid="account-communication-input"
        />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:map-pin" class="mr-2 size-4" /> Address</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputAddress
          {form}
          name="address"
          label="Address"
          data-testid="account-address-input"
        />
      </CardContent>
    </Card>

    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:map-pin" class="mr-2 size-4" /> Official Address</CardTitle
        >
      </CardHeader>
      <CardContent>
        <HantaInputAddress
          {form}
          name="officialAddress"
          label="Official address"
          data-testid="account-official-address-input"
        />
      </CardContent>
    </Card>
  {:else if type === 'analysis'}
    <div class="grid grid-cols-2 gap-4">
      <Card>
        <CardHeader>
          <CardTitle class="flex items-center"
            ><Icon icon="mdi:file-text" class="mr-2" /> Analysis</CardTitle
          >
        </CardHeader>
        <CardContent class="space-y-4">
          <UsersSelector
            bind:value={$formData.sales}
            label="Sales"
            multiple={false}
            data-testid="account-sales-selector"
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.region}
            name="region"
            type="Account > Region"
            label="Region"
            multiple={false}
            icon="mdi:briefcase"
            data-testid="account-region-select"
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.district}
            name="district"
            type="Account > District"
            label="District"
            multiple={false}
            icon="mdi:briefcase"
            data-testid="account-district-select"
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.customerType}
            name="customerType"
            type="Account > Customer Type"
            label="Customer Type"
            multiple={false}
            icon="mdi:briefcase"
            data-testid="account-customer-type-select"
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.industry}
            type="Account > Industry"
            label="Industry"
            multiple={false}
            icon="mdi:briefcase"
            data-testid="account-industry-select"
          />

          <HantaInputCheckbox
            {form}
            name="potentialCustomer"
            label="Potential Customer"
            data-testid="account-potential-customer-checkbox"
          />

          <HantaInputText
            {form}
            name="statusComment"
            label="Status Comment"
            icon="mdi:message-square"
            data-testid="account-status-comment-input"
          />
          <HantaInputText
            {form}
            name="reasonOfUnsatisfaction"
            label="Reason of unsatisfaction"
            icon="mdi:alert-circle"
            data-testid="account-reason-of-unsatisfaction-input"
          />
          <HantaInputText
            {form}
            name="satisfaction"
            label="Satisfaction"
            icon="mdi:thumbs-up"
            data-testid="account-satisfaction-input"
          />
          <!--
          <HantaInputText
            {form}
            name="lostToCompetitor"
            label="Lost to competitor"
            icon={UserX}
          />
          -->
          <HantaInputText
            {form}
            name="lostReason"
            label="Lost reason"
            icon="mdi:alert-circle"
            data-testid="account-lost-reason-input"
          />
          <HantaInputText
            {form}
            name="commentToDecision"
            label="Comment to decision"
            icon="mdi:message-square"
            data-testid="account-comment-to-decision-input"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.competitors}
            type="Account > Competitors"
            label="Competitors"
            multiple={true}
            icon="mdi:users"
            data-testid="account-competitors-select"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.phase}
            type="Account > Phase"
            label="Phase"
            multiple={false}
            icon="mdi:bar-chart-3"
            data-testid="account-phase-select"
          />
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle class="flex items-center"
            ><Icon icon="mdi:bar-chart-3" class="mr-2" /> Potential</CardTitle
          >
        </CardHeader>
        <CardContent class="space-y-4">
          <HantaInputLovSelect
            {form}
            bind:value={$formData.interestZone}
            type="Countries"
            label="Interest Zone"
            multiple={true}
            icon="mdi:map"
            data-testid="account-interest-zone-select"
          />

          <HantaInputLovSelect
            {form}
            bind:value={$formData.direction}
            type="Account > Direction"
            label="Direction"
            multiple={true}
            icon="mdi:compass"
            data-testid="account-direction-select"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.products}
            type="Account > Product"
            label="Products"
            multiple={true}
            icon="mdi:package"
            data-testid="account-products-select"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.serviceUsageFrequency}
            type="Account > Service Usage Frequency"
            label="Service Usage Frequency"
            multiple={false}
            icon="mdi:clock"
            data-testid="account-service-usage-frequency-select"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.expectedVolume}
            type="Account > Expected Volume"
            label="Expected Volume"
            multiple={false}
            icon="mdi:bar-chart-3"
            data-testid="account-expected-volume-select"
          />
          <HantaInputLovSelect
            {form}
            bind:value={$formData.expectedCategory}
            type="Account > Category"
            label="Expected Category"
            multiple={false}
            icon="mdi:tag"
            data-testid="account-expected-category-select"
          />
        </CardContent>
      </Card>
    </div>
  {:else if type === 'financial'}
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:wallet" class="mr-2" /> Financial Information</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-4">
        <HantaInputText
          {form}
          name="fedExICPCAccount"
          label="FedEx ICPC Account"
          icon="mdi:credit-card"
          data-testid="account-fed-ex-icpc-account-input"
        />
        <HantaInputText
          {form}
          name="tntICPCAccount"
          label="TNT ICPC Account"
          icon="mdi:credit-card"
          data-testid="account-tnt-icpc-account-input"
        />
        <HantaInputNumber
          {form}
          name="paymentDelayInDays"
          label="Payment Delay (days)"
          icon="mdi:clock"
          data-testid="account-payment-delay-in-days-input"
        />

        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.baseTariffObj}
          label="Base tariff"
          data-testid="account-base-tariff-selector"
        />
        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.customerTariffObj}
          label="Customer tariff"
          data-testid="account-customer-tariff-selector"
        />
        <CrmObjectSelector
          module="tariffs"
          bind:value={$formData.supplierTariffObj}
          label="Supplier tariff"
          data-testid="account-supplier-tariff-selector"
        />

        <HantaInputNumber
          {form}
          name="maxDebt"
          label="Max Debt"
          icon="mdi:alert-triangle"
          data-testid="account-max-debt-input"
        />
        <HantaInputNumber
          {form}
          name="currentCreditLimit"
          label="Current Credit Limit"
          readonly={true}
          icon="mdi:credit-card"
          data-testid="account-current-credit-limit-input"
        />
        <HantaInputNumber
          {form}
          name="currentBalance"
          label="Current Balance"
          readonly={true}
          icon="mdi:piggy-bank"
          data-testid="account-current-balance-input"
        />
        <HantaInputDate
          {form}
          name="currentBalanceDate"
          label="Balance Updated On"
          readOnly={true}
          data-testid="account-current-balance-date-input"
        />
        <HantaInputLovSelect
          {form}
          bind:value={$formData.creditRating}
          type="Account > Credit Rating"
          label="Credit Rating"
          multiple={false}
          icon="mdi:scale"
          data-testid="account-credit-rating-select"
        />
        <HantaInputLovSelect
          {form}
          bind:value={$formData.debtReason}
          type="Account > Debt Reason"
          label="Debt Reason"
          multiple={false}
          icon="mdi:alert-triangle"
          data-testid="account-debt-reason-select"
        />
      </CardContent>
    </Card>
    <Card>
      <CardHeader>
        <CardTitle class="flex items-center"
          ><Icon icon="mdi:building" class="mr-2" /> Bank Details</CardTitle
        >
      </CardHeader>
      <CardContent class="space-y-2">
        <HantaInputText
          {form}
          name="companyName"
          label="Company Name"
          icon="mdi:building"
          data-testid="account-company-name-input"
        />
        <HantaInputText
          {form}
          name="iban"
          label="IBAN"
          icon="mdi:credit-card"
          data-testid="account-iban-input"
        />
        <HantaInputText
          {form}
          name="bic"
          label="BIC"
          icon="mdi:credit-card"
          data-testid="account-bic-input"
        />
        <HantaInputText
          {form}
          name="bank"
          label="Bank"
          icon="mdi:building"
          data-testid="account-bank-input"
        />
        <HantaInputText
          {form}
          name="tva"
          label="TVA"
          icon="mdi:file-text"
          data-testid="account-tva-input"
        />
      </CardContent>
    </Card>
  {/if}

  <FormActions {form} data-testid="account-form-actions" />
</form>
