<script lang="ts">
  import Address from '$lib/components/hanta/address.svelte';
  import Communication from '$lib/components/hanta/communication.svelte';
  import * as Avatar from '$lib/components/ui/avatar';
  import Button from '$lib/components/ui/button/button.svelte';
  import { ContactRoleType, type ContactWithRefs } from '$db/schema';
  import { appStore } from '$lib/stores/app-store';
  import LinkedinButton from '$lib/components/hanta/linkedin-button.svelte';
  import { createItem } from '$lib/api/mutations';
  import SelectExistingContact from '../select-existing-contact.svelte';

  export let customerId: string | undefined;
  export let dealId: string | undefined;
  export let contacts: ContactWithRefs[];

  function openContact(contact) {
    appStore.select({
      module: 'contacts',
      id: contact.id,
    });
    appStore.openPopup(true);
  }

  async function createContact() {
    // TODO: first name and last name should be required
    // TODO: add company relationship
    // TODO: add a loading state
    // TODO: add error handling
    const data = await createItem('contacts', {
      firstname: ' ',
      lastname: ' ',
      name: ' ',
      customer: customerId,
      role: ContactRoleType.enum.Contact,
    });
    openContact(data[0]);
  }
</script>

<div class="mt-4 text-left shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:p-6">
    <div class="flex justify-between">
      <h3 class="text-base font-semibold leading-6 text-primary">
        Contacts
        <span class="text-muted-foreground">({contacts.length})</span>
      </h3>
      <div class="flex flex-col items-end space-y-2 md:flex-row md:space-x-2">
        <Button variant="outline" on:click={createContact}
          >Add new contact</Button
        >
        {#if dealId}
          <SelectExistingContact {customerId} {dealId} />
        {/if}
      </div>
    </div>
    {#if contacts.length === 0}
      <p class="mt-2 text-sm text-gray-500">No contacts found</p>
    {:else}
      <div>
        {#each contacts || [] as contact, i (i)}
          {@const name =
            contact.name ??
            (contact.firstname ?? '') + ' ' + (contact.lastname ?? '')}

          <div class="mt-5">
            <div
              class="px-6 py-5 rounded-md bg-muted sm:flex sm:items-start sm:justify-between"
            >
              <div class="sm:flex sm:items-start">
                <div class="flex flex-col justify-center">
                  <Avatar.Root class="size-20">
                    <Avatar.Image src={contact.photo} alt={name} />
                    <Avatar.Fallback>{name}</Avatar.Fallback>
                  </Avatar.Root>
                </div>
                <div class="mt-3 sm:ml-4 sm:mt-0">
                  <div
                    class="flex items-center text-sm font-medium text-primary"
                  >
                    <Button
                      variant="link"
                      class="flex pl-0"
                      on:click={() => openContact(contact)}
                    >
                      {#if contact.salutation}
                        <span class="pr-1">{contact.salutation ?? ''}</span>
                      {/if}
                      {#if contact.title}
                        <span class="pr-1">{contact.title ?? ''}</span>
                      {/if}

                      <span>{name}</span>
                    </Button>
                    <LinkedinButton linkedin={contact?.linkedin} />
                  </div>
                  <div
                    class="mt-1 text-sm text-primary/80 sm:flex sm:items-center"
                  >
                    <div>{contact.position ?? ''}</div>
                  </div>
                  <Communication
                    class="mt-2"
                    communication={contact.communicationWork}
                  />
                  <Address address={contact.addressWork} />
                </div>
              </div>
              <div class="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                <Button
                  variant="secondary"
                  on:click={() => openContact(contact)}>Edit</Button
                >
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>
