import type { Filter } from '$db/schema';

export interface StandardFilter {
  id: string;
  name: string;
  filter: any[];
  isDefault?: boolean;
  isPinned?: boolean;
  module?: string; // if undefined, applies to all modules
}

export const standardFilters: StandardFilter[] = [
  {
    id: 'all',
    name: 'All',
    isDefault: true,
    isPinned: true,
    filter: []
  },
  {
    id: 'created-today',
    name: 'Created Today',
    filter: [
      {
        field: 'createdAt',
        operator: 'gte',
        value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
        active: true,
      },
    ],
  },
  {
    id: 'created-this-week',
    name: 'Created This Week',
    filter: [
      {
        field: 'createdAt',
        operator: 'gte',
        value: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
        active: true,
      },
    ],
  },
  {
    id: 'modified-today',
    name: 'Modified Today',
    filter: [
      {
        field: 'modifiedAt',
        operator: 'gte',
        value: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
        active: true,
      },
    ],
  },
  {
    id: 'modified-this-week',
    name: 'Modified This Week',
    filter: [
      {
        field: 'modifiedAt',
        operator: 'gte',
        value: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
        active: true,
      },
    ],
  },
  // Module specific filters
 
  {
    id: 'deals-open',
    name: 'Open Deals',
    module: 'deals123',
    filter: [
      {
        field: 'status',
        operator: 'eq',
        value: 'open',
        active: true,
      },
    ],
  },
  {
    id: 'deals-closed-won',
    name: 'Closed Won',
    module: 'deals123',
    filter: [
      {
        field: 'status',
        operator: 'eq',
        value: 'closed-won',
        active: true,
      },
    ],
  },
];
