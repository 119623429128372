<script lang="ts">
  import { Button } from '$lib/components/ui/button/index.js';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';
  import Input from '$lib/components/ui/input/input.svelte';
  import { cn } from '$lib/utils/hanta-utils.js';
  import { onDestroy } from 'svelte';
  import Icon from '@iconify/svelte';

  export let value: { operator: string; value: number | null } | undefined =
    undefined;

  const operators = [
    { label: 'Equal to', value: 'eq', icon: 'mdi:equal' },
    { label: 'Not equal to', value: 'neq', icon: 'mdi:not-equal' },
    { label: 'Greater than', value: 'gt', icon: 'mdi:greater-than' },
    { label: 'Greater than or equal', value: 'gte', icon: 'mdi:greater-than-or-equal' },
    { label: 'Less than', value: 'lt', icon: 'mdi:less-than' },
    { label: 'Less than or equal', value: 'lte', icon: 'mdi:less-than-or-equal' },
    { label: 'Is null', value: 'is', icon: 'mdi:null' },
    { label: 'Is not null', value: 'not.is', icon: 'mdi:not-equal-variant' },
  ];

  let selectedOperator = operators[0];
  let numberValue: string = '';
  let debounceTimeout: ReturnType<typeof setTimeout>;

  // Clean up timeout on component destroy
  onDestroy(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
  });

  $: {
    if (value) {
      selectedOperator =
        operators.find(op => op.value === value.operator) || operators[0];
      numberValue = value.value?.toString() || '';
    }
  }

  function handleOperatorSelect(operator) {
    selectedOperator = operator;
    updateValue();
  }

  function handleNumberInput(event) {
    numberValue = event.target.value;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      updateValue();
    }, 500);
  }

  function updateValue() {
    if (
      selectedOperator.value === 'is' ||
      selectedOperator.value === 'not.is'
    ) {
      value = {
        operator: selectedOperator.value,
        value: null,
      };
    } else if (numberValue !== '') {
      value = {
        operator: selectedOperator.value,
        value: parseFloat(numberValue),
      };
    }
  }
</script>

<div class="flex gap-2">
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        builders={[builder]}
        variant="outline"
        class="w-[200px] justify-between"
      >
        <div class="flex items-center gap-2">
          <Icon icon={selectedOperator.icon} class="h-4 w-4" />
          {selectedOperator.label}
        </div>
        <Icon icon="mdi:chevron-down" class="h-4 w-4" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content class="w-56">
      {#each operators as operator}
        <DropdownMenu.Item
          on:click={() => handleOperatorSelect(operator)}
          class="flex items-center gap-2"
        >
          <Icon icon={operator.icon} class="h-4 w-4" />
          {operator.label}
        </DropdownMenu.Item>
      {/each}
    </DropdownMenu.Content>
  </DropdownMenu.Root>

  {#if selectedOperator.value !== 'is' && selectedOperator.value !== 'not.is'}
    <Input
      type="number"
      placeholder="Enter value"
      value={numberValue}
      on:input={handleNumberInput}
      class="w-[200px]"
    />
  {/if}
</div>
