<script lang="ts">
  import { onMount } from 'svelte';
  import * as Form from '$lib/components/ui/form/index.js';
  import { cn } from '$lib/utils/hanta-utils';
  import { stringProxy } from 'sveltekit-superforms';
  import Editor from './editor.svelte';

  export let form;
  export let name;
  export let label: string = '';
  export let description = '';
  export let placeholder: string = '';
  export let readOnly: boolean = false;

  const { form: formData } = form;

  type $$Props = {
    form: any;
    name: string;
    label?: string;
    description?: string;
    placeholder?: string;
    class?: string;
    readOnly?: boolean;
    initialRows?: number;
    toolbarPosition?: 'top' | 'bottom';
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  $: proxy = stringProxy(formData, name, { empty: 'undefined' });

  onMount(() => {
    if ($proxy && $proxy.startsWith('<html')) {
      const start = $proxy.indexOf('<body>') + 6;
      const end = $proxy.indexOf('</body>');
      $proxy = $proxy.substring(start, end);
    }
  });

  const onTextChange = event => {
    const { html } = event.detail ?? {};
    $proxy = html;
  };
</script>

<Form.Field class={cn('space-y-1', className)} {form} {name}>
  <Form.Control let:attrs>
    {#if label}
      <Form.Label>{label}</Form.Label>
    {/if}
    {#if !readOnly}
      <Editor
        data={$proxy}
        options={{
          placeholder,
          readOnly,
        }}
        on:text-change={onTextChange}
      />
    {:else}
      <span class="w-full text-sm text-foreground">
        {@html $proxy?.replace(/\n/g, '<br/>')}
      </span>
    {/if}
  </Form.Control>
  {#if description}
    <Form.Description>{description ?? ''}</Form.Description>
  {/if}
  <Form.FieldErrors />
</Form.Field>
