<script lang="ts">
  import * as Tabs from '$lib/components/ui/tabs';
  import Icon from '@iconify/svelte';
  import { getIconForActivityType } from '$lib/config/crm-config';
  import { activityTypes } from '$lib/config/crm-config';

  export let selectedType: string = activityTypes[1];

  const handleTypeChange = (type: string) => {
    selectedType = type;
  };
</script>

<div class="flex px-6 min-w-0 max-w-full mt-4">
  <Tabs.Root
    value={selectedType}
    onValueChange={handleTypeChange}
    class="w-full"
  >
    <Tabs.List class="w-full">
      {#each activityTypes.slice(1) as type}
        <Tabs.Trigger value={type} class="flex-1">
          <div class="flex items-center justify-center gap-2">
            <Icon icon={getIconForActivityType(type)} class="w-4 h-4" />
            <span class="text-xs">{type}</span>
          </div>
        </Tabs.Trigger>
      {/each}
    </Tabs.List>
  </Tabs.Root>
</div>
