<script lang="ts">
  import { Badge } from '$lib/components/ui/badge';
  import {
    dndzone,
    TRIGGERS,
    SOURCES,
    DRAGGED_ELEMENT_ID,
  } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import { tick } from 'svelte';
  import { activeZoneId, selectedItems } from './selection-store';
  import KanbanItem from './kanban-item.svelte';
  import { addPipelineItem } from '$lib/api/pipeline-api';

  import { createEventDispatcher } from 'svelte';
  import type { Pipeline, Phase } from '$lib/types/pipeline';
  import ItemSelectionDialog from './item-selection-dialog.svelte';
  import Button from '../ui/button/button.svelte';
  import Icon from '@iconify/svelte';

  const dispatch = createEventDispatcher();

  const flipDurationMs = 120;

  let showItemDialog = false;
  export let pipeline: Pipeline;
  export let items = [];
  export let phase: Phase;

  let zoneId = `zone-${Math.floor(Math.random() * 1000000)}`;

  let isDraggingOver = false;

  async function onAddItem(item: any) {
    dispatch('add', {      
      item,
    });
  }

  function transformDraggedElement(el) {
    if (
      !el.getAttribute('data-selected-items-count') &&
      Object.keys($selectedItems).length
    ) {
      el.setAttribute(
        'data-selected-items-count',
        Object.keys($selectedItems).length + 1,
      );
    }
  }
  function handleConsider(e) {
    const {
      items: newItems,
      info: { trigger, source, id },
    } = e.detail;
    isDraggingOver = trigger === TRIGGERS.DRAGGED_ENTERED;
    if (source !== SOURCES.KEYBOARD) {
      if (
        Object.keys($selectedItems).length &&
        trigger === TRIGGERS.DRAG_STARTED
      ) {
        if (Object.keys($selectedItems).includes(id)) {
          delete $selectedItems[id];
          $selectedItems = { ...$selectedItems };
          tick().then(() => {
            items = newItems.filter(
              item => !Object.keys($selectedItems).includes(item.id),
            );
          });
        } else {
          $selectedItems = {};
        }
      }
    }
    if (trigger === TRIGGERS.DRAG_STOPPED) $selectedItems = {};
    items = newItems;
  }
  
  function handleFinalize(e) {
    isDraggingOver = false;

    let {
      items: newItems,
      info: { trigger, source, id },
    } = e.detail;

    if (Object.keys($selectedItems).length) {
      console.log('handleFinalize', { trigger, source, id, newItems });
      if (trigger === TRIGGERS.DROPPED_INTO_ANOTHER) {
        items = newItems.filter(
          item => !Object.keys($selectedItems).includes(item.id),
        );
      } else if (
        trigger === TRIGGERS.DROPPED_INTO_ZONE ||
        trigger === TRIGGERS.DROPPED_OUTSIDE_OF_ANY
      ) {
        tick().then(() => {
          const idx = newItems.findIndex(item => item.id === id);
          // to support arrow up when keyboard dragging
          const sidx = Math.max(
            Object.values($selectedItems).findIndex(item => item.id === id),
            0,
          );
          newItems = newItems.filter(
            item => !Object.keys($selectedItems).includes(item.id),
          );
          newItems.splice(idx - sidx, 0, ...Object.values($selectedItems));
          items = newItems;
          $activeZoneId = zoneId;
          if (source !== SOURCES.KEYBOARD) $selectedItems = {};
        });
      }
    } else {
      items = newItems;
    }

    const added = items.filter(
      item => !phase.items.some(oldItem => oldItem.id === item.id),
    );
    const removed = phase.items.filter(
      item => !items.some(newItem => newItem.id === item.id),
    );
    console.debug({ items });
    dispatch('changes', {
      items: newItems,
      added,
      removed,
    });
    items = newItems;
  }
  function handleMaybeSelect(id, e) {
    if (!e.ctrlKey && !e.metaKey) return;
    if (e.key && e.key !== 'Shift') return;
    if ($activeZoneId !== zoneId) {
      $selectedItems = {};
      $activeZoneId = zoneId;
    }
    if (Object.keys($selectedItems).includes(id)) {
      delete $selectedItems[id];
    } else {
      $selectedItems[id] = items.find(item => item.id === id);
    }
    $selectedItems = { ...$selectedItems };
  }
</script>

<div class="flex flex-col justify-between items-center">
  <div class="flex flex-row justify-around items-center w-full bg-primary/10">
    <Badge variant={items?.length > 0 ? 'default' : 'outline'}
      >{items.length}</Badge
    >
    <h2 class="text-lg font-bold">{phase.name}</h2>

    <Button variant="ghost" size="sm" on:click={() => (showItemDialog = true)}>
      <Icon icon="mdi:plus" class="w-4 h-4" />
    </Button>
  </div>
  <ItemSelectionDialog
    bind:showDialog={showItemDialog}
    module={pipeline?.module}
    onSelect={item => {
      console.log(JSON.stringify(item));
      onAddItem(item);
      showItemDialog = false;
    }}
    onClose={() => (showItemDialog = false)}
  />
  <section
    class="p-2 bg-primary-800/50 w-[32rem] h-[calc(100%-5rem)] overflow-y-auto"
    class:drag-over={isDraggingOver}
    use:dndzone={{
      items,
      flipDurationMs,
      transformDraggedElement,
      dropTargetStyle: {
        backgroundColor: 'rgb(var(--primary) / 0.1)',
      },
    }}
    on:consider={handleConsider}
    on:finalize={handleFinalize}
  >
    {#each items || [] as item (item.id)}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="min-h-[60px] kanban-item"
        animate:flip={{ duration: flipDurationMs }}
        class:selected={Object.keys($selectedItems).includes(item.id)}
        on:mousedown={e => handleMaybeSelect(item.id, e)}
        on:keydown={e => handleMaybeSelect(item.id, e)}
      >
        <KanbanItem {pipeline} {item} />
      </div>
    {/each}

    {#if phase?.description}
      <p class="text-sm text-muted-foreground">
        {phase.description}
      </p>
    {/if}
    {#if items?.length === 0}
      <p class="hidden text-sm text-muted-foreground">No items in this phase</p>
      <Button
        variant="ghost"
        size="sm"
        class="w-full"
        on:click={() => (showItemDialog = true)}
      >
        <Icon icon="mdi:plus" class="w-4 h-4" />
        <span>Add item</span>
      </Button>
    {/if}
  </section>
</div>

<style>
  div {
    @apply border border-solid border-primary/10 py-1 px-2 my-3 rounded-lg;
  }
  .kanban-item {
    @apply p-0 border-none;
  }

  section {
    min-height: 12em;
  }
  .selected {
    @apply border-destructive bg-destructive/20 p-0.5;
    opacity: 0.7;
  }
  :global([data-selected-items-count]::after) {
    position: absolute;
    right: 0.2em;
    padding: 0.5em;
    content: attr(data-selected-items-count);
    color: white;
    background: theme('colors.destructive.DEFAULT / 0.8');
  }
</style>
