import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { cubicOut } from 'svelte/easing';
import type { TransitionConfig } from 'svelte/transition';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type DebouncedFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => void;

export const debounce = <T extends (...args: any[]) => any>(
  callback: T,
  wait: number
): DebouncedFunction<T> => {
  let timeoutId: number | null = null;
  return (...args: Parameters<T>) => {
    window.clearTimeout(timeoutId!);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export function formatNumberWithSuffix(number: number): string | number {
  if (number < 1000) {
    return number;
  }
  const suffixes: string[] = ['', 'K', 'M', 'B'];
  const index = Math.floor(Math.log(number) / Math.log(1000));
  const value = parseFloat((number / Math.pow(1000, index)).toFixed(1));
  return `${value}${suffixes[index]}`;
}

interface NameResult {
  vorname?: string;
  name?: string;
  [key: string]: string | undefined;
}

export function extractName(result: NameResult, name: string): void {
  let split = name.trim().split(' ');
  if (split.length > 0) {
    result['vorname'] = split.slice(0, split.length - 1).join(' ');
    split.length > 1 && (result['name'] = split[split.length - 1]);
  }
}

type FlyAndScaleParams = {
  y?: number;
  x?: number;
  start?: number;
  duration?: number;
  center?: boolean;
};

export function formatCurrency(
  amount: number | undefined | null,
  currency: string = 'EUR',
): string {
  if (amount === undefined || amount === null) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency === 'EUR - Euro' ? 'EUR' : currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: 'symbol',
  });

  const parts = formatter.formatToParts(amount);
  let formatted = '';

  for (const part of parts) {
    if (part.type !== 'currency') {
      formatted += part.value;
    }
  }

  // Add the currency symbol at the end
  const currencySymbol =
    parts.find(part => part.type === 'currency')?.value || currency;
  formatted = formatted.trim() + ' ' + currencySymbol;

  return formatted;
}

const centerElement = (elem: HTMLElement) => {
  console.debug('centerPopup');
  let w = elem.getBoundingClientRect().width;
  let h = elem.getBoundingClientRect().height;

  // Calculate the coordinates for the center of the viewport
  let x = (window.innerWidth - w) / 2;
  let y = (window.innerHeight - h) / 2;

  // Apply the coordinates to the elem's style
  elem.style.transform = `translate3d(${x}px, ${y}px, 0)`;
};

export const flyAndScale = (
  node: Element,
  params: FlyAndScaleParams = {
    y: -8,
    x: 0,
    start: 0.95,
    duration: 150,
    center: false,
  },
): TransitionConfig => {
  const style = getComputedStyle(node);

  if (params.center) {
    centerElement(node);
  }

  const scaleConversion = (
    valueA: number,
    scaleA: [number, number],
    scaleB: [number, number],
  ) => {
    const [minA, maxA] = scaleA;
    const [minB, maxB] = scaleB;

    const percentage = (valueA - minA) / (maxA - minA);
    const valueB = percentage * (maxB - minB) + minB;

    return valueB;
  };

  const styleToString = (
    style: Record<string, number | string | undefined>,
  ): string => {
    return Object.keys(style).reduce((str, key) => {
      if (style[key] === undefined) return str;
      return str + `${key}:${style[key]};`;
    }, '');
  };

  return {
    duration: params.duration ?? 200,
    delay: 0,
    css: t => {
      const y = scaleConversion(t, [0, 1], [params.y ?? 5, 0]);
      const x = scaleConversion(t, [0, 1], [params.x ?? 0, 0]);
      const scale = scaleConversion(t, [0, 1], [params.start ?? 0.95, 1]);

      const transform = style.transform === 'none' ? '' : style.transform;

      return styleToString({
        transform: `${transform} translate3d(${x}px, ${y}px, 0) scale(${scale})`,
        opacity: t,
      });
    },
    easing: cubicOut,
  };
};

export function objectDiff(obj1: any, obj2: any) {
  const diff = {};

  function compareObjects(item1, item2, path = '') {
    for (const key in item1) {
      const currentPath = path ? `${path}.${key}` : key;

      if (!(key in item2)) {
        diff[currentPath] = { oldValue: item1[key], newValue: undefined };
      } else if (
        typeof item1[key] === 'object' &&
        item1[key] !== null &&
        typeof item2[key] === 'object' &&
        item2[key] !== null
      ) {
        compareObjects(item1[key], item2[key], currentPath);
      } else if (item1[key] !== item2[key]) {
        diff[currentPath] = { oldValue: item1[key], newValue: item2[key] };
      }
    }

    for (const key in item2) {
      const currentPath = path ? `${path}.${key}` : key;
      if (!(key in item1)) {
        diff[currentPath] = { oldValue: undefined, newValue: item2[key] };
      }
    }
  }

  compareObjects(obj1, obj2);
  return diff;
}

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat().format(num);
};
