<script lang="ts">
  import { cn } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';

  export let icon: any;

  let className = undefined;
  export { className as class };
</script>

{#if icon}
  <Icon {icon} class={cn('inline items-start size-5', className ?? '')} />
{/if}
