<script lang="ts">
  import '../../app.pcss';
  import '../../themes.pcss';

  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';

  import App from '../../App.svelte';
  import AuthWithSupabase from './supabase/auth-with-supabase.svelte';
  import AuthWithClerk from './clerk/auth-with-clerk.svelte';
</script>

<svelte:component
  this={import.meta.env.VITE_AUTH_PROVIDER === 'supabase'
    ? AuthWithSupabase
    : AuthWithClerk}
>
  <App />
</svelte:component>
