<script lang="ts">
  import * as Command from '$lib/components/ui/command';
  import { Button } from '$lib/components/ui/button';
  import Icon from '@iconify/svelte';
  import { getItems } from '$lib/api/queries';

  export let showDialog = false;
  export let module: string;
  export let onSelect: (item: any) => void;
  export let onClose: () => void;

  let searchQuery = '';
  let items: any[] = [];
  let loading = false;

  $: {
    loadItems();
  }

  $: if (searchQuery) {
    loadItems();
  }

  async function loadItems() {
    loading = true;
    getItems({
      collection: module,
      search: searchQuery,
      from: 0,
      to: 10,
      select: 'id,name',
    })
      .then(result => {
        items = result.data;
      })
      .catch(error => {
        console.error('Error loading items:', error);
        items = [];
      })
      .finally(() => {
        loading = false;
      });
  }

  $: filteredItems = (items || []).filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  function handleSelect(item: any) {
    onSelect(item);
    showDialog = false;
    searchQuery = '';
  }
</script>

<Command.Dialog open={showDialog} onOpenChange={(open) => !open && onClose()}>
  <Command.Root class="rounded-lg border shadow-md">
    <Command.Input 
      placeholder="Search {module}..." 
      bind:value={searchQuery}
    />
    <Command.List>
      <Command.Empty>No results found.</Command.Empty>
      {#if loading}
        <div class="p-4 text-center">
          <Icon icon="mdi:loading" class="w-6 h-6 animate-spin" />
        </div>
      {:else}
        {#each filteredItems as item (item.id)}
          <Command.Item 
            value={item.name}
            onSelect={() => handleSelect(item)}
          >
            {item.name}
          </Command.Item>
        {/each}
      {/if}
    </Command.List>
  </Command.Root>
</Command.Dialog>
