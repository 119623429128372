<script lang="ts">
  import { authStore } from '$lib/stores/auth-store';
  import DocxTemplates from '$lib/widgets/docx-template/docx-templates.svelte';

  export let from;
  export let to;
  export let data;
  export let project;

  $: projectName = project === 'null' ? 'Total' : project;

  $: totalMinutes = (data || []).reduce((acc, item) => {
    const minutes = item.duration.split(':').reduce((acc, time, i) => {
      return acc + parseInt(time) * Math.pow(60, 1 - i);
    }, 0);
    return acc + minutes;
  }, 0);
  $: totalHours = Math.floor(totalMinutes / 60);
  $: totalMinutesRest = (totalMinutes % 60) / 60;

  const localDateFrom = date =>
    new Date(date).toLocaleDateString('de', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

  const localTimeString = time =>
    new Date('2024-01-01T' + time).toLocaleTimeString('de', {
      hour: '2-digit',
      minute: '2-digit',
    });

  $: printData = {
    name:
      (projectName ?? 'Total') +
      ' ' +
      localDateFrom(from) +
      '-' +
      localDateFrom(to),
    project: projectName ?? 'Total',
    user: $authStore?.user?.fullName,
    start: localDateFrom(from),
    end: localDateFrom(to),

    total: (totalHours + totalMinutesRest).toFixed(2),

    positions: (data || []).map(pos => ({
      date: localDateFrom(pos.date),

      duration: localTimeString(pos.duration),
      starttime: localTimeString(pos.starttime),
      endtime: localTimeString(pos.endtime),
      pause: pos.pause,
      task: pos.task,
      description: pos.description,
    })),
  };
</script>

<div class="p-4 my-4 w-48 border border-solid shadow-md bg-secondary">
  <div class="text-xs font-bold text-muted-foreground">
    {projectName}
  </div>
  <div class="text-xl">
    {(totalHours + totalMinutesRest).toFixed(2)} hours
  </div>

  <DocxTemplates
    class="px-4 py-2"
    data={printData}
    label={projectName}
    templateName="timelogs"
    customFilename={printData.name}
  />
</div>
