<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import * as Sheet from '$lib/components/ui/sheet';
  import { cn } from '$lib/utils/hanta-utils';
  import HantaObjectView from './hanta-object-view.svelte';
  import HantaObjectContent from './hanta-object-content.svelte';
  import Dashboard from '$lib/pages/dashboard/dashboard.svelte';

  const isPluginMode = import.meta.env.MODE === 'plugin';
  export let open = false;
  export let module;
  export let id;

  const onOpenChangeFn = () => {
    appStore.openPopup(!$appStore.openPopup);
    if (!module) {
      appStore.setCurrentModule(null);
    }
  };

  const onOutsideClickFn = () => {
    if (!module) {
      appStore.setCurrentModule(null);
    }
  };
</script>

<Sheet.Root
  bind:open
  onOpenChange={onOpenChangeFn}
  onOutsideClick={onOutsideClickFn}
  closeOnOutsideClick={!$appStore.openActivity}
>
  <Sheet.Content
    class={cn(
      isPluginMode ? 'hanta-popup' : '',
      'bg-card text-card-foreground h-full p-0 ',
      $appStore.isCollapsed
        ? 'sm:max-w-[calc(100%-70px)]'
        : 'sm:max-w-[calc(100%-250px)]',
      $appStore.isMobile ? 'w-full z-50' : 'w-[calc(100%-160px)]',
    )}
  >
    <Sheet.Header class="flex space-y-0 w-full h-full">
      {#if module}
        {#key `${module}-${id}`}
          <HantaObjectView {module} {id} let:item>
            <svelte:fragment slot="header">
              <slot name="header" />
            </svelte:fragment>
            <svelte:fragment slot="actionBar">
              <slot name="actionBar" />
            </svelte:fragment>

            <HantaObjectContent
              {module}
              {item}
              currentTab={$appStore.selected?.tab}
            />
          </HantaObjectView>
        {/key}
      {:else}
        <Dashboard />
      {/if}
    </Sheet.Header>
  </Sheet.Content>
</Sheet.Root>
