<script lang="ts">
  import { appStore } from '$lib/stores/app-store';
  import Icon from '@iconify/svelte';
  import { beforeUpdate, onMount } from 'svelte';
  import { Button } from '$lib/components/ui/button';
  import { flyAndScale } from '$lib/utils/hanta-utils';
  import { cn } from '$lib/utils/hanta-utils';
  import { type SuperForm } from 'sveltekit-superforms';
  import { type ActivityWithRefs } from '$db/schema';
  import { asDraggable } from '$lib/ui/draggable';

  let popup: HTMLElement;

  export let form: SuperForm<ActivityWithRefs> = null;

  export let minWidth = 900;
  export let minHeight = 500;

  export let width = 700;
  export let height = 500;

  let posX = 0;
  let posY = 0;

  type $$Props = {
    class?: string;
    width?: number;
    height?: number;
    form?: SuperForm<ActivityWithRefs>;
    minWidth?: number;
    minHeight?: number;
  };

  let className: $$Props['class'] = undefined;
  export { className as class };

  function centerPopup() {
    if (!popup) return;

    const viewportWidth = window.innerWidth;
    posX = Math.max(0, Math.floor((viewportWidth - width) / 2));
    posY = 0;
  }

  onMount(() => {
    centerPopup();
    moveToLastPosition();
  });

  beforeUpdate(() => {
    moveToLastPosition();
  });

  function moveToLastPosition() {
    if (popup && document.body.lastChild !== popup) {
      document.body.appendChild(popup);
    }
  }

  function onDragStart() {
    return { x: posX, y: posY };
  }

  function onDragMove(x: number, y: number) {
    // Ensure the popup stays within viewport bounds
    const maxX = window.innerWidth - width;
    const maxY = window.innerHeight - height;

    posX = Math.max(0, Math.min(x, maxX));
    posY = Math.max(0, Math.min(y, maxY));
  }

  function onDragEnd(x: number, y: number) {
    onDragMove(x, y);
  }

  function startResizing() {
    return { x: width, y: height };
  }

  function continueResizing(x: number, y: number) {
    width = Math.max(minWidth, Math.min(x, window.innerWidth));
    height = Math.max(minHeight, Math.min(y, window.innerHeight));

    // Adjust position if needed after resize
    onDragMove(posX, posY);
  }

  function finishResizing(x: number, y: number) {
    continueResizing(x, y);
  }

  let { form: formData, enhance, errors } = form;
</script>

<div
  bind:this={popup}
  class={cn(
    'fixed flex top-0 left-0 z-50 border bg-background shadow-lg sm:rounded-lg',
    className,
    $appStore.isMobile ? 'w-full h-full' : '',
  )}
  style={$appStore.isMobile
    ? ''
    : `width: ${width}px; height: ${height}px; min-width: ${minWidth}px; min-height: ${minHeight}px; max-height: 100vh; transform: translate3d(${posX}px, ${posY}px, 0px);`}
  transition:flyAndScale={{ duration: 200, center: true }}
>
  <form
    autocomplete="off"
    class="flex flex-col gap-0 w-full h-full relative"
    datatype="JSON"
    method="POST"
    use:enhance
  >
    <div
      class="flex-shrink-0 flex flex-col px-6 py-4 pt-6 pb-6 space-y-1.5 text-center sm:text-left bg-muted cursor-move"
      use:asDraggable={{
        relativeTo: document.body,
        onDragStart,
        onDragMove,
        onDragEnd,
        minX: 0,
        minY: 0,
        maxX: window.innerWidth - width,
        maxY: window.innerHeight - height,
      }}
    >
      <div
        class="flex flex-row items-center text-lg font-semibold tracking-tight leading-none"
      >
        <slot name="header" />
        <Button
          class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-muted data-[state=open]:text-muted-foreground"
          on:click={() => {
            appStore.closeActivity();
          }}
          variant="ghost"
        >
          <Icon icon="mdi:close" class="w-4 h-4" />
          <span class="sr-only">Close</span>
        </Button>
      </div>
    </div>
    <div class="flex-1 overflow-y-auto pb-20">
      <slot name="content" />
    </div>
    <div
      class="absolute bottom-0 left-0 right-0 flex flex-col justify-end px-6 py-4 w-full border-t bg-background sm:flex-row sm:justify-start sm:space-x-2 border-t-solid border-t-primary-200/20"
    >
      {#if $$slots.footer}
        <slot name="footer" />
      {/if}
    </div>
    <div
      class="fixed right-0 bottom-0 cursor-se-resize w-4 h-4 flex items-center justify-center"
      use:asDraggable={{
        onDragStart: startResizing,
        onDragMove: continueResizing,
        onDragEnd: finishResizing,
        minX: minWidth,
        minY: minHeight,
        maxX: window.innerWidth,
        maxY: window.innerHeight,
      }}
    >
      <Icon icon="mdi:grip" class="w-4 h-4" />
    </div>
  </form>
</div>
