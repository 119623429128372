import { writable } from 'svelte/store';

type AlertState = {
  isOpen: boolean;
  title: string;
  description: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const createAlertStore = () => {
  const { subscribe, set, update } = writable<AlertState>({
    isOpen: false,
    title: '',
    description: '',
  });

  return {
    subscribe,
    show: (title: string, description: string, onConfirm?: () => void, onCancel?: () => void) => {
      set({
        isOpen: true,
        title,
        description,
        onConfirm,
        onCancel,
      });
    },
    close: () => {
      set({
        isOpen: false,
        title: '',
        description: '',
      });
    },
  };
};

export const alertStore = createAlertStore();
