<script lang="ts">
  import * as AlertDialog from "$lib/components/ui/alert-dialog";
  import { alertStore } from '$lib/stores/alert-store';
</script>

<AlertDialog.Root open={$alertStore.isOpen}>
  <AlertDialog.Content>
    <AlertDialog.Header>
      <AlertDialog.Title>{$alertStore.title}</AlertDialog.Title>
      <AlertDialog.Description>
        {$alertStore.description}
      </AlertDialog.Description>
    </AlertDialog.Header>
    <AlertDialog.Footer>
      <AlertDialog.Cancel on:click={() => {
        $alertStore.onCancel?.();
        alertStore.close();
      }}>Cancel</AlertDialog.Cancel>
      <AlertDialog.Action on:click={() => {
        $alertStore.onConfirm?.();
        alertStore.close();
      }}>Continue</AlertDialog.Action>
    </AlertDialog.Footer>
  </AlertDialog.Content>
</AlertDialog.Root>
