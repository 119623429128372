<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { cn } from '$lib/utils/hanta-utils';
  import { Button } from '$lib/components/ui/button';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu';

  import Icon from '@iconify/svelte';
  import { fields as fieldsDef } from '$lib/config';

  const dispatch = createEventDispatcher();
  export let collection = 'accounts';
  export let sort: { column: string; order: 'asc' | 'desc' } | undefined =
    undefined;

  $: fields = fieldsDef[collection]?.filter(el => el.sortable);
  $: sortBy = sort?.column;
  $: direction = sort?.order;

  function handleOrderChange(newDirection: 'asc' | 'desc') {
    if (!sortBy) return;

    if (direction === newDirection && newDirection === 'desc') {
      sort = undefined;
    } else {
      sort = { column: sortBy, order: newDirection };
    }
    dispatch('sort', sort);
  }

  function handleSortChange(column: string) {
    if (!column) {
      sort = undefined;
    } else {
      sort = { column, order: 'asc' };
    }
    dispatch('sort', sort);
  }

  function resetSort() {
    sort = undefined;
    dispatch('sort', undefined);
  }
</script>

<div class={cn('flex justify-end')}>
  <DropdownMenu.Root>
    <DropdownMenu.Trigger asChild let:builder>
      <Button
        variant={sortBy ? 'secondary' : 'outline'}
        builders={[builder]}
        class={cn('gap-2 transition-colors')}
      >
        <Icon
          icon={sortBy
            ? direction === 'asc'
              ? 'mdi:sort-ascending'
              : 'mdi:sort-descending'
            : 'mdi:sort-variant'}
          class="size-4"
        />
      </Button>
    </DropdownMenu.Trigger>

    <DropdownMenu.Content class="w-56" align="end" sideOffset={5}>
      <DropdownMenu.Label>Sort by</DropdownMenu.Label>
      <DropdownMenu.Separator />
      {#each fields || [] as field}
        <DropdownMenu.Item
          class={cn(
            field.value === sortBy && 'bg-muted/10 text-accent-foreground',
          )}
          on:click={() => handleSortChange(field.value)}
        >
          <div class="flex items-center justify-between w-full">
            <span>{field.label}</span>
            {#if field.value === sortBy}
              <Icon
                icon={direction === 'asc' ? 'mdi:arrow-up' : 'mdi:arrow-down'}
                class="size-4"
              />
            {/if}
          </div>
        </DropdownMenu.Item>
      {/each}

      {#if sortBy}
        <DropdownMenu.Separator />
        <DropdownMenu.Label>Sort order</DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.RadioGroup value={direction}>
          <DropdownMenu.RadioItem
            value="asc"
            on:click={() => handleOrderChange('asc')}
            class={cn(direction === 'asc' && 'bg-muted text-accent-foreground')}
          >
            <div class="flex items-center gap-2">
              <Icon icon="mdi:arrow-up" class="size-4" />
              <span>Ascending</span>
            </div>
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem
            value="desc"
            on:click={() => handleOrderChange('desc')}
            class={cn(
              direction === 'desc' && 'bg-muted text-accent-foreground',
            )}
          >
            <div class="flex items-center gap-2">
              <Icon icon="mdi:arrow-down" class="size-4" />
              <span>Descending</span>
            </div>
          </DropdownMenu.RadioItem>
          <DropdownMenu.Separator />
          <DropdownMenu.RadioItem
            value={undefined}
            on:click={resetSort}
            class="text-muted-foreground hover:text-destructive hover:bg-destructive/10"
          >
            <div class="flex items-center gap-2">
              <Icon icon="mdi:close" class="size-4" />
              <span>Clear sort</span>
            </div>
          </DropdownMenu.RadioItem>
        </DropdownMenu.RadioGroup>
      {/if}
    </DropdownMenu.Content>
  </DropdownMenu.Root>
</div>
