<script lang="ts">
  import { createQuery } from '@tanstack/svelte-query';
  import MenuItem from './menu-item.svelte';
  import { getTodos } from '$lib/api/queries';
  import { derived } from 'svelte/store';
  import { appStore } from '$lib/stores/app-store';

  // Query for overdue tasks
  const query = createQuery({
    queryKey: ['activities', 'todos', 'overdue'],
    queryFn: async () => {
      return await getTodos(
        {
          search: '',
          consultantId: undefined,
          type: undefined,
          timeFilter: {
            value: 'Overdue',
          },
        },
        0,
        100,
      );
    },
  });

  // Create a derived store for the route configuration
  const taskRoute = derived(query, $query => ({
    title: 'Todos',
    label: `${$query?.data?.data?.length || ''}`,
    icon: 'mdi:calendar-check',
    module: 'todos',
  }));
</script>

<MenuItem
  route={$taskRoute}
  collapsed={$appStore.isCollapsed}
  data-testid="todos-nav-item"
/>
