import { get } from 'svelte/store';
import { supabase } from './supabase';

import { authStore } from '$lib/stores/auth-store';
import type { Pipeline } from '$lib/types/pipeline';
import {
  pipelineInsertSchema,
  pipelinePhaseInsertSchema,
  pipelineItemInsertSchema,
} from '$db/schema';

export async function deletePipeline(id: string) {
  const { error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .delete()
    .eq('id', id);
}

export async function removePipelineItem(id: string) {
  const { error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .delete()
    .eq('id', id);
}
export async function savePipeline(pipeline: Pipeline) {
  const pipelineToSave = pipelineInsertSchema.parse(pipeline);

  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .upsert(pipelineToSave)
    .select();

  if (error) {
    console.debug('error', error);
    throw new Error(error.message);
  }

  const savedPipeline = await getPipeline(data?.[0].id);

  // Fetch existing phases
  const { data: existingPhases } = await supabase(get(authStore)?.token)
    .from('pipelinePhases')
    .select('id')
    .eq('pipelineId', savedPipeline.id);

  const existingPhaseIds = new Set<string>(existingPhases?.map(p => p.id));

  // Prepare phases to upsert and ids to delete
  const phasesToUpsert = [];
  const phaseIdsToDelete = [];

  pipeline.phases.forEach((phase, index) => {
    const phaseToUpsert = pipelinePhaseInsertSchema.parse({
      ...phase,
      phaseIndex: index,
      pipelineId: savedPipeline.id,
      orgId: savedPipeline.orgId,
      userId: savedPipeline.userId,
      createdAt: savedPipeline.createdAt,
      modifiedAt: savedPipeline.modifiedAt,
      endPhase: false
    });
    
    phasesToUpsert.push(phaseToUpsert);
    existingPhaseIds.delete(phase.id);
  });

  phaseIdsToDelete.push(...existingPhaseIds);

  // Delete removed phases
  if (phaseIdsToDelete.length > 0) {
    await supabase(get(authStore)?.token)
      .from('pipelinePhases')
      .delete()
      .in('id', phaseIdsToDelete);
  }

  // Upsert new and updated phases
  if (phasesToUpsert.length > 0) {    
    await supabase(get(authStore)?.token)
      .from('pipelinePhases')
      .upsert(phasesToUpsert);
  }

  return await getPipeline(savedPipeline.id);
}

export async function getPipeline(id: string) {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .select('*, phases:pipelinePhases(*)')
    .eq('id', id);
  return data?.[0];
}

export async function getPipelines() {
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelines')
    .select(
      '*, phases:pipelinePhases(*, items:pipelineItems(*, account:accounts(*), contact:contacts(*), candidate:contacts(*)))',
    )
    .eq('deleted', false)
    .order('createdAt', { ascending: false });
  return data;
}

export async function addPipelineItem(
  phaseId: string,
  module: string,
  item: any,
) {
  const field =
    module === 'accounts'
      ? 'accountId'
      : module === 'contacts'
        ? 'contactId'
        : module === 'deals'
          ? 'dealId'
          : module === 'activities'
            ? 'activityId'
            : module === 'files'
              ? 'filedId'
              : null;
  const { data, error } = await supabase(get(authStore)?.token)
    .from('pipelineItems')
    .insert({
      phaseId,
      [field]: item.id,
    });
}

export async function savePipelineItems(phaseId: string, items: any[]) {
  if (items?.length) {
    const itemsToSave = items.map(item =>
      pipelineItemInsertSchema.parse({
        ...item,
        phaseId,
      }),
    );
    const { data, error } = await supabase(get(authStore)?.token)
      .from('pipelineItems')
      .upsert(itemsToSave);
  }
}
