<script lang="ts">
  import { Input } from '$lib/components/ui/input/index.js';
  import CrmAvatar from '$lib/components/hanta/crm-avatar.svelte';
  import Pagination from '$lib/app/list/pagination.svelte';
  import { Badge } from '$lib/components/ui/badge';
  import * as Card from '$lib/components/ui/card';
  import * as Tabs from '$lib/components/ui/tabs';
  import * as Select from '$lib/components/ui/select';
  import { Button } from '$lib/components/ui/button';
  import { Calendar, Search } from 'lucide-svelte';
  import { Alert, AlertDescription } from '$lib/components/ui/alert';

  import {
    activityTypes,
    getIconForActivityType,
    timeFilters,
  } from '$lib/config/crm-config';

  import { type ActivityWithRefs, type Account } from '$db/schema';
  import { getTodos } from '$lib/api/queries';
  import { cn } from '$lib/utils/hanta-utils';
  import Icon from '@iconify/svelte';
  import UsersSelector from '$lib/widgets/users/users-selector.svelte';
  import { superForm } from 'sveltekit-superforms';
  import { formatDate } from '$lib/pages/invoices/invoice-utils.js';
  import { appStore } from '$lib/stores/app-store';
  import { createMutation, createQuery } from '@tanstack/svelte-query';
  import { derived, writable } from 'svelte/store';
  import { saveActivity } from '$lib/api/mutations';
  import { flip } from 'svelte/animate';
  import ActivityActionBar from '../activities/activity-action-bar.svelte';

  type $$Props = {
    class?: string;
  };

  let filterValue = writable('');
  let page = writable(0);
  let activityType = writable('All');
  let timeFilter = writable({
    value: 'Today',
    label: 'Today',
    disabled: false,
  });

  const pageSize = 10;

  const form = superForm<ActivityWithRefs>(
    {},
    {
      resetForm: false,
      SPA: true,
      dataType: 'json',
    },
  );

  const { form: formData } = form;

  // Separate query for overdue count to ensure it's always accurate
  const overdueQuery = createQuery(
    derived([formData], ([data]) => ({
      enabled: true,
      queryKey: ['activities', 'overdue', data?.consultantObj?.id ?? 'n/a'],
      queryFn: async () => {
        return await getTodos(
          {
            consultantId: data?.consultantObj?.id,
            timeFilter: { value: 'Overdue' },
          },
          0,
          1000,
        );
      },
    })),
  );

  const overdueCount = derived(overdueQuery, $overdueQuery => {
    return $overdueQuery?.data?.count ?? 0;
  });

  const query = createQuery(
    derived(
      [formData, filterValue, page, activityType, timeFilter],
      ([data, search, page, type, time]) => ({
        enabled: true,
        queryKey: [
          'activities',
          'todos',
          page,
          data?.consultantObj?.id ?? 'n/a',
          search,
          type,
          time,
        ],
        queryFn: async () => {
          return await getTodos(
            {
              search,
              consultantId: data?.consultantObj?.id,
              type: type === 'All' ? undefined : type,
              timeFilter: time,
            },
            page,
            page + 10,
          );
        },
      }),
    ),
  );

  const addMutation = createMutation({
    mutationFn: (activity): Promise<Account> =>
      Promise.resolve(saveActivity(activity)),
  });

  let className: $$Props['class'] = undefined;
  export { className as class };

  function saveActivityDone(e: MouseEvent, activity, done: boolean) {
    e.stopPropagation();
    $addMutation.mutate({ ...activity, done: !activity.done });
  }
</script>

<Card.Root
  class={cn('h-full overflow-hidden bg-card flex flex-col', className)}
>
  <Card.Header
    class="flex relative flex-col space-y-4 border-b border-solid bg-primary-800 border-b-primary-200/20 pb-2"
  >
    <div class="flex justify-between items-center">
      <Card.Title class="flex gap-2 items-center">
        Todos
        <Badge>{$query.data?.count ?? '0'}</Badge>
        {#if $overdueCount > 0}
          <Button
            variant="destructive"
            size="sm"
            class="ml-2 gap-1"
            on:click={() => {
              $activityType = 'All';
              $timeFilter = {
                value: 'Overdue',
                label: 'Overdue',
                disabled: false,
              };
            }}
          >
            <Icon icon="mdi:alert-circle" class="size-4" />
            {$overdueCount} overdue todos
          </Button>
        {/if}
      </Card.Title>
      <div class="flex gap-2">
        <Button variant="outline" size="sm" class="gap-2 hidden">
          <Calendar class="h-4 w-4" />
          Connect Calendar
        </Button>
        <ActivityActionBar mode="collapsed" />
      </div>
    </div>

    <Alert class="bg-blue-500/10 border-blue-500/20 hidden">
      <AlertDescription>
        Connect your calendar to sync activities. Supports Google Calendar,
        Outlook, and Office 365.
      </AlertDescription>
    </Alert>

    <div class="flex justify-between items-center gap-4">
      <div class="flex-1">
        <div class="relative">
          <Search
            class="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground"
          />
          <Input
            bind:value={$filterValue}
            class="pl-8 w-full"
            placeholder="Search activities..."
            type="text"
          />
        </div>
      </div>

      <UsersSelector
        class="w-64"
        bind:value={$formData.consultantObj}
        multiple={false}
        hideLabel={true}
      />
    </div>

    <div class="flex gap-4 pt-2">
      <Tabs.Root
        value={$activityType}
        onValueChange={v => activityType.set(v)}
        class="w-full"
      >
        <Tabs.List class="w-full">
          {#each activityTypes as type}
            <Tabs.Trigger value={type} class="flex-1">
              <div class="flex items-center justify-center gap-2">
                {#if type !== 'All'}
                  <Icon icon={getIconForActivityType(type)} class="size-4" />
                {/if}
                {type}
              </div>
            </Tabs.Trigger>
          {/each}
        </Tabs.List>
      </Tabs.Root>
    </div>

    <div class="flex items-center">
      <Select.Root
        selected={$timeFilter}
        onSelectedChange={v => timeFilter.set(v)}
      >
        <Select.Trigger class="w-[180px]">
          <Select.Value placeholder="Select time filter" />
        </Select.Trigger>
        <Select.Content>
          {#each timeFilters as filter}
            <Select.Item value={filter}>{filter}</Select.Item>
          {/each}
        </Select.Content>
      </Select.Root>
    </div>
  </Card.Header>

  <Card.Content class="flex overflow-scroll relative flex-col gap-2 p-6 h-full">
    {#if $query?.data?.data?.length > 0}
      {#each $query.data?.data as activity (activity.id)}
        <div animate:flip={{ duration: 200 }} class="transition-all">
          <Button
            on:click={() => {
              appStore.openActivity({
                ...activity,
                consultantObj: { id: activity.consultant },
              });
            }}
            variant="link"
            class="{activity.done
              ? 'line-through bg-primary/10 hover:bg-primary/10 text-muted-foreground'
              : 'bg-secondary hover:bg-card/10'} flex text-left content-start justify-start w-full p-4 shadow-sm border border-muted rounded items-center gap-4 text-sm h-auto"
          >
            <Button
              variant="ghost"
              class="p-2"
              on:click={e => saveActivityDone(e, activity, !activity.done)}
            >
              <Icon
                icon={activity.done
                  ? 'material-symbols:check-box-outline'
                  : 'material-symbols:check-box-outline-blank-sharp'}
                class="size-6"
              />
            </Button>

            <div class="flex items-center gap-2 min-w-[200px]">
              <Icon
                icon={getIconForActivityType(activity.type)}
                class="size-4"
              />
              <span>{activity.name ?? '-'}</span>
            </div>

            {#if activity.deal}
              <div class="flex items-center gap-2">
                <Icon icon="mdi:handshake" class="size-4" />
                <CrmAvatar id={activity.deal} module="deals" />
              </div>
            {/if}

            <div class="flex items-center gap-2">
              <Icon icon="mdi:account" class="size-4" />
              <CrmAvatar id={activity.consultant} module="users" />
            </div>

            {#if activity.dueDate}
              <div class="flex items-center gap-2">
                <Icon icon="mdi:clock" class="size-4" />
                <Badge
                  variant={new Date(activity.dueDate) < new Date()
                    ? 'destructive'
                    : 'default'}
                >
                  {formatDate(activity.dueDate)}
                </Badge>
              </div>
            {/if}
          </Button>
        </div>
      {/each}
    {:else}
      <div
        class="flex flex-col items-center justify-center h-full text-center space-y-4"
      >
        <div class="rounded-full bg-primary-50 p-4">
          <Icon
            icon="mdi:checkbox-marked-outline"
            class="size-12 text-primary-500"
          />
        </div>
        <div class="space-y-2">
          <h3 class="text-lg font-semibold">No todos found</h3>
          <p class="text-sm text-muted-foreground max-w-sm">
            {#if $filterValue}
              No todos match your search criteria. Try adjusting your filters or
              search terms.
            {:else}
              Get started by creating your first todos. Track your activities,
              set deadlines, and stay organized.
            {/if}
          </p>
        </div>
        <ActivityActionBar mode="button" />
      </div>
    {/if}
  </Card.Content>

  <Card.Footer
    class="gap-4 justify-end pt-1 pb-1.5 border-t border-solid shadow-sm border-gray-200 bg-primary-800"
  >
    <Pagination
      count={$query.data?.count}
      from={$page}
      on:next={() => ($page += pageSize)}
      on:previous={() => ($page -= pageSize)}
    />
  </Card.Footer>
</Card.Root>
