<script lang="ts">
  import { QueryClient, QueryClientProvider } from '@tanstack/svelte-query';
  import { toast } from 'svelte-sonner';
  import { ModeWatcher, mode } from 'mode-watcher';
  import { Plane } from 'svelte-loading-spinners';
  import { appStore } from '$lib/stores/app-store';
  import { Toaster } from '$lib/components/ui/sonner';
  import MainWidget from '$lib/widgets/main-widget.svelte';
  import ActivityPopup from '$lib/pages/activities/activity-popup.svelte';
  import CrmApp from '$lib/app/crm-app.svelte';
  import CrmChecker from '$lib/checker/crm-checker.svelte';
  import LinkedInChecker from '$lib/checker/linkedIn-checker.svelte';
  import { contactLocal } from '$lib/mock-data';
  import { onDestroy, onMount } from 'svelte';
  import { SvelteQueryDevtools } from '@tanstack/svelte-query-devtools';
  import { authStore } from '$lib/stores/auth-store';
  import { get } from 'svelte/store';
  import AlertDialogCentral from '$lib/components/ui/alert-dialog/alert-dialog-central.svelte';
  import NewItemWindow from '$lib/app/new-item/new-item-window.svelte';
  import { newItemStore } from '$lib/stores/new-item-store';
  import CurrentDevPage from '$lib/pages/_current/current-dev-page.svelte';
  import { ConnectionService, connectionConfig } from '$lib/services/connection-service';

  let contact = import.meta.env.DEV ? contactLocal : null;
  let connectionService: ConnectionService;

  // Add logging to mode watcher
  mode.subscribe(currentMode => {
    console.log(`Mode changed to: ${currentMode}`);
  });

  onMount(async () => {
    if (!$appStore.queryClient) {
      const queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 30,
          },
        },
      });
      appStore.setQueryClient(queryClient);
    }

    connectionService = new ConnectionService(connectionConfig);
    connectionService.subscribeToChannels();

    function handleKeydown(e: KeyboardEvent) {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        newItemStore.openDialog();
      }
    }

    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  });

  onDestroy(() => {
    if (connectionService) {
      connectionService.cleanup();
    }
  });
</script>

{#if $appStore.queryClient}
  <QueryClientProvider client={$appStore.queryClient}>
    {#if false && import.meta.env.DEV}
      <SvelteQueryDevtools initialIsOpen={false} />
    {/if}
    <div class="app bg-primary-foreground">
      <ModeWatcher track={true} />
      <Toaster class="z-[100000]" position="bottom-left" />

      <CrmApp />

      {#if $appStore.isParsing}
        <div class="ml-4 min-h-[60px]">
          <Plane size="15" color="#09D2C2" unit="px" duration="1.5s" />
        </div>
      {/if}

      <MainWidget />

      <LinkedInChecker />
      <CrmChecker linkedin={contact?.linkedin} />
      <ActivityPopup />
      <AlertDialogCentral />
      <NewItemWindow />

      {#if false}
        <CurrentDevPage />
      {/if}
    </div>
  </QueryClientProvider>
{/if}

<style>
  :global(body) {
    margin: 0;
    font-family: system-ui, sans-serif;
  }

  .app {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    z-index: 10000;
  }

  div :global(svg) {
    font-size: 16px;
    line-height: 1em;
  }

  div :global(.big-icon) {
    font-size: 72px;
  }
</style>
